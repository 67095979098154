<template>
  <b-row>
    <b-col cols="12" md="4">
      <statistics-guests-card />
    </b-col>
    <b-col cols="12" md="8">
      <statistics-insights-card />
      <statistics-billing-card />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import StatisticsGuestsCard from "@/views/accommodations/accommodation/components/statistics/StatisticsGuestsCard.vue";
import StatisticsInsightsCard from "@/views/accommodations/accommodation/components/statistics/StatisticsInsightsCard.vue";
import StatisticsBillingCard from "@/views/accommodations/accommodation/components/statistics/StatisticsBillingCard.vue";

export default {
  components: {
    BRow,
    BCol,
    StatisticsGuestsCard,
    StatisticsInsightsCard,
    StatisticsBillingCard,
  },
};
</script>
