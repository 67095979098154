<template>
  <b-overlay :show="loadingContract" rounded="sm" variant="white">
    <b-card>
      <b-row class="mb-2">
        <b-col class="d-flex text-center align-items-center">
          <b-button
            v-b-modal="`add-contract-modal`"
            variant="primary"
            @click="onCreateButtonClicked"
          >
            Crea un nou contracte
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="contract" class="mb-2">
        <b-col cols="12">
          <h3>Contracte</h3>
        </b-col>
        <b-col cols="12">
          <span class="font-weight-bold">ID:</span>
          {{ contract.uuid }}
        </b-col>
        <b-col cols="12">
          <span class="font-weight-bold">Data d'inici:</span>
          {{ formatContractDate(contract.startDate) }}
        </b-col>
        <b-col cols="12">
          <span class="font-weight-bold">Data de fi:</span>
          {{ formatContractDate(contract.endDate) }}
        </b-col>
      </b-row>
      <b-row v-if="contract && owner">
        <b-col cols="12">
          <h3>Propietari</h3>
        </b-col>
        <b-col cols="12">
          <span class="font-weight-bold">Nom:</span>
          {{ owner.fullName }}
        </b-col>
        <b-col cols="12">
          <span class="font-weight-bold">Email:</span>
          {{ owner.email }}
        </b-col>
      </b-row>

      <!-- ADD CONTRACT MODAL -->
      <b-modal
        :id="'add-contract-modal'"
        title="Afegeix un contracte"
        scrollable
        hide-footer
        centered
        size="lg"
      >
        <contract-form
          @cancel="onAddContractCancel"
          @contract-added="onContractAdded"
          @add-contract-error="onAddContractError"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BRow, BCol, BCard, BButton, BModal } from "bootstrap-vue";
import { formatDateStringToDate } from "@/utils/formatters";
import ContractForm from "@/views/accommodations/accommodation/components/ContractForm.vue";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    ContractForm,
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    loadingContract() {
      return this.$store.getters["accommodation/loadingContract"];
    },
    contract() {
      return this.$store.getters["accommodation/contract"];
    },
    owner() {
      return this.$store.getters["accommodation/owner"];
    },
  },
  methods: {
    onCreateButtonClicked() {
      // this.$router.push({
      //   name: 'foravila-new-contract',
      // })
      // this.$bvModal.show('add-contract-modal')
    },
    formatContractDate(date) {
      return formatDateStringToDate(date);
    },
    onAddContractCancel() {
      this.$bvModal.hide("add-contract-modal");
    },
    onContractAdded() {
      this.$bvModal.hide("add-contract-modal");
    },
    onAddContractError() {
      //
    },
  },
};
</script>
