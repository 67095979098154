<template>
  <b-card no-body id="statistics-billing-card">
    <b-card-header>
      <div>
        <b-card-title> Facturació per data d'entrada </b-card-title>
        <b-card-text class="font-small-2">
          {{ periodText }}
        </b-card-text>
        <b-spinner v-if="loadingBookings" />
        <h1 v-else class="font-weight-bolder">
          {{ billingTotal }}
        </h1>
      </div>
      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item
          v-for="(option, index) in periodOptions"
          :key="`period-option-${index}`"
          @click="selectPeriod(option.value)"
        >
          {{ option.label }}
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-form>
          <b-form-checkbox :disabled="period !== 'ALL'" v-model="limitResults">
            <span class="text-nowrap"> Limitar resultats </span>
          </b-form-checkbox>
        </b-dropdown-form>
      </b-dropdown>
    </b-card-header>

    <b-card-body class="p-2">
      <b-overlay
        :show="loadingBookings"
        rounded="sm"
        variant="white"
        opacity=".8"
        :class="{ 'mb-3': loadingBookings }"
      >
        <b-row class="mx-0">
          <b-col cols="12">
            <!-- chart -->
            <vue-apex-charts
              height="285"
              :options="chartConfig"
              :series="billingDataSeries"
            />
          </b-col>

          <b-col cols="12" class="d-flex justify-content-center mt-2">
            <div class="d-flex align-items-center">
              <div
                v-for="serie in billingDataSeries"
                :key="serie.color"
                class="d-flex align-items-center mr-2"
              >
                <span
                  class="bullet svg-font-small-3 mr-50 cursor-pointer"
                  :style="{
                    backgroundColor: serie.color,
                  }"
                />
                <span>{{ serie.name }}</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BSpinner,
  BOverlay,
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BDropdownForm,
  BFormCheckbox,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
import { formatCurrency } from "@/utils/formatters";

export default {
  components: {
    BSpinner,
    BOverlay,
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BDropdownDivider,
    BDropdownForm,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      period: "CURRENT_YEAR",
      periodOptions: [
        { label: this.$t("Any actual"), value: "CURRENT_YEAR" },
        { label: this.$t("Any passat"), value: "LAST_YEAR" },
        { label: this.$t("Desde l'inici"), value: "ALL" },
      ],
      limitResults: true,
    };
  },
  computed: {
    loadingBookings() {
      return this.$store.getters["accommodation/loadingBookings"];
    },
    bookings() {
      return this.$store.getters["accommodation/bookings"];
    },
    filteredBookings() {
      if (!this.bookings?.length) {
        return [];
      }

      const currentYear = new Date().getFullYear();
      const lastYear = currentYear - 1;
      const nextYear = currentYear + 1;

      return this.bookings.filter((booking) => {
        const checkinYear = new Date(booking.checkin).getFullYear();

        // Status
        const statusMatches = booking.status === "COMPLETED";

        // Period
        let periodMatches = false;
        switch (this.period) {
          case "CURRENT_YEAR":
            periodMatches = checkinYear === currentYear;
            break;
          case "LAST_YEAR":
            periodMatches = checkinYear === lastYear;
            break;
          case "ALL":
            periodMatches = true;
            break;
        }

        // Limited filter
        let limitedMatches = true;
        if (this.limitResults) {
          limitedMatches = checkinYear >= lastYear && checkinYear <= nextYear;
        }

        return statusMatches && periodMatches && limitedMatches;
      });
    },
    billingTotal() {
      const total = this.filteredBookings.reduce((sum, booking) => {
        return (sum += booking.pvpPrice);
      }, 0);
      return formatCurrency(total);
    },
    billingData() {
      const totalPriceByYear = {};

      this.filteredBookings.forEach((booking) => {
        const checkinDate = new Date(booking.checkin);
        const year = checkinDate.getFullYear();
        const month = checkinDate.getMonth();

        if (!totalPriceByYear[year]) {
          totalPriceByYear[year] = new Array(12).fill(0);
        }

        totalPriceByYear[year][month] += booking.pvpPrice / 100;
      });

      return totalPriceByYear;
    },
    billingDataSeries() {
      if (!this.billingData) {
        return [];
      }

      return Object.keys(this.billingData).map((year) => {
        return {
          name: year,
          data: this.billingData[year],
          color: this.getSeriesYearColor(year),
        };
      });
    },
    billingDataColors() {
      if (!this.billingDataSeries) {
        return [];
      }

      return this.billingDataSeries.map((serie) => serie.color);
    },
    periodText() {
      const periodTexts = {
        CURRENT_YEAR: this.$t("Any actual"),
        LAST_YEAR: this.$t("Any passat"),
        ALL: this.$t("Desde l'inici"),
      };

      return periodTexts[this.period] || null;
    },
    chartConfig() {
      return {
        chart: {
          stacked: false,
          type: "area",
          toolbar: { show: false },
        },
        grid: {
          padding: { top: -20, bottom: -10 },
          xaxis: { lines: { show: false } },
          yaxis: { lines: { show: true } },
        },
        legend: { show: false },
        dataLabels: { enabled: false },
        colors: this.billingDataColors,
        xaxis: {
          categories: [
            "Gen",
            "Feb",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Oct",
            "Nov",
            "Des",
          ],
          labels: {
            style: {
              colors: "#6E6B7B",
              fontSize: "0.86rem",
              fontFamily: "Montserrat",
            },
          },
          axisTicks: { show: false },
          axisBorder: { show: true },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#6E6B7B",
              fontSize: "0.86rem",
              fontFamily: "Montserrat",
            },
            formatter: function (value) {
              return new Intl.NumberFormat("es-ES", {
                style: "currency",
                currency: "EUR",
              })
                .format(value)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
          },
          axisTicks: { show: false },
          axisBorder: { show: true },
        },
        stroke: {
          width: 2,
        },
      };
    },
  },
  methods: {
    selectPeriod(period) {
      this.period = period;
    },
    getSeriesYearColor(year) {
      const checkYear = parseInt(year);
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const lastYear = currentYear - 1;
      const nextYear = currentYear + 1;

      if (checkYear === currentYear) return "#FFCD00";
      if (checkYear === lastYear) return "#4EA7BC";
      if (checkYear === nextYear) return "#AFC97E";

      return "#EFF2F1";
    },
  },
};
</script>
