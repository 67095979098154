<template>
  <div id="faq-form">
    <b-row class="mb-2">
      <b-col>
        <h4>Visibilitat</h4>
        <v-select
          v-model="scope"
          :options="faqScopeOptions"
          :reduce="(option) => option.value"
          placeholder="Selecciona la visibilitat"
          :clearable="false"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <h4>Idioma</h4>
        <v-select
          v-model="language"
          :options="languageOptions"
          :reduce="(option) => option.value"
          placeholder="Selecciona l'idioma"
          :clearable="false"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <h4>Contingut</h4>
        <b-form-input
          v-model="faqTitle"
          type="text"
          placeholder="Títol FAQ"
          class="mb-1"
        />
        <quill-editor
          :ref="`quillEditor-faq-form`"
          v-model="faqContent"
          :options="editorConfig"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <h4>
          Fotos
          <small>(opcional)</small>
        </h4>
        <b-form-file
          v-if="previewPictures"
          id="wildcard"
          accept="image/*"
          multiple
          @change="handleImages($event)"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="previewPictures && previewPictures.length"
      class="d-flex flex-wrap mb-2"
    >
      <b-col
        v-for="(picture, index) in previewPictures"
        :key="`preview-picture-${index}`"
        cols="12"
        md="6"
        lg="3"
      >
        <b-card
          :img-src="picture"
          img-top
          img-alt="FAQ image"
          class="mb-1"
          no-body
        >
          <template #footer>
            <div class="d-flex justify-content-center">
              <b-button
                variant="danger"
                size="sm"
                @click.stop="deletePreviewImage(index)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-end">
      <b-col cols="6" md="auto">
        <b-button block @click="cancel"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" md="auto">
        <b-button block variant="primary" @click="save"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormFile,
  BCard,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { faqScopeOptions, languageOptions } from "@/utils/select-options";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    vSelect,
    quillEditor,
    BButton,
    BFormFile,
    BCard,
  },
  props: {
    faq: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      faqTitle: null,
      faqContent: null,
      scope: "AGENCY",
      language: "ca",
      previewPictures: [],
      createdFaqs: [],
      editorConfig: {
        modules: {
          toolbar: [
            // [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"],
          ],
        },
      },
      faqScopeOptions,
      languageOptions,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
  },
  created() {
    if (this.faq) this.initForm();
  },
  methods: {
    initForm() {
      if (!this.faq) return;
      this.faqTitle = this.faq.title;
      this.scope = this.faq.scope;
      this.language = this.faq.language;
      this.faqContent = this.faq.content;
    },
    cancel() {
      this.$emit("cancel");
    },
    save() {
      if (this.faq) this.editFaq();
      else this.createFaq();
    },
    createFaq() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("accommodation/addAccommodationFaq", {
          accommodation: this.accommodation["@id"],
          title: this.faqTitle,
          content: this.faqContent,
          language: this.language,
          scope: this.scope,
        })
        .then((faq) => {
          if (!this.previewPictures.length) {
            this.$emit("added");
            this.$store.dispatch("app/setLoading", false);
            return;
          }

          this.$store
            .dispatch("accommodation/addAccommodationFaqPictures", {
              accommodationUuid: this.accommodation.uuid,
              faqUuid: faq.uuid,
              pictures: this.previewPictures,
            })
            .then(() => {
              this.$emit("added");
            })
            .catch(() => {
              this.$emit("add-error");
            })
            .finally(() => {
              this.$store.dispatch("app/setLoading", false);
            });
        })
        .catch(() => {
          this.$emit("add-error");
          this.$store.dispatch("app/setLoading", false);
        });
    },
    editFaq() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("accommodation/updateAccommodationFaq", {
          uuid: this.faq.uuid,
          title: this.faqTitle,
          content: this.faqContent,
          language: this.language,
          scope: this.scope,
        })
        .then(() => {
          if (!this.previewPictures.length) {
            this.$emit("edited");
            this.$store.dispatch("app/setLoading", false);
            return;
          }

          this.$store
            .dispatch("accommodation/addAccommodationFaqPictures", {
              accommodationUuid: this.accommodation.uuid,
              faqUuid: this.faq.uuid,
              pictures: this.previewPictures,
            })
            .then(() => {
              this.$emit("edited");
            })
            .catch(() => {
              this.$emit("edit-error");
            })
            .finally(() => {
              this.$store.dispatch("app/setLoading", false);
            });
        })
        .catch(() => {
          this.$emit("edit-error");
          this.$store.dispatch("app/setLoading", false);
        });
    },
    handleImages(event) {
      this.previewPictures = [];
      event.target.files.forEach((file) => {
        this.createBase64Image(file);
      }, this);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.previewPictures.push(event.target.result);
      };
      reader.readAsDataURL(fileObject);
    },
    deletePreviewImage(index) {
      this.previewPictures.splice(index, 1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#faq-form {
  .quill-editor {
    .ql-editor {
      p {
        margin-top: 0 !important;
        margin-bottom: 1rem !important;
      }
    }
  }
}
</style>
