<template>
  <b-card id="accommodation-summary-card" no-body>
    <b-card-header>
      <b-card-title>Resum</b-card-title>
      <b-card-title>
        <b-form-checkbox
          v-model="editedAccommodation.trending"
          class="custom-control-primary"
          switch
          @change="onTrendingSwitched"
        >
          <span class="switch-icon-left">
            <feather-icon icon="StarIcon" />
          </span>
          <span class="switch-icon-right">
            <feather-icon icon="StarIcon" />
          </span>
        </b-form-checkbox>
      </b-card-title>
    </b-card-header>

    <b-card-body>
      <b-row class="mb-2">
        <!-- PICUTRE -->
        <b-col cols="12" md="6" lg="4" xl="3" class="mb-3 mb-md-0">
          <b-img
            :src="
              coverPicture ||
              require('@/assets/foravila/images/accommodation-placeholder.jpg')
            "
            fluid-grow
            rounded
          />
        </b-col>

        <!-- DETAILS -->
        <b-col cols="12" md="6" lg="8" xl="9">
          <b-row>
            <!-- NAME -->
            <b-col cols="12" sm="4" md="6" xl="3" class="mb-2">
              <h6 class="mb-75">Nom</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedAccommodation.name"
                placeholder="Nom de l'allotjament"
              />
              <b-card-text v-else>
                {{ accommodation.name }}
              </b-card-text>
            </b-col>

            <!-- CADASTRAL REFERENCE -->
            <b-col cols="12" sm="4" md="6" xl="3" class="mb-2">
              <h6 class="mb-75">Ref. Catastral</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedAccommodation.cadastralReference"
                placeholder="Nº de referència catastral"
              />
              <b-card-text v-else>
                {{ accommodation.cadastralReference }}
              </b-card-text>
            </b-col>

            <!-- STATUS -->
            <b-col :cols="editMode ? 12 : 6" sm="4" md="6" xl="3" class="mb-2">
              <h6 class="mb-75">Estat</h6>
              <v-select
                v-if="editMode"
                v-model="editedAccommodation.active"
                placeholder="Selecciona una estat"
                :options="statusOptions"
                :reduce="(option) => option.value"
              />
              <b-card-text v-else>
                {{ accommodation.active ? "Actiu" : "Inactiu" }}
              </b-card-text>
            </b-col>

            <!-- TYPE -->
            <b-col :cols="editMode ? 12 : 6" sm="4" md="6" xl="3" class="mb-2">
              <h6 class="mb-75">Tipus</h6>
              <v-select
                v-if="editMode"
                v-model="editedAccommodation.type"
                placeholder="Selecciona una tipus"
                :options="accommodationTypeOptions"
                :reduce="(option) => option.value"
              />
              <b-card-text v-else>
                {{ accommodationTypeName }}
              </b-card-text>
            </b-col>

            <!-- TOURIST LICENCE -->
            <b-col :cols="editMode ? 12 : 6" sm="4" md="6" xl="3" class="mb-2">
              <h6 class="mb-75">ETV</h6>
              <b-row v-if="editMode">
                <b-col cols="6">
                  <b-form-input
                    v-model="editedAccommodation.touristLicenceNumber"
                    placeholder="Número"
                  />
                </b-col>
                <b-col cols="6">
                  <b-form-input
                    v-model="editedAccommodation.touristLicenceName"
                    placeholder="Nom"
                  />
                </b-col>
              </b-row>
              <b-card-text v-else>
                {{ accommodationTouristLicenceAndName }}
              </b-card-text>
            </b-col>

            <!-- POLICE REGISTRATION -->
            <b-col :cols="editMode ? 12 : 6" sm="4" md="6" xl="3" class="mb-2">
              <h6 class="mb-75">Registre policia</h6>
              <b-row v-if="editMode">
                <b-col cols="6">
                  <b-form-input
                    v-model="editedAccommodation.policeRegistrationNumber"
                    placeholder="Número"
                  />
                </b-col>
                <b-col cols="6">
                  <b-form-input
                    v-model="editedAccommodation.policeRegistrationName"
                    placeholder="Nom"
                  />
                </b-col>
              </b-row>
              <b-card-text v-else>
                {{ accommodationPoliceRegistrationAndName }}
              </b-card-text>
            </b-col>

            <!-- CAPACITY -->
            <b-col :cols="editMode ? 12 : 6" sm="4" md="6" xl="3" class="mb-2">
              <h6 class="mb-75">Capacitat</h6>
              <b-form-spinbutton
                v-if="editMode"
                v-model="editedAccommodation.capacity"
                min="1"
                step="1"
              />
              <b-card-text v-else>
                {{ accommodation.capacity }}
              </b-card-text>
            </b-col>

            <!-- BEDROOMS -->
            <b-col v-if="!editMode" cols="6" sm="4" md="6" xl="3" class="mb-2">
              <h6 class="mb-75">Dormitoris</h6>
              <b-card-text>
                {{ accommodation.bedroomsNumber }}
              </b-card-text>
            </b-col>

            <!-- BATHROOMS -->
            <b-col v-if="!editMode" cols="6" sm="4" md="6" xl="3" class="mb-2">
              <h6 class="mb-75">Banys</h6>
              <b-card-text>
                {{ accommodation.bathroomsNumber }}
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            Cancel·la
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="onSaveButtonClicked">
            Guarda
          </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="onEditButtonClicked">
            Edita
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BImg,
  BButton,
  BFormCheckbox,
  BFormInput,
  BFormSpinbutton,
} from "bootstrap-vue";
import {
  getAccommodationTypeName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import {
  statusOptions,
  accommodationTypeOptions,
} from "@/utils/select-options";
import vSelect from "vue-select";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BImg,
    BButton,
    BFormCheckbox,
    BFormInput,
    BFormSpinbutton,
    vSelect,
  },
  data() {
    return {
      editMode: false,
      editedAccommodation: {
        trending: false,
        name: null,
        active: null,
        type: null,
        touristLicenceNumber: null,
        touristLicenceName: null,
        policeRegistrationNumber: null,
        policeRegistrationName: null,
        capacity: null,
        cadastralReference: null,
      },
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    pictures() {
      return this.$store.getters["accommodation/pictures"];
    },
    statusOptions() {
      return statusOptions;
    },
    accommodationTypeOptions() {
      return accommodationTypeOptions;
    },
    accommodationTypeName() {
      if (!this.accommodation) return null;
      return (
        getAccommodationTypeName(this.accommodation.type, this.$i18n.locale) ||
        this.$t("No definit")
      );
    },
    coverPicture() {
      const coverPicture = this.pictures.find((p) => p.cover === true);
      if (coverPicture) return coverPicture?.thumbnailUrl;
      return this.accommodation?.coverPicture?.thumbnailUrl;
    },
    accommodationTouristLicenceAndName() {
      if (!this.accommodation) return this.$t("No disponible");

      const text = [];
      if (this.accommodation.touristLicenceNumber)
        text.push(this.accommodation.touristLicenceNumber);
      if (this.accommodation.touristLicenceName)
        text.push(this.accommodation.touristLicenceName);

      if (text.length) return text.join(" - ");
      return this.$t("No disponible");
    },
    accommodationPoliceRegistrationAndName() {
      if (!this.accommodation) return this.$t("No disponible");

      const text = [];
      if (this.accommodation.policeRegistrationNumber)
        text.push(this.accommodation.policeRegistrationNumber);
      if (this.accommodation.policeRegistrationName)
        text.push(this.accommodation.policeRegistrationName);

      if (text.length) return text.join(" - ");
      return this.$t("No disponible");
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    initInputs() {
      this.editedAccommodation = {
        trending: this.accommodation.trending || false,
        name: this.accommodation.name || null,
        active: this.accommodation.active || false,
        type: this.accommodation.type || "OTHER",
        touristLicenceNumber: this.accommodation.touristLicenceNumber || null,
        touristLicenceName: this.accommodation.touristLicenceName || null,
        policeRegistrationNumber:
          this.accommodation.policeRegistrationNumber || null,
        policeRegistrationName:
          this.accommodation.policeRegistrationName || null,
        capacity: this.accommodation.capacity || null,
        cadastralReference: this.accommodation.cadastralReference || null,
      };
    },
    onTrendingSwitched() {
      if (!this.editMode) {
        this.$store
          .dispatch("accommodation/updateAccommodation", {
            uuid: this.accommodation.uuid,
            trending: this.editedAccommodation.trending || false,
          })
          .then(() =>
            notifySuccess(
              "Allotjament actualitzat",
              "L'allotjament s'ha actualitzat correctament"
            )
          )
          .catch(() =>
            notifyError(
              "Allotjament no actualitzat",
              "L'allotjament no s'ha pogut actualitzar correctament, si us plau, revisa les dades introduïdes"
            )
          );
      }
    },
    onEditButtonClicked() {
      this.initInputs();
      this.editMode = true;
    },
    onSaveButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("accommodation/updateAccommodation", {
          uuid: this.accommodation.uuid,
          ...this.editedAccommodation,
        })
        .then(() => {
          notifySuccess(
            "Allotjament actualitzat",
            "L'allotjament s'ha actualitzat correctament"
          );
          this.editMode = false;
        })
        .catch(() =>
          notifyError(
            "Allotjament no actualitzat",
            "L'allotjament no s'ha pogut actualitzar correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
