<template>
  <div v-if="accommodation" id="accommodations-view">
    <b-tabs no-fade>
      <!-- DETAILS TAB -->
      <b-tab active>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="HomeIcon" />
          <span class="d-none d-md-block">{{ $t("Detalls") }}</span>
        </template>

        <summary-card />
        <checkin-checkout-card />
        <location-card />
        <multimedia-card />
        <descriptions-card />
        <faqs-card />
        <arrangements-card />
        <amenities-card />
      </b-tab>

      <!-- CALENDAR TAB -->
      <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="CalendarIcon" />
          <span class="d-none d-md-block">{{ $t("Calendari") }}</span>
        </template>

        <calendar-card />
      </b-tab>

      <!-- BOOKINS TAB -->
      <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="BookIcon" />
          <span class="d-none d-md-block">{{ $t("Reserves") }}</span>
        </template>

        <bookings-card />
      </b-tab>

      <!-- SERVICES TAB -->
      <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="LayersIcon" />
          <span class="d-none d-md-block">{{ $t("Serveis") }}</span>
        </template>

        <services-card />
      </b-tab>

      <!-- DISCOUNTS TAB -->
      <!-- <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="PercentIcon" />
          <span class="d-none d-md-block">{{ $t("Descomptes") }}</span>
        </template>

        <discounts-card />
      </b-tab> -->

      <!-- REVIEWS TAB -->
      <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="MessageSquareIcon" />
          <span class="d-none d-md-block">{{ $t("Ressenyes") }}</span>
        </template>

        <reviews-card />
      </b-tab>

      <!-- CONTRACT TAB -->
      <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="ClipboardIcon" />
          <span class="d-none d-md-block">{{ $t("Contracte") }}</span>
        </template>

        <contract-card />
      </b-tab>

      <!-- ACCOUNTING TAB -->
      <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="FileTextIcon" />
          <span class="d-none d-md-block">{{ $t("Comptabilitat") }}</span>
        </template>

        <accounting-configuration-card />
      </b-tab>

      <!-- STATISTICS TAB -->
      <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="PieChartIcon" />
          <span class="d-none d-md-block">{{ $t("Estadístiques") }}</span>
        </template>

        <statistics-cards />
      </b-tab>

      <!-- NOTIFICATIONS TAB -->
      <b-tab>
        <template #title>
          <feather-icon class="mb-25 mb-lg-none" icon="BellIcon" />
          <span class="d-none d-md-block">{{ $t("Notificacions") }}</span>
        </template>

        <notifications-card />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import SummaryCard from "@/views/accommodations/accommodation/components/SummaryCard.vue";
import CheckinCheckoutCard from "@/views/accommodations/accommodation/components/CheckinCheckoutCard.vue";
import LocationCard from "@/views/accommodations/accommodation/components/LocationCard.vue";
import MultimediaCard from "@/views/accommodations/accommodation/components/MultimediaCard.vue";
import DescriptionsCard from "@/views/accommodations/accommodation/components/DescriptionsCard.vue";
import FaqsCard from "@/views/accommodations/accommodation/components/FaqsCard.vue";
import ArrangementsCard from "@/views/accommodations/accommodation/components/ArrangementsCard.vue";
import AmenitiesCard from "@/views/accommodations/accommodation/components/AmenitiesCard.vue";
import CalendarCard from "@/views/accommodations/accommodation/components/CalendarCard.vue";
import BookingsCard from "@/views/accommodations/accommodation/components/BookingsCard.vue";
import ServicesCard from "@/views/accommodations/accommodation/components/ServicesCard.vue";
// import DiscountsCard from "@/views/accommodations/accommodation/components/DiscountsCard.vue";
import ReviewsCard from "@/views/accommodations/accommodation/components/ReviewsCard.vue";
import ContractCard from "@/views/accommodations/accommodation/components/ContractCard.vue";
import StatisticsCards from "@/views/accommodations/accommodation/components/statistics/StatisticsCards.vue";
import NotificationsCard from "@/views/accommodations/accommodation/components/NotificationsCard.vue";
import AccountingConfigurationCard from "@/views/accommodations/accommodation/components/AccountingConfigurationCard.vue";
import { notifyError } from "@/utils/methods";
import { BTabs, BTab } from "bootstrap-vue";

export default {
  components: {
    BTabs,
    BTab,
    SummaryCard,
    CheckinCheckoutCard,
    LocationCard,
    MultimediaCard,
    DescriptionsCard,
    FaqsCard,
    ArrangementsCard,
    AmenitiesCard,
    CalendarCard,
    BookingsCard,
    ServicesCard,
    // DiscountsCard,
    ReviewsCard,
    ContractCard,
    StatisticsCards,
    NotificationsCard,
    AccountingConfigurationCard,
  },
  data() {
    return {
      accommodaitonNotFound: false,
    };
  },
  computed: {
    loadingBookingPolicies() {
      return this.$store.getters["bookingPolicies/loading"];
    },
    loadingServices() {
      return this.$store.getters["services/loading"];
    },
    loadingAccommodation() {
      return this.$store.getters["accommodation/loadingAccommodation"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
  },
  watch: {
    accommodation(accommodation) {
      // Set page title
      this.setPageTitle(accommodation);
    },
    "$route.params.accommodationUuid"() {
      this.fetchAccommodation();
    },
  },
  mounted() {
    if (!this.loadingAccommodation) this.fetchAccommodation();
    if (!this.loadingBookingPolicies) this.fetchBookingPolicies();
    if (!this.loadingServices) this.fetchServices();

    // Set page title
    this.setPageTitle(this.accommodation);
  },
  beforeDestroy() {
    this.$store.dispatch("accommodation/reset");
    this.setPageTitle(null);
  },
  methods: {
    fetchAccommodation() {
      this.$store.dispatch("app/setLoading", true);
      const { accommodationUuid } = this.$route.params;

      this.$store
        .dispatch("accommodation/fetchAccommodation", accommodationUuid)
        .then(() => {
          // Basic accommodation data already fetched, now fetch the rest of the data
          this.$store.dispatch(
            "accommodation/fetchAccommodationPictures",
            accommodationUuid
          );
          this.$store.dispatch(
            "accommodation/fetchAccommodationFaqs",
            accommodationUuid
          );
          this.$store.dispatch(
            "accommodation/fetchAccommodationBedrooms",
            accommodationUuid
          );
          this.$store.dispatch(
            "accommodation/fetchAccommodationBathrooms",
            accommodationUuid
          );
          this.$store.dispatch(
            "accommodation/fetchAccommodationAmenities",
            accommodationUuid
          );
          this.$store.dispatch(
            "accommodation/fetchAccommodationAvailabilities",
            accommodationUuid
          );
          this.$store.dispatch(
            "accommodation/fetchAccommodationRates",
            accommodationUuid
          );
          this.$store.dispatch(
            "accommodation/fetchAccommodationDatesLocks",
            accommodationUuid
          );
          this.$store.dispatch(
            "accommodation/fetchAccommodationBookings",
            accommodationUuid
          );
          this.$store.dispatch(
            "accommodation/fetchServices",
            accommodationUuid
          );
          this.$store.dispatch(
            "accommodation/fetchAccommodationReviews",
            accommodationUuid
          );
          if (this.accommodation?.contract) {
            this.$store.dispatch(
              "accommodation/fetchContract",
              this.accommodation.contract.uuid
            );
          }
          if (this.accommodation?.owner) {
            this.$store.dispatch(
              "accommodation/fetchOwner",
              this.accommodation.owner.uuid
            );
          }
        })
        .catch((error) => {
          if (error.response.status === 404) this.accommodaitonNotFound = true;
          notifyError(
            this.$t("errors.fetchAccommodation.title"),
            this.$t("errors.fetchAccommodation.description")
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));

      this.$store.dispatch("owners/fetchOwners", {
        pagination: false,
        sort: "asc",
      });
    },
    fetchBookingPolicies() {
      this.$store
        .dispatch("bookingPolicies/fetchPolicies", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intenter carregar les polítiques de reserva"
          )
        );
    },
    fetchServices() {
      this.$store
        .dispatch("services/fetchServices", { pagination: false, sort: "asc" })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intenter carregar els serveis"
          )
        );
    },
    setPageTitle(accommodation) {
      this.$store.dispatch("app/setPageTitle", accommodation?.name || null);
      this.$store.dispatch(
        "app/setPageSubtitle",
        accommodation?.location?.city || null
      );
    },
  },
};
</script>
