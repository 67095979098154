<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card-actions no-body action-collapse :collapsed="true">
      <!-- LEFT ACTIONS -->
      <template #left-actions>
        <b-form-checkbox
          v-model="approved"
          switch
          color="success"
          @change="onToggleApproved"
        >
          {{ approved ? "Publicada" : "No publicada" }}
        </b-form-checkbox>
      </template>

      <!-- TITLE -->
      <template #title>
        <div class="d-flex justify-content-between">
          <div>
            <b-card-title
              v-if="reviewAuthor"
              :class="{ 'mb-1': reviewSubtitle }"
            >
              {{ reviewAuthor }}
            </b-card-title>
            <b-card-sub-title>
              {{ reviewSubtitle }}
              <!-- BOOKING -->
              <span v-if="review.booking">
                <span class="d-none d-md-inline"> Reserva </span>
                <router-link
                  class="text-sm font-light"
                  :to="{
                    name: 'foravila-booking-view',
                    params: { localizator: review.booking.localizator },
                  }"
                  @click.stop
                >
                  {{ review.booking.localizator }}
                </router-link>
              </span>
            </b-card-sub-title>
          </div>
          <b-card-title class="mt-1">
            {{ review.averageRating }}
            <span class="d-none d-sm-inline"> / {{ maxRating }} </span>
          </b-card-title>
        </div>
      </template>

      <!-- BODY -->
      <b-card-body class="pb-2">
        <!-- REVIEW SUMMARY -->
        <b-row class="mb-2 pt-2 border-top border-bottom">
          <!-- FACILITIES -->
          <b-col cols="12" md="2" class="mb-1"> Instal·lacions </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-progress :max="100" variant="primary" show-value>
              <b-progress-bar :value="getRatingPercent(review.rateFacilities)">
                <strong>{{ getRatingPercent(review.rateFacilities) }}%</strong>
              </b-progress-bar>
            </b-progress>
          </b-col>
          <!-- LOCATION -->
          <b-col cols="12" md="2" class="mb-1"> Ubicació </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-progress :max="100" variant="primary" show-value>
              <b-progress-bar :value="getRatingPercent(review.rateLocation)">
                <strong>{{ getRatingPercent(review.rateLocation) }}%</strong>
              </b-progress-bar>
            </b-progress>
          </b-col>
          <!-- CLEANING -->
          <b-col cols="12" md="2" class="mb-1"> Neteja </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-progress :max="100" variant="primary" show-value>
              <b-progress-bar :value="getRatingPercent(review.rateCleaning)">
                <strong>{{ getRatingPercent(review.rateCleaning) }}%</strong>
              </b-progress-bar>
            </b-progress>
          </b-col>
          <!-- COMMUNICATION -->
          <b-col cols="12" md="2" class="mb-1"> Comunicació </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-progress :max="100" variant="primary" show-value>
              <b-progress-bar
                :value="getRatingPercent(review.rateCommunication)"
              >
                <strong>
                  {{ getRatingPercent(review.rateCommunication) }}%
                </strong>
              </b-progress-bar>
            </b-progress>
          </b-col>
          <!-- CHECKIN -->
          <b-col cols="12" md="2" class="mb-1"> Arribada </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-progress :max="100" variant="primary" show-value>
              <b-progress-bar :value="getRatingPercent(review.rateCheckin)">
                <strong>{{ getRatingPercent(review.rateCheckin) }}%</strong>
              </b-progress-bar>
            </b-progress>
          </b-col>
          <!-- ACCURACY -->
          <b-col cols="12" md="2" class="mb-1"> Veracitat </b-col>
          <b-col cols="12" md="4" class="mb-1">
            <b-progress :max="100" variant="primary" show-value>
              <b-progress-bar :value="getRatingPercent(review.rateAccuracy)">
                <strong>{{ getRatingPercent(review.rateAccuracy) }}%</strong>
              </b-progress-bar>
            </b-progress>
          </b-col>
        </b-row>

        <!-- COMMENTS -->
        <b-row>
          <!-- PUBLIC COMMENT -->
          <b-col cols="12" class="mb-2">
            <h5 class="mb-1">Comentari públic</h5>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="reviewPublicComment" />
          </b-col>
          <!-- PRIVATE COMMENT -->
          <b-col cols="12" class="mb-2">
            <h5 class="mb-1">Comentari privat</h5>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="reviewPrivateComment" />
          </b-col>
          <!-- PUBLIC ANSWER -->
          <b-col cols="12">
            <h5 class="mb-1">
              Resposta pública
              <small v-if="reviewPrivateComment && reviewPublicReplyAuthor">
                ({{ reviewPublicReplyAuthor }})
              </small>
            </h5>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-if="reviewPublicReply" v-html="reviewPublicReply" />
            <b-alert
              v-else
              class="mb-2"
              variant="primary"
              :show="!reviewPublicReply"
            >
              <div class="alert-body">
                Encara no s'ha contestat aquesta ressenya
              </div>
            </b-alert>
          </b-col>
        </b-row>

        <!-- BUTTONS -->
        <b-row class="d-flex justify-content-between justify-content-sm-end">
          <b-col cols="6" sm="auto">
            <b-button variant="danger" block @click="confirmDeleteReview">
              Elimina
            </b-button>
          </b-col>
          <b-col cols="6" sm="auto">
            <b-button
              block
              variant="primary"
              @click="onEditButtonClicked(review)"
            >
              Edita
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- EDIT REVIEW MODAL -->
      <b-modal
        :id="`edit-review-modal-${review.uuid}`"
        title="Edita la ressenya"
        scrollable
        hide-footer
      >
        <review-form
          :review="review"
          @cancel="onEditReviewCancel(review)"
          @review-edited="onReviewEdited(review)"
          @edit-review-error="onEditReviewError"
        />
      </b-modal>
    </b-card-actions>
  </b-overlay>
</template>

<script>
import BCardActions from "@/@foravila-core/components/b-card-actions/BCardActions.vue";
import {
  BOverlay,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BFormCheckbox,
  BRow,
  BCol,
  BProgress,
  BButton,
  BAlert,
  BProgressBar,
} from "bootstrap-vue";
import {
  formatDateObjectToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import {
  getOtaName,
  getTeamMemberName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import ReviewForm from "@/views/accommodations/accommodation/components/ReviewForm.vue";

export default {
  components: {
    BOverlay,
    BCardActions,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BRow,
    BCol,
    BProgress,
    BButton,
    ReviewForm,
    BAlert,
    BProgressBar,
  },
  props: {
    review: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      maxRating: 5,
      approved: false,
      reply: null,
    };
  },
  computed: {
    reviewAuthor() {
      return this.review?.author || "Anònim";
    },
    reviewPostedDate() {
      const formatting = { day: "numeric", month: "long", year: "numeric" };
      return formatDateStringToDate(
        this.review?.postedAt,
        this.$i18n.locale,
        formatting
      );
    },
    reviewSource() {
      return getOtaName(this.review?.source);
    },
    reviewSubtitle() {
      const subtitle = [];
      if (this.reviewPostedDate) subtitle.push(this.reviewPostedDate);
      if (this.reviewSource) subtitle.push(this.reviewSource);
      if (this.review.booking) subtitle.push("");
      return subtitle.length ? subtitle.join(" · ") : null;
    },
    reviewPublicComment() {
      return this.review?.comment || "No disponible";
    },
    reviewPrivateComment() {
      return this.review?.privateComment || "No disponible";
    },
    reviewPublicReplyAuthor() {
      return getTeamMemberName(this.review?.replyAuthor);
    },
    reviewPublicReply() {
      return this.review?.reply || null;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.approved = this.review.approved;
    },
    onToggleApproved() {
      this.$swal({
        title: `${this.approved ? "Aprova ressenya" : "Rebutja ressenya"}`,
        text: `Estàs a punt de ${
          this.approved ? "aprovar" : "rebutjar"
        } aquesta ressenya, estàs segur/a?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: `${this.approved ? "Aprova" : "Rebutja"}`,
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: `btn ${this.approved ? "btn-primary" : "btn-danger"}`,
          cancelButton: `btn ${
            this.approved ? "btn-outline-primary" : "btn-outline-danger"
          } ml-1`,
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.confirmToggleApproved();
        else this.rejectToggleApproved();
      });
    },
    confirmToggleApproved() {
      this.loading = true;
      this.$store
        .dispatch("accommodation/updateAccommodationReview", {
          uuid: this.review.uuid,
          approved: this.approved,
          reviewedAt: formatDateObjectToDatabaseDate(new Date()),
        })
        .catch(() => {
          notifyError(
            "Ressenya no actualitzada",
            "Hi ha hagut un error al intentar actualitzar la ressenya"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    rejectToggleApproved() {
      this.approved = !this.approved;
    },
    getRatingPercent(rating) {
      if (!rating) return 0;
      return (100 / this.maxRating) * rating;
    },
    onEditButtonClicked(review) {
      this.$bvModal.show(`edit-review-modal-${review.uuid}`);
    },
    onEditReviewCancel(review) {
      this.$bvModal.hide(`edit-review-modal-${review.uuid}`);
    },
    onReviewEdited(review) {
      this.$bvModal.hide(`edit-review-modal-${review.uuid}`);
      notifySuccess(
        "Ressenya editada",
        "La ressenya ha estat editada correctament"
      );
    },
    onEditReviewError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar editar la ressenya"
      );
    },
    confirmDeleteReview() {
      this.$swal({
        title: "Eliminar ressenya",
        text: "Estàs a punt d'eliminar aquesta ressenya, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteReview();
      });
    },
    deleteReview() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("accommodation/deleteAccommodationReview", this.review.uuid)
        .then(() => {
          notifySuccess(
            "Ressenya eliminada",
            "La ressenya ha estat eliminada correctament."
          );
        })
        .catch(() => {
          notifyError(
            "Ressenya no eliminada",
            "Hi ha hagut un error al eliminar la ressenya."
          );
          // TODO: log error in Sentry
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
