<template>
  <div id="booking-policy-form">
    <!-- DATES -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Dates</h6>
      </b-col>
      <!-- START DATE -->
      <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
        <b-form-datepicker
          v-model="startDate"
          placeholder="Inici"
          :locale="locale"
          :start-weekday="1"
          :show-decade-nav="false"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          hide-header
        />
      </b-col>
      <!-- END DATE -->
      <b-col cols="12" sm="6">
        <b-form-datepicker
          v-model="endDate"
          placeholder="Fi"
          :min="startDate"
          :locale="locale"
          :start-weekday="1"
          :show-decade-nav="false"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          hide-header
        />
      </b-col>
    </b-row>

    <!-- BOOKING POLICY -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Política de reserva</h6>
      </b-col>
      <b-col cols="12">
        <v-select
          v-model="bookingPolicy"
          :options="bookingPoliciesOptions"
          :reduce="(option) => option.value"
        />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormDatepicker, BButton } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: "BookingPolicyForm",
  components: {
    BRow,
    BCol,
    BFormDatepicker,
    vSelect,
    BButton,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      bookingPolicy: null,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    locale() {
      return this.$i18n.locale;
    },
    bookingPoliciesOptions() {
      return this.$store.getters["bookingPolicies/policies"].map((policy) => ({
        label: policy.name,
        value: policy["@id"],
      }));
    },
  },
  watch: {
    startDate(startDate) {
      if (startDate && (!this.endDate || this.endDate < startDate))
        this.endDate = startDate;
    },
  },
  methods: {
    resetForm() {
      this.startDate = null;
      this.endDate = null;
      this.bookingPolicy = null;
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    save() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("accommodation/setAccommodationBookingPolicies", {
            accommodation: this.accommodation["@id"],
            startDate: this.startDate,
            endDate: this.endDate,
            bookingPolicy: this.bookingPolicy,
          })
          .then(() => this.$emit("policies-added"))
          .catch(() => {
            this.$emit("add-policies-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
  },
};
</script>

<style scoped>
#booking-policy-form {
  min-height: 400px;
}
</style>
