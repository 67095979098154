<template>
  <div id="rate-form">
    <!-- DATES -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Dates</h6>
      </b-col>
      <!-- START DATE -->
      <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
        <b-form-datepicker
          v-model="startDate"
          placeholder="Inici"
          :locale="locale"
          :start-weekday="1"
          :show-decade-nav="false"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          hide-header
          @hidden="onStartDateDatepickerHidden"
        />
      </b-col>
      <!-- END DATE -->
      <b-col cols="12" sm="6">
        <b-form-datepicker
          ref="endDateDatepicker"
          v-model="endDate"
          placeholder="Fi"
          :min="startDate"
          :locale="locale"
          :start-weekday="1"
          :show-decade-nav="false"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          hide-header
        />
      </b-col>
    </b-row>

    <!-- SELECT OWNER -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Propietari</h6>
      </b-col>
      <b-col cols="12">
        <v-select
          v-model="owner"
          :options="ownerOptions"
          :reduce="(option) => option.value"
        />
      </b-col>
    </b-row>

    <!-- CREATE OWNER -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-2 mb-sm-0">
        <b-row>
          <b-col cols="12" class="mb-1">
            <h6>Nom</h6>
          </b-col>
          <b-col cols="12">
            <b-form-input v-model="firstName" :disabled="!!owner" />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="6" class="mb-2 mb-sm-0">
        <b-row>
          <b-col cols="12" class="mb-1">
            <h6>Llinatges</h6>
          </b-col>
          <b-col cols="12">
            <b-form-input v-model="lastName" :disabled="!!owner" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-2 mb-sm-0">
        <b-row>
          <b-col cols="12" class="mb-1">
            <h6>Email</h6>
          </b-col>
          <b-col cols="12">
            <b-form-input v-model="email" :disabled="!!owner" />
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="6" class="mb-2 mb-sm-0">
        <b-row>
          <b-col cols="12" class="mb-1">
            <h6>DNI</h6>
          </b-col>
          <b-col cols="12">
            <b-form-input v-model="idNumber" :disabled="!!owner" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormDatepicker,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { formatDateObjectToDatabaseDate } from "@/utils/formatters";

export default {
  components: {
    BRow,
    BCol,
    BFormDatepicker,
    vSelect,
    BFormInput,
    BButton,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      owner: null,
      firstName: null,
      lastName: null,
      email: null,
      idNumber: null,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    locale() {
      return this.$i18n.locale;
    },
    ownerOptions() {
      return this.$store.getters["owners/owners"].map((owner) => ({
        label: owner.fullName,
        value: owner["@id"],
      }));
    },
  },
  watch: {
    startDate(startDate) {
      if (startDate && (!this.endDate || this.endDate < startDate)) {
        this.endDate = new Date(startDate);
        this.endDate.setFullYear(this.endDate.getFullYear() + 1);
        this.endDate = formatDateObjectToDatabaseDate(this.endDate);
      }
    },
    owner(owner) {
      if (owner) {
        this.firstName = null;
        this.lastName = null;
        this.email = null;
        this.idNumber = null;
      }
    },
  },
  methods: {
    resetForm() {
      this.startDate = null;
      this.endDate = null;
      this.owner = null;
      this.firstName = null;
      this.lastName = null;
      this.email = null;
      this.idNumber = null;
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    save() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("accommodation/addContract", {
          accommodation: this.accommodation["@id"],
          startDate: this.startDate,
          endDate: this.endDate,
          owner: this.owner || null,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          idType: "NIF",
          idNumber: this.idNumber,
        })
        .then(() => this.$emit("contract-added"))
        .catch(() => {
          this.$emit("add-contract-error");
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onStartDateDatepickerHidden() {
      this.$refs.endDateDatepicker.isVisible = true;
    },
  },
};
</script>
