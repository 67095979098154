<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card id="calendar-card">
      <!-- CALENDAR -->
      <b-row>
        <b-col cols="12">
          <calendar-view
            ref="calendar"
            display-period-uom="month"
            :starting-day-of-week="1"
            :show-date="showDate"
            :items="events"
            :item-top="windowWidth <= 400 ? '2rem' : '3rem'"
            item-border-height="0px"
            item-content-height="1.65rem"
            class="theme-default p-0"
            @click-item="onEventClicked"
          >
            <div slot="header">
              <b-row class="mb-2">
                <!-- ACTION BUTTONS -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-content-center justify-content-sm-start mb-2 mb-sm-0"
                >
                  <b-dropdown text="Afegeix" variant="primary" class="mr-1">
                    <b-dropdown-item
                      @click.prevent="$bvModal.show('add-rates-modal')"
                    >
                      Nova tarifa
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click.prevent="
                        $bvModal.show('add-booking-policies-modal')
                      "
                    >
                      Nova política
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click.prevent="$bvModal.show('add-dates-lock-modal')"
                    >
                      Nou bloqueig
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click.prevent="$bvModal.show('add-owner-rates-modal')"
                    >
                      Nou preu propietari
                    </b-dropdown-item>
                  </b-dropdown>

                  <b-dropdown text="Actualitza" variant="primary">
                    <b-dropdown-item @click.prevent="onUpdateRatesClicked">
                      Tarifes
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click.prevent="onUpdateAvailabilitiesClicked"
                    >
                      Disponibilitats
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>

                <!-- MONTH SELECTOR -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex justify-content-center justify-content-sm-end"
                >
                  <div class="d-flex justify-content-between">
                    <b-button
                      class="btn-icon rounded-circle"
                      variant="primary"
                      @click="updateMonth(-12)"
                    >
                      <feather-icon icon="ChevronsLeftIcon" />
                    </b-button>
                    <b-button
                      class="btn-icon rounded-circle ml-50"
                      variant="primary"
                      @click="updateMonth(-1)"
                    >
                      <feather-icon icon="ChevronLeftIcon" />
                    </b-button>

                    <span
                      class="d-flex align-items-center justify-content-center font-weight-bold mx-50 month-and-year-text px-1"
                      @click="updateMonth(null)"
                      title="Clica per tornar a la data actual"
                    >
                      {{ monthAndYear }}
                    </span>

                    <b-button
                      class="btn-icon rounded-circle mr-50"
                      variant="primary"
                      @click="updateMonth(1)"
                    >
                      <feather-icon icon="ChevronRightIcon" />
                    </b-button>
                    <b-button
                      class="btn-icon rounded-circle"
                      variant="primary"
                      @click="updateMonth(12)"
                    >
                      <feather-icon icon="ChevronsRightIcon" />
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- <div
              slot="item"
              slot-scope="{ value, weekStartDate, top }"
            >
              Value: {{ value }}<br>
              Week start date: {{ weekStartDate }}<br>
              Top: {{ top }}<br>
            </div> -->
          </calendar-view>
        </b-col>
      </b-row>

      <!-- ADD RATES MODAL -->
      <b-modal
        :id="'add-rates-modal'"
        title="Afegeix una tarifa"
        scrollable
        hide-footer
      >
        <rate-form
          @cancel="onAddRatesCancel"
          @rates-added="onRatesAdded"
          @add-rates-error="onAddRatesError"
        />
      </b-modal>

      <!-- ADD OWNER RATES MODAL -->
      <b-modal
        :id="'add-owner-rates-modal'"
        title="Afegeix una tarifa propietari"
        scrollable
        hide-footer
        size="lg"
      >
        <owner-rate-form
          @cancel="onAddOwnerRatesCancel"
          @owner-rates-added="onOwnerRatesAdded"
          @add-owner-rates-error="onAddOwnerRatesError"
        />
      </b-modal>

      <!-- ADD BOOKING POLICIES MODAL -->
      <b-modal
        :id="'add-booking-policies-modal'"
        title="Afegeix una política de reserva"
        scrollable
        hide-footer
      >
        <booking-policy-form
          @cancel="onAddPoliciesCancel"
          @policies-added="onPoliciesAdded"
          @add-policies-error="onAddPoliciesError"
        />
      </b-modal>

      <!-- ADD DATES LOCK MODAL -->
      <b-modal
        :id="'add-dates-lock-modal'"
        title="Afegeix un bloqueig"
        scrollable
        hide-footer
      >
        <dates-lock-form
          @cancel="onAddDatesLockCancel"
          @dates-lock-added="onDatesLockAdded"
          @add-dates-lock-error="onAddDatesLockError"
        />
      </b-modal>

      <!-- EDIT DATES LOCK MODAL -->
      <b-modal
        :id="'edit-dates-lock-modal'"
        title="Edita el bloqueig"
        scrollable
        hide-footer
      >
        <dates-lock-form
          :dates-lock="selectedDatesLock"
          @cancel="onEditDatesLockCancel"
          @dates-lock-edited="onDatesLockEdited"
          @edit-dates-lock-error="onEditDatesLockError"
          @dates-lock-deleted="onDatesLockDeleted"
          @delete-dates-lock-error="onDeleteDatesLockError"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BButton,
  BModal,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { CalendarView } from "vue-simple-calendar";
import RateForm from "@/views/accommodations/accommodation/components/RateForm.vue";
import OwnerRateForm from "@/views/accommodations/accommodation/components/OwnerRateForm.vue";
import BookingPolicyForm from "@/views/accommodations/accommodation/components/BookingPolicyForm.vue";
import DatesLockForm from "@/views/accommodations/accommodation/components/DatesLockForm.vue";
import {
  notifyError,
  notifySuccess,
  getOtaName,
  getDatesLockName,
} from "@/utils/methods";
import {
  formatDateObjectToDate,
  formatDateStringToDate,
} from "@/utils/formatters";

require("vue-simple-calendar/static/css/default.css");

export default {
  name: "CalendarCard",
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BModal,
    CalendarView,
    RateForm,
    OwnerRateForm,
    BookingPolicyForm,
    DatesLockForm,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      showDate: new Date(),
      selectedDatesLock: null,
      selectedBooking: null,
    };
  },
  computed: {
    loading() {
      return (
        this.loadingAvailabilities ||
        this.loadingRates ||
        this.loadingDatesLocks ||
        this.loadingBookings
      );
    },
    loadingAvailabilities() {
      return this.$store.getters["accommodation/loadingAvailabilities"];
    },
    loadingRates() {
      return this.$store.getters["accommodation/loadingRates"];
    },
    loadingDatesLocks() {
      return this.$store.getters["accommodation/loadingDatesLocks"];
    },
    loadingBookings() {
      return this.$store.getters["accommodation/loadingBookings"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    rates() {
      return this.$store.getters["accommodation/rates"];
    },
    availabilities() {
      return this.$store.getters["accommodation/availabilities"];
    },
    datesLocks() {
      return this.$store.getters["accommodation/datesLocks"];
    },
    bookings() {
      return this.$store.getters["accommodation/bookings"];
    },
    events() {
      const events = [];
      let eventId = 0;

      this.availabilities.forEach((availability) => {
        const availabilityEvent = {
          id: (eventId += 1),
          type: "AVAILABILITY",
          title: `${availability.available ? "Disponible" : "No Disponible"}`,
          startDate: new Date(availability.date),
          endDate: new Date(availability.date),
          classes: `${
            availability.available
              ? "event-day-available"
              : "event-day-unavailable"
          }`,
        };
        events.push(availabilityEvent);
      });

      this.rates.forEach((rate) => {
        const startDate = new Date(rate.date);
        const endDate = new Date(rate.date);

        const priceEvent = {
          id: (eventId += 1),
          type: "RATES",
          title: `👫 ${rate.price / 100} € 🔑 ${rate.ownerPrice / 100} €`,
          startDate,
          endDate,
          classes: rate.price === 0 ? "event-danger" : "event-text-only",
        };
        events.push(priceEvent);

        const minStayEvent = {
          id: (eventId += 1),
          type: "MIN_STAY",
          title: `Mínim: ${rate.minimumStay} dies`,
          startDate,
          endDate,
          classes: rate.minimumStay === 0 ? "event-danger" : "event-text-only",
        };
        events.push(minStayEvent);

        if (rate.bookingPolicy) {
          const bookingPolicyEvent = {
            id: (eventId += 1),
            type: "BOOKING_POLICY",
            title: `Política: ${rate.bookingPolicy.name}`,
            startDate,
            endDate,
            classes: "event-text-only",
          };
          events.push(bookingPolicyEvent);
        }
      });

      this.datesLocks.forEach((datesLock) => {
        const datesLockEvent = {
          id: (eventId += 1),
          type: "DATES_LOCK",
          uuid: datesLock.uuid,
          title: `${getDatesLockName(datesLock.scope, this.$i18n.locale)}`,
          startDate: new Date(datesLock.startDate),
          endDate: new Date(datesLock.endDate),
          classes: "event-blocked",
        };
        events.push(datesLockEvent);
      });

      this.bookings.forEach((booking) => {
        if (booking.cancelled) return;

        const lastNight = new Date(booking.checkout);
        lastNight.setDate(lastNight.getDate() - 1);

        const bookingCheckin = formatDateStringToDate(booking.checkin);
        const bookingCheckout = formatDateStringToDate(booking.checkout);

        const bookingEvent = {
          id: (eventId += 1),
          type: "BOOKING",
          localizator: booking.localizator,
          title: `${booking.localizator} | ${getOtaName(
            booking.source
          )} | ${bookingCheckin} - ${bookingCheckout}`,
          startDate: new Date(booking.checkin),
          endDate: lastNight,
          classes: this.getBookingStatusClasses(booking.status),
        };

        events.push(bookingEvent);
      });

      return events;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    monthAndYear() {
      const formatting = { month: "long", year: "numeric" };
      return formatDateObjectToDate(
        this.showDate,
        this.$i18n.locale,
        formatting
      );
    },
  },
  methods: {
    updateMonth(val) {
      if (!val) this.showDate = new Date();
      else this.showDate = this.$refs.calendar.getIncrementedPeriod(val);
    },
    fetchEvents() {
      this.$store.dispatch("app/setLoading", true);

      const { accommodationUuid } = this.$route.params;
      const promises = [];

      promises.push(
        this.$store.dispatch(
          "accommodation/fetchAccommodationAvailabilities",
          accommodationUuid
        )
      );

      promises.push(
        this.$store.dispatch(
          "accommodation/fetchAccommodationRates",
          accommodationUuid
        )
      );

      promises.push(
        this.$store.dispatch(
          "accommodation/fetchAccommodationDatesLocks",
          accommodationUuid
        )
      );

      promises.push(
        this.$store.dispatch(
          "accommodation/fetchAccommodationBookings",
          accommodationUuid
        )
      );

      Promise.all(promises)
        .catch(() => {
          // TODO: log error to Sentry
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onEventClicked(event) {
      if (event.originalItem.type === "DATES_LOCK") {
        this.selectedDatesLock = this.datesLocks.find(
          (dl) => dl.uuid === event.originalItem.uuid
        );
        this.$bvModal.show("edit-dates-lock-modal");
      }

      if (event.originalItem.type === "BOOKING") {
        this.$router.push({
          name: "foravila-booking-view",
          params: { localizator: event.originalItem.localizator },
        });
      }
    },
    onAddRatesCancel() {
      this.$bvModal.hide("add-rates-modal");
    },
    onAddOwnerRatesCancel() {
      this.$bvModal.hide("add-owner-rates-modal");
    },
    onAddRatesError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar actualitzar les tarifes"
      );
    },
    onAddOwnerRatesError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar actualitzar les tarifes propietari"
      );
    },
    onRatesAdded() {
      this.fetchEvents();
      this.$bvModal.hide("add-rates-modal");
      notifySuccess(
        "Tarifes actualitzades",
        "Les tarifes han estat actualitzades correctament"
      );
    },
    onOwnerRatesAdded() {
      this.fetchEvents();
      this.$bvModal.hide("add-owner-rates-modal");
      notifySuccess(
        "Tarifes propietari actualitzades",
        "Les tarifes propietari han estat actualitzades correctament"
      );
    },
    onAddPoliciesCancel() {
      this.$bvModal.hide("add-booking-policies-modal");
    },
    onAddPoliciesError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar actualitzar les polítiques de reserva"
      );
    },
    onPoliciesAdded() {
      this.fetchEvents();
      this.$bvModal.hide("add-booking-policies-modal");
      notifySuccess(
        "Polítiques de reserva actualitzades",
        "Les polítiques de reserva han estat actualitzades correctament"
      );
    },
    onAddDatesLockCancel() {
      this.$bvModal.hide("add-dates-lock-modal");
    },
    onDatesLockAdded() {
      this.fetchEvents();
      this.$bvModal.hide("add-dates-lock-modal");
      notifySuccess(
        "Bloqueig afegit",
        "El nou bloqueig ha estat afegit correctament"
      );
    },
    onAddDatesLockError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar afegir el nou bloqueig"
      );
    },
    onEditDatesLockCancel() {
      this.$bvModal.hide("edit-dates-lock-modal");
    },
    onDatesLockEdited() {
      this.fetchEvents();
      this.$bvModal.hide("edit-dates-lock-modal");
      notifySuccess(
        "Bloqueig editat",
        "El bloqueig ha estat editat correctament"
      );
    },
    onEditDatesLockError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar editar el bloqueig"
      );
    },
    onDatesLockDeleted() {
      this.fetchEvents();
      this.$bvModal.hide("edit-dates-lock-modal");
      notifySuccess(
        "Bloqueig eliminat",
        "El bloqueig ha estat eliminat correctament"
      );
    },
    onDeleteDatesLockError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar eliminar el bloqueig"
      );
    },
    onUpdateRatesClicked() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch(
          "accommodation/importAccommodationRates",
          this.accommodation.slug
        )
        .then(() => this.fetchEvents())
        .catch(() => {
          notifyError(
            "Tarifes no importades",
            "Hi ha hagut un error al intentar importar les tarifes desde el channel manager"
          );
          this.$store.dispatch("app/setLoading", false);
        });
    },
    onUpdateAvailabilitiesClicked() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch(
          "accommodation/importAccommodationAvailabilities",
          this.accommodation.slug
        )
        .then(() => this.fetchEvents())
        .catch(() => {
          notifyError(
            "Disponibilitats no importades",
            "Hi ha hagut un error al intentar importar les disponibilitats desde el channel manager"
          );
          this.$store.dispatch("app/setLoading", false);
        });
    },
    getBookingStatusClasses(status) {
      if (!status) return "event-booking";
      switch (status) {
        case "COMPLETED":
          return "event-booking-completed";
        case "CONFIRMED":
          return "event-booking-confirmed";
        case "CANCELLED":
          return "event-booking-cancelled";
        case "REQUESTED":
          return "event-booking-requested";
        default:
          return "event-booking";
      }
    },
  },
};
</script>

<style lang="scss">
.month-and-year-text {
  min-width: 135px;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
}
.theme-default {
  .cv-header-days {
    border-bottom: 1px solid #ddd;
  }

  .cv-header,
  .cv-header-day {
    background-color: transparent;
    font-weight: bold;
  }

  .cv-day {
    &.outsideOfMonth {
      background-color: rgba(241, 241, 241, 0.4);
    }

    .cv-day-number {
      padding: 1rem;
      @media screen and (max-width: 400px) {
        padding: 0;
        margin: 5px;
      }
    }

    &.today {
      .cv-day-number {
        background-color: #ffcd00;
        border-radius: 50%;
        display: inline-table;
        padding: 0.43rem;
        margin: 0.4rem;
        color: #fff;
        text-align: center;
        font-weight: 600;
        min-width: 2rem;
        min-height: 2rem;
        @media screen and (max-width: 400px) {
          margin: 5px;
          padding: 0.3rem;
        }
      }
    }
  }

  .cv-weeks {
    .cv-week {
      min-height: 13rem;

      .cv-item {
        color: white;
        border: none;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        font-weight: 500;
        font-size: 12px;

        &.event-booking {
          background: #ffcc00 !important;
        }
        &.event-booking-confirmed {
          background: #ecbe04 !important;
        }
        &.event-booking-completed {
          background: #c39d04 !important;
        }
        &.event-booking-requested {
          background: rgb(15, 174, 222) !important;
        }
        &.event-booking-cancelled {
          background: rgb(199, 12, 12) !important;
        }
        &.event-day-available {
          background: #1e9e0b !important;
        }
        &.event-day-unavailable {
          background: #c62222 !important;
        }
        &.event-primary {
          background: #ffcd00 !important;
        }
        &.event-warning {
          background: orange !important;
        }
        &.event-success {
          background: green !important;
        }
        &.event-danger {
          background: red !important;
        }
        &.event-booked {
          background: gray !important;
        }
        &.event-blocked {
          background: rgb(73, 73, 73) !important;
        }
        &.event-text-only {
          background: transparent !important;
          color: black;
        }
        &.toBeContinued:after,
        &.continued:before {
          color: white !important;
        }

        &:hover {
          cursor: pointer;
        }
      }

      &:first-of-type {
        .cv-day {
          border-top: none;
        }
      }
    }
  }
}
</style>
