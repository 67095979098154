<template>
  <b-overlay :show="loadingFaqs" rounded="sm" variant="white">
    <b-card title="FAQ's">
      <b-row>
        <b-col>
          <b-tabs no-fade>
            <faqs-language-tab
              v-for="language in faqsLanguages"
              :key="`language-${language}`"
              :language="language"
              :faqs="getLanguageFaqs(language)"
            />
          </b-tabs>

          <b-button
            size="sm"
            variant="primary"
            class="mt-2"
            @click="onNewFaqButtonClicked"
          >
            <feather-icon icon="PlusIcon" />
            Nova <span class="d-none d-md-inline">FAQ</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- ADD FAQ MODAL -->
      <b-modal
        id="add-faq-modal"
        hide-footer
        centered
        title="Afegeix una nova FAQ"
      >
        <faq-form
          @cancel="onAddFaqCancel"
          @added="onFaqsAdded"
          @add-error="onAddFaqError"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BButton,
  BTabs,
  BModal,
} from "bootstrap-vue";
import FaqForm from "@/views/accommodations/accommodation/components/FaqForm.vue";
import FaqsLanguageTab from "@/views/accommodations/accommodation/components/FaqsLanguageTab.vue";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BTabs,
    BModal,
    FaqForm,
    FaqsLanguageTab,
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    loadingFaqs() {
      return this.$store.getters["accommodation/loadingFaqs"];
    },
    faqs() {
      return this.$store.getters["accommodation/faqs"];
    },
    faqsLanguages() {
      const languages = this.faqs.map((faq) => faq.language);
      return [...new Set(languages)];
    },
  },
  methods: {
    onNewFaqButtonClicked() {
      this.$bvModal.show("add-faq-modal");
    },
    onAddFaqCancel() {
      this.$bvModal.hide("add-faq-modal");
    },
    onAddFaqError() {
      this.$bvModal.hide("add-faq-modal");
      notifyError("Error", "Hi ha hagut un erro al intentar afegir la FAQ");
    },
    onFaqsAdded() {
      this.$bvModal.hide("add-faq-modal");
      notifySuccess("FAQ afegida", "La FAQ ha estat afegida correctament");
    },
    getLanguageFaqs(language) {
      if (!this.faqs.length) return [];
      return this.faqs.filter((faq) => faq.language === language);
    },
  },
};
</script>
