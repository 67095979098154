<template>
  <b-card id="accommodation-summary-card" no-body>
    <b-card-header>
      <b-card-title>Entrada i Sortida</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-row class="mb-2">
        <!-- SELF CHECK-IN -->
        <b-col cols="6" sm="4" md="6" xl="3" class="mb-2">
          <h6 class="mb-75">Entrada autònoma</h6>
          <b-form-checkbox
            v-if="editMode"
            v-model="editedDetails.selfCheckin"
            switch
          />
          <b-card-text v-else>
            {{ selfCheckin }}
          </b-card-text>
        </b-col>

        <!-- SELF CHECK-OUT -->
        <b-col cols="6" sm="4" md="6" xl="3" class="mb-2">
          <h6 class="mb-75">Sortida autònoma</h6>
          <b-form-checkbox
            v-if="editMode"
            v-model="editedDetails.selfCheckout"
            switch
          />
          <b-card-text v-else>
            {{ selfCheckout }}
          </b-card-text>
        </b-col>

        <!-- LOCKBOX CODE -->
        <b-col cols="12" sm="4" md="6" xl="3" class="mb-2">
          <h6 class="mb-75">Codi lockbox</h6>
          <b-form-input
            v-if="editMode"
            v-model="editedDetails.lockboxCode"
            placeholder="Codi"
          />
          <b-card-text v-else>
            {{ lockboxCode }}
          </b-card-text>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            Cancel·la
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="onSaveButtonClicked">
            Guarda
          </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="onEditButtonClicked">
            Edita
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BFormInput,
} from "bootstrap-vue";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  name: "SummaryCard",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardText,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormInput,
  },
  data() {
    return {
      editMode: false,
      editedDetails: {
        selfCheckin: false,
        selfCheckout: false,
        lockboxCode: null,
      },
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    details() {
      return this.accommodation?.details || null;
    },
    selfCheckin() {
      if (!this.details) return this.$t("No disponible");
      return this.details.selfCheckin ? "Sí" : "No";
    },
    selfCheckout() {
      if (!this.details) return this.$t("No disponible");
      return this.details.selfCheckout ? "Sí" : "No";
    },
    lockboxCode() {
      return this.details?.lockboxCode || this.$t("No definit");
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    initInputs() {
      this.editedDetails = {
        selfCheckin: this.details?.selfCheckin || false,
        selfCheckout: this.details?.selfCheckout || false,
        lockboxCode: this.details?.lockboxCode || null,
      };
    },
    onEditButtonClicked() {
      this.initInputs();
      this.editMode = true;
    },
    onSaveButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      const action = this.details
        ? "updateAccommodationDetails"
        : "createAccommodationDetails";

      this.$store
        .dispatch(`accommodation/${action}`, {
          uuid: this.details?.uuid,
          accommodation: this.accommodation["@id"],
          ...this.editedDetails,
        })
        .then(() => {
          notifySuccess(
            "Detalls actualitzats",
            "Els detalls de l'allotjament s'ha actualitzat correctament"
          );
          this.editMode = false;
        })
        .catch(() =>
          notifyError(
            "Detalls no actualitzats",
            "Els detalls de l'allotjament no s'ha pogut actualitzar correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
