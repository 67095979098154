<template>
  <b-card no-body id="statistics-guests-card">
    <b-card-header>
      <div>
        <b-card-title>
          {{ cardTitle }}
        </b-card-title>
        <b-card-text class="font-small-2">
          {{ periodText }}
        </b-card-text>
      </div>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item
          v-for="(option, index) in periodOptions"
          :key="`period-option-${index}`"
          @click="selectPeriod(option.value)"
        >
          {{ option.label }}
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-form>
          <b-form-checkbox v-model="limitResults">
            <span class="text-nowrap"> Limitar resultats </span>
          </b-form-checkbox>
        </b-dropdown-form>
      </b-dropdown>
    </b-card-header>

    <b-overlay
      :show="loadingBookings"
      rounded="sm"
      variant="white"
      opacity="1"
      :class="{ 'mb-3': loadingBookings && !nationalities.length }"
    >
      <b-card-body class="pt-0">
        <template v-if="nationalities.length">
          <div
            v-for="(nationality, index) in nationalities"
            :key="`nationality-${index}`"
            class="d-flex justify-content-between py-50"
          >
            <b-media class="d-flex align-items-center" no-body>
              <b-media-aside class="mr-1">
                <b-img
                  rounded
                  :src="nationality.flagImg"
                  alt="nationality flag image"
                  class="flag-image"
                />
              </b-media-aside>
              <b-media-body>
                <h6 class="align-self-center my-auto">
                  {{ nationality.name }}
                </h6>
              </b-media-body>
            </b-media>
            <div class="d-flex align-items-center">
              <span class="font-weight-bold text-body-heading mr-1">
                {{ nationality.percentage }} %
              </span>
              <vue-apex-charts
                type="radialBar"
                height="30"
                width="30"
                :options="getChartDataKey(index, 'options')"
                :series="getChartDataKey(index, 'series')"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-center pt-1 pb-2">Sense resultats</div>
        </template>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BDropdown,
  BDropdownItem,
  BDropdownForm,
  BDropdownDivider,
  BFormCheckbox,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { getCountryName } from "@/utils/methods";
/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";

export default {
  components: {
    BOverlay,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BDropdown,
    BDropdownItem,
    BDropdownForm,
    BDropdownDivider,
    VueApexCharts,
    BFormCheckbox,
  },
  data() {
    return {
      period: "CURRENT_YEAR",
      periodOptions: [
        { label: this.$t("Any actual"), value: "CURRENT_YEAR" },
        { label: this.$t("Any passat"), value: "LAST_YEAR" },
        { label: this.$t("Desde l'inici"), value: "ALL" },
      ],
      limitResults: true,
      chartData: [],
      limitedNationalitiesNumber: 10,
    };
  },
  computed: {
    loadingBookings() {
      return this.$store.getters["accommodation/loadingBookings"];
    },
    bookings() {
      return this.$store.getters["accommodation/bookings"];
    },
    filteredBookings() {
      if (!this.bookings?.length) {
        return [];
      }

      const currentYear = new Date().getFullYear();
      const lastYear = currentYear - 1;

      return this.bookings.filter((booking) => {
        const checkinYear = new Date(booking.checkin).getFullYear();

        switch (this.period) {
          case "CURRENT_YEAR":
            return checkinYear === currentYear;
          case "LAST_YEAR":
            return checkinYear === lastYear;
          case "ALL":
            return true;
        }
        return false;
      });
    },
    nationalities() {
      if (!this.filteredBookings?.length) {
        return [];
      }

      const nationalities = [];

      this.filteredBookings.forEach((booking) => {
        const { client } = booking || {};
        const clientNationality =
          client?.nationality || client?.location?.country || null;
        this.updateNationalityCounter(clientNationality, nationalities);
      });

      this.updateNationalitiesPercentages(
        nationalities,
        this.filteredBookings.length
      );

      nationalities.sort((a, b) => {
        // First, compare by percentage (descending order)
        if (b.percentage !== a.percentage) {
          return b.percentage - a.percentage;
        }
        // If percentages are equal, compare by name (ascending order)
        return a.name.localeCompare(b.name);
      });

      return this.limitResults
        ? nationalities.slice(0, this.limitedNationalitiesNumber)
        : nationalities;
    },
    periodText() {
      const periodTexts = {
        CURRENT_YEAR: this.$t("Any actual"),
        LAST_YEAR: this.$t("Any passat"),
        ALL: this.$t("Desde l'inici"),
      };

      return periodTexts[this.period] || null;
    },
    cardTitle() {
      return `Top ${
        this.limitResults ? this.limitedNationalitiesNumber + " " : ""
      }països de procedència`;
    },
  },
  watch: {
    nationalities() {
      this.updateChartData();
    },
  },
  created() {
    this.updateChartData();
  },
  methods: {
    selectPeriod(period) {
      this.period = period;
    },
    updateNationalityCounter(nationality, nationalities) {
      const foundNationalityIndex = nationalities.findIndex(
        (currentNationality) => {
          return currentNationality.code === nationality;
        }
      );
      if (foundNationalityIndex === -1) {
        this.addNationality(nationality, nationalities);
      } else {
        this.incrementNationality(nationalities, foundNationalityIndex);
      }
    },
    addNationality(nationality, nationalities) {
      const flagImageUrl = nationality
        ? `https://flagsapi.com/${nationality}/flat/48.png`
        : require(`@/assets/images/flags/other.png`);

      nationalities.push({
        flagImg: flagImageUrl,
        code: nationality,
        name:
          getCountryName(nationality, this.$i18n.locale) || this.$t("Altres"),
        count: 1,
        percentage: 0,
        color: $themeColors.primary,
      });
    },
    incrementNationality(nationalities, index) {
      nationalities[index].count += 1;
    },
    updateNationalitiesPercentages(nationalities, itemsNumber) {
      if (!nationalities?.length) {
        return;
      }

      for (let i = 0; i < nationalities.length; i++) {
        const nationalityCount = nationalities[i].count;
        nationalities[i].percentage = Math.round(
          (100 / itemsNumber) * nationalityCount
        );
      }
    },
    updateChartData() {
      this.chartData = [];
      this.nationalities.forEach((nationality) => {
        const chart = {
          series: [0],
          options: {
            grid: {
              show: false,
              padding: {
                left: -15,
                right: -15,
                top: -12,
                bottom: -15,
              },
            },
            colors: [$themeColors.primary],
            plotOptions: {
              radialBar: {
                hollow: {
                  size: "22%",
                },
                track: {
                  background: $trackBgColor,
                },
                dataLabels: {
                  showOn: "always",
                  name: {
                    show: false,
                  },
                  value: {
                    show: false,
                  },
                },
              },
            },
            stroke: {
              lineCap: "round",
            },
          },
        };
        const chartClone = JSON.parse(JSON.stringify(chart));
        chartClone.options.colors[0] = nationality.color;
        chartClone.series[0] = nationality.percentage;
        this.chartData.push(chartClone);
      });
    },
    getChartDataKey(index, key) {
      const chartDataItem = this.chartData[index];
      return chartDataItem ? chartDataItem[key] : null;
    },
  },
};
</script>

<style scoped lang="scss">
#statistics-guests-card {
  .flag-image {
    height: 30px;
    width: 40px;
    object-fit: cover;
  }
}
</style>
