var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"booking-policy-form"}},[_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('h6',[_vm._v("Dates")])]),_c('b-col',{staticClass:"mb-1 mb-sm-0",attrs:{"cols":"12","sm":"6"}},[_c('b-form-datepicker',{attrs:{"placeholder":"Inici","locale":_vm.locale,"start-weekday":1,"show-decade-nav":false,"date-format-options":{
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        },"hide-header":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-datepicker',{attrs:{"placeholder":"Fi","min":_vm.startDate,"locale":_vm.locale,"start-weekday":1,"show-decade-nav":false,"date-format-options":{
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        },"hide-header":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('h6',[_vm._v("Política de reserva")])]),_c('b-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"options":_vm.bookingPoliciesOptions,"reduce":function (option) { return option.value; }},model:{value:(_vm.bookingPolicy),callback:function ($$v) {_vm.bookingPolicy=$$v},expression:"bookingPolicy"}})],1)],1),_c('b-row',{staticClass:"mb-1 d-flex justify-content-between justify-content-sm-end"},[_c('b-col',{attrs:{"cols":"6","sm":"auto"}},[_c('b-button',{attrs:{"block":"","variant":"light"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" Cancel·la ")])],1),_c('b-col',{attrs:{"cols":"6","sm":"auto"}},[_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Guarda ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }