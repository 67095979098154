<template>
  <div id="review-form">
    <!-- BOOKING -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Reserva <small>(opcional)</small></h6>
      </b-col>
      <b-col cols="12">
        <v-select
          v-model="booking"
          :options="bookingOptions"
          :reduce="(option) => option.value"
          placeholder="Reserva on s'ha fet la ressenya"
        />
      </b-col>
    </b-row>

    <!-- SOURCE -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Canal</h6>
      </b-col>
      <b-col cols="12">
        <v-select
          v-model="source"
          :options="sourceOptions"
          :reduce="(option) => option.value"
          placeholder="Canal on s'ha fet la ressenya"
        />
      </b-col>
    </b-row>

    <!-- AUTHOR -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Autor</h6>
      </b-col>
      <b-col cols="12">
        <b-form-input v-model="author" placeholder="Autor de la ressenya" />
      </b-col>
    </b-row>

    <!-- POST DATE -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Data</h6>
      </b-col>
      <b-col cols="12">
        <b-form-datepicker
          v-model="postDate"
          placeholder="Data de la ressenya"
          :locale="locale"
          :start-weekday="1"
          :show-decade-nav="false"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          hide-header
        />
      </b-col>
    </b-row>

    <!-- RATINGS -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6>Valoracions</h6>
      </b-col>
      <b-col cols="12">
        <table class="w-100">
          <!-- Facilities -->
          <tr>
            <td>
              <label for="rate-facilities"> Instal·lacions </label>
            </td>
            <td>
              <b-form-rating
                id="rate-facilities"
                v-model="rateFacilities"
                no-border
                variant="primary"
                inline
                size="lg"
              />
            </td>
          </tr>
          <!-- Cleaning -->
          <tr>
            <td>
              <label for="rate-cleaning"> Neteja </label>
            </td>
            <td>
              <b-form-rating
                id="rate-cleaning"
                v-model="rateCleaning"
                no-border
                variant="primary"
                inline
                size="lg"
              />
            </td>
          </tr>
          <!-- Check-in -->
          <tr>
            <td>
              <label for="rate-checkin"> Arribada </label>
            </td>
            <td>
              <b-form-rating
                id="rate-checkin"
                v-model="rateCheckin"
                no-border
                variant="primary"
                inline
                size="lg"
              />
            </td>
          </tr>
          <!-- Location -->
          <tr>
            <td>
              <label for="rate-location"> Ubicació </label>
            </td>
            <td>
              <b-form-rating
                id="rate-location"
                v-model="rateLocation"
                no-border
                variant="primary"
                inline
                size="lg"
              />
            </td>
          </tr>
          <!-- Communication -->
          <tr>
            <td>
              <label for="rate-communication"> Comunicació </label>
            </td>
            <td>
              <b-form-rating
                id="rate-communication"
                v-model="rateCommunication"
                no-border
                variant="primary"
                inline
                size="lg"
              />
            </td>
          </tr>
          <!-- Accuracy -->
          <tr>
            <td>
              <label for="rate-accuracy"> Veracitat </label>
            </td>
            <td>
              <b-form-rating
                id="rate-accuracy"
                v-model="rateAccuracy"
                no-border
                variant="primary"
                inline
                size="lg"
              />
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>

    <!-- LOCALE -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Idioma</h6>
      </b-col>
      <b-col cols="12">
        <v-select
          v-model="language"
          :options="languageOptions"
          :reduce="(option) => option.value"
          placeholder="Idioma en el que està escrita la ressenya"
        />
      </b-col>
    </b-row>

    <!-- COMMENT -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Comentari públic</h6>
      </b-col>
      <b-col cols="12">
        <quill-editor v-model="comment" :options="editorOptions" />
      </b-col>
    </b-row>

    <!-- PRIVATE COMMENT -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Comentari privat</h6>
      </b-col>
      <b-col cols="12">
        <quill-editor v-model="privateComment" :options="editorOptions" />
      </b-col>
    </b-row>

    <!-- REPLY AUTHOR -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Autor resposta</h6>
      </b-col>
      <b-col cols="12">
        <v-select
          v-model="replyAuthor"
          :options="replyAuthorOptions"
          :reduce="(option) => option.value"
          placeholder="Autor de la resposta"
        />
      </b-col>
    </b-row>

    <!-- REPLY -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Resposta pública</h6>
      </b-col>
      <b-col cols="12">
        <quill-editor v-model="reply" :options="editorOptions" />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormDatepicker,
  BFormRating,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  otaOptions,
  languageOptions,
  teamMemberOptions,
} from "@/utils/select-options";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";
import {
  formatDateObjectToDatabaseDate,
  formatDateStringToDatabaseDate,
} from "@/utils/formatters";

export default {
  name: "ReviewForm",
  components: {
    BRow,
    BCol,
    vSelect,
    BFormInput,
    BButton,
    quillEditor,
    BFormDatepicker,
    BFormRating,
  },
  props: {
    review: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      booking: null,
      source: null,
      author: null,
      reply: null,
      replyAuthor: null,
      postDate: null,
      rateFacilities: null,
      rateCleaning: null,
      rateCheckin: null,
      rateLocation: null,
      rateCommunication: null,
      rateAccuracy: null,
      comment: null,
      privateComment: null,
      language: null,
      editorOptions: {
        modules: {
          toolbar: false,
        },
        placeholder: "Escriu el comentari...",
      },
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    bookings() {
      return this.$store.getters["accommodation/bookings"];
    },
    bookingOptions() {
      if (!this.bookings.length) return [];
      return this.bookings
        .filter((booking) => !booking.cancelled)
        .map((booking) => ({
          label: `${booking.localizator} - ${
            booking.client?.fullName ?? "Sense client"
          }`,
          value: booking,
        }));
    },
    sourceOptions() {
      return otaOptions;
    },
    replyAuthorOptions() {
      return teamMemberOptions;
    },
    languageOptions() {
      return languageOptions;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {
    booking(booking) {
      this.source = booking?.source || null;
      this.author = booking?.client?.firstName || null;
    },
    author(author) {
      if (author === "") this.author = null;
    },
    comment(comment) {
      if (comment === "") this.comment = null;
    },
    privateComment(privateComment) {
      if (privateComment === "") this.privateComment = null;
    },
    reply(reply) {
      if (reply === "") this.reply = null;
    },
  },
  created() {
    if (this.review) this.init();
  },
  methods: {
    searchBooking(bookings, search) {
      const searchText = search.toLowerCase();
      return bookings.filter(
        (booking) =>
          booking.localizator.toLowerCase().includes(searchText) ||
          booking.client.fullName.toLowerCase().includes(searchText)
      );
    },
    init() {
      this.booking = this.review.booking
        ? this.bookings.find(
            (booking) => booking.uuid === this.review.booking.uuid
          )
        : null;
      this.source = this.review.source;
      this.author = this.review.author;
      this.postDate = this.review.postedAt;
      this.rateFacilities = this.review.rateFacilities;
      this.rateCleaning = this.review.rateCleaning;
      this.rateCheckin = this.review.rateCheckin;
      this.rateLocation = this.review.rateLocation;
      this.rateCommunication = this.review.rateCommunication;
      this.rateAccuracy = this.review.rateAccuracy;
      this.language = this.review.language;
      this.comment = this.review.comment;
      this.privateComment = this.review.privateComment;
      this.reply = this.review.reply;
      this.replyAuthor = this.review.replyAuthor;
    },
    cancel() {
      this.$emit("cancel", this.review);
    },
    save() {
      if (this.review) this.editReview();
      else this.createReview();
    },
    createReview() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("accommodation/addAccommodationReview", {
          accommodation: this.accommodation["@id"],
          booking: this.booking ? this.booking["@id"] : null,
          source: this.source,
          language: this.language,
          comment: this.comment,
          privateComment: this.privateComment,
          author: this.author,
          reply: this.reply,
          replyAuthor: this.reply ? this.replyAuthor : null,
          repliedAt: this.reply
            ? formatDateObjectToDatabaseDate(new Date())
            : null,
          rateFacilities: this.rateFacilities,
          rateCleaning: this.rateCleaning,
          rateCheckin: this.rateCheckin,
          rateLocation: this.rateLocation,
          rateCommunication: this.rateCommunication,
          rateAccuracy: this.rateAccuracy,
          approved: true,
          reviewedAt: formatDateObjectToDatabaseDate(new Date()),
          postedAt: formatDateStringToDatabaseDate(this.postDate),
        })
        .then(() => this.$emit("review-added"))
        .catch(() => this.$emit("add-review-error"))
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    editReview() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("accommodation/updateAccommodationReview", {
          uuid: this.review.uuid,
          booking: this.booking ? this.booking["@id"] : null,
          source: this.source,
          language: this.language,
          comment: this.comment,
          privateComment: this.privateComment,
          author: this.author,
          reply: this.reply,
          replyAuthor: this.replyAuthor,
          repliedAt: this.reply
            ? formatDateObjectToDatabaseDate(new Date())
            : null,
          rateFacilities: this.rateFacilities,
          rateCleaning: this.rateCleaning,
          rateCheckin: this.rateCheckin,
          rateLocation: this.rateLocation,
          rateCommunication: this.rateCommunication,
          rateAccuracy: this.rateAccuracy,
          reviewedAt: formatDateObjectToDatabaseDate(new Date()),
          postedAt: formatDateStringToDatabaseDate(this.postDate),
        })
        .then((response) => this.$emit("review-edited", response.data))
        .catch((response) => this.$emit("edit-review-error", response.data))
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
#review-form {
  .quill-editor {
    .ql-editor {
      p {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
