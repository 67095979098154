<template>
  <b-overlay :show="loadingReviews" rounded="sm" variant="white">
    <!-- SUMMARY CARD -->
    <b-card>
      <b-row class="mb-2 mt-1">
        <b-col cols="12">
          <h4>
            <span v-if="accommodation.averageRating" class="mr-1">
              {{ accommodation.averageRating }}
              <span class="font-normal">/ {{ maxRating }}</span>
            </span>
            <span>
              ({{ approvedReviews.length }}
              {{
                approvedReviews.length == 1
                  ? "ressenya aprovada"
                  : "ressenyes aprovades"
              }})
            </span>
          </h4>
        </b-col>
      </b-row>
      <b-row v-if="rating">
        <!-- FACILITIES -->
        <b-col cols="12" md="2" class="mb-1"> Instal·lacions </b-col>
        <b-col cols="12" md="4" class="mb-1">
          <b-progress :max="100" variant="primary" show-value>
            <b-progress-bar :value="getRatingPercent(rating.facilities)">
              <strong>{{ getRatingPercent(rating.facilities) }}%</strong>
            </b-progress-bar>
          </b-progress>
        </b-col>
        <!-- LOCATION -->
        <b-col cols="12" md="2" class="mb-1"> Ubicació </b-col>
        <b-col cols="12" md="4" class="mb-1">
          <b-progress :max="100" variant="primary" show-value>
            <b-progress-bar :value="getRatingPercent(rating.location)">
              <strong>{{ getRatingPercent(rating.location) }}%</strong>
            </b-progress-bar>
          </b-progress>
        </b-col>
        <!-- CLEANING -->
        <b-col cols="12" md="2" class="mb-1"> Neteja </b-col>
        <b-col cols="12" md="4" class="mb-1">
          <b-progress :max="100" variant="primary" show-value>
            <b-progress-bar :value="getRatingPercent(rating.cleaning)">
              <strong>{{ getRatingPercent(rating.cleaning) }}%</strong>
            </b-progress-bar>
          </b-progress>
        </b-col>
        <!-- COMMUNICATION -->
        <b-col cols="12" md="2" class="mb-1"> Comunicació </b-col>
        <b-col cols="12" md="4" class="mb-1">
          <b-progress :max="100" variant="primary" show-value>
            <b-progress-bar :value="getRatingPercent(rating.communication)">
              <strong>{{ getRatingPercent(rating.communication) }}%</strong>
            </b-progress-bar>
          </b-progress>
        </b-col>
        <!-- CHECKIN -->
        <b-col cols="12" md="2" class="mb-1"> Arribada </b-col>
        <b-col cols="12" md="4" class="mb-1">
          <b-progress :max="100" variant="primary" show-value>
            <b-progress-bar :value="getRatingPercent(rating.checkin)">
              <strong>{{ getRatingPercent(rating.checkin) }}%</strong>
            </b-progress-bar>
          </b-progress>
        </b-col>
        <!-- ACCURACY -->
        <b-col cols="12" md="2" class="mb-1"> Veracitat </b-col>
        <b-col cols="12" md="4" class="mb-1">
          <b-progress :max="100" variant="primary" show-value>
            <b-progress-bar :value="getRatingPercent(rating.accuracy)">
              <strong>{{ getRatingPercent(rating.accuracy) }}%</strong>
            </b-progress-bar>
          </b-progress>
        </b-col>
      </b-row>
    </b-card>

    <!-- REVIEWS LIST -->
    <b-card>
      <!-- FILTER & ACTIONS BAR -->
      <b-row class="mb-2 d-flex justify-content-between">
        <b-col cols="12" md="auto" class="mb-1 mb-md-0">
          <b-button v-b-modal.add-review-modal variant="primary" block>
            Nova ressenya
          </b-button>
        </b-col>
        <b-col cols="12" md="auto">
          <b-row class="d-flex justify-content-between">
            <b-col>
              <v-select
                v-model="reviewFilter"
                class="review-filter-select"
                :options="reviewsFiltersOptions"
                placeholder="Totes"
                :reduce="(option) => option.value"
              />
            </b-col>
            <b-col>
              <b-form-input v-model="searchText" placeholder="Cerca" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- LIST -->
      <b-row>
        <b-col
          v-for="review in filteredReviews"
          :key="`review-card-${review.uuid}`"
          cols="12"
        >
          <review-card :review="review" />
        </b-col>
      </b-row>
    </b-card>

    <!-- ADD REVIEW MODAL -->
    <b-modal
      :id="'add-review-modal'"
      title="Afegeix una ressenya"
      scrollable
      hide-footer
    >
      <review-form
        @cancel="onAddReviewCancel"
        @review-added="onReviewAdded"
        @add-review-error="onAddReviewError"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BProgress,
  BProgressBar,
} from "bootstrap-vue";
import ReviewCard from "@/views/accommodations/accommodation/components/ReviewCard.vue";
import ReviewForm from "@/views/accommodations/accommodation/components/ReviewForm.vue";
import { notifyError, notifySuccess } from "@/utils/methods";
import vSelect from "vue-select";

export default {
  name: "ReviewsCard",
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BProgress,
    BProgressBar,
    ReviewCard,
    ReviewForm,
    vSelect,
  },
  data() {
    return {
      maxRating: 5,
      reviewFilter: null,
      searchText: null,
      addReviewPopupShown: false,
      askReviewPopupShown: false,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    bookings() {
      return this.$store.getters["accommodation/bookings"];
    },
    loadingReviews() {
      return this.$store.getters["accommodation/loadingReviews"];
    },
    reviews() {
      return this.$store.getters["accommodation/reviews"];
    },
    bookingsWithoutReview() {
      if (this.bookings.length === 0) return [];
      return this.bookings.filter((booking) => {
        const diff = Math.floor(
          (Date.parse(new Date()) - Date.parse(booking.checkout)) / 86400000
        );
        // Return bookings without review and no older than 180 days (6 months)
        return (
          !Object.prototype.hasOwnProperty.call(booking, "review") &&
          diff < 180 &&
          diff > 0
        );
      });
    },
    rating() {
      return this.accommodation?.rating || null;
    },
    approvedReviews() {
      return this.reviews.filter((review) => review.approved);
    },
    filteredReviews() {
      function matchesSearchText(review, searchText) {
        if (!searchText) return true;
        return (
          review.author?.toLowerCase().includes(searchText.toLowerCase()) ||
          review.source?.toLowerCase().includes(searchText.toLowerCase()) ||
          review.booking?.localizator
            ?.toLowerCase()
            .includes(searchText.toLowerCase())
        );
      }

      const text = this.searchText;

      // Filtering the reviews
      return this.reviews.filter((review) => {
        if (!this.reviewFilter && !text) return true;

        if (!this.reviewFilter && text) {
          return matchesSearchText(review, text);
        }

        switch (this.reviewFilter) {
          case "WITH_REPLY":
            return (
              Object.prototype.hasOwnProperty.call(review, "reply") &&
              review.reply !== null &&
              review.reply !== "" &&
              review.reply !== undefined &&
              (text ? matchesSearchText(review, text) : true)
            );
          case "WITHOUT_REPLY":
            return (
              (!Object.prototype.hasOwnProperty.call(review, "reply") ||
                review.reply === null ||
                review.reply === "" ||
                review.reply === undefined) &&
              (text ? matchesSearchText(review, text) : true)
            );
          case "APPROVED":
            return (
              review.approved && (text ? matchesSearchText(review, text) : true)
            );
          case "REJECTED":
            return (
              !review.approved &&
              (text ? matchesSearchText(review, text) : true)
            );
          case "ALL":
          default:
            return (
              Object.prototype.hasOwnProperty.call(review, "postedAt") &&
              (text ? matchesSearchText(review, text) : true)
            );
        }
      });
    },
    reviewsFiltersOptions() {
      return [
        {
          label: "Totes",
          value: "ALL",
        },
        {
          label: "Amb resposta",
          value: "WITH_REPLY",
        },
        {
          label: "Sense resposta",
          value: "WITHOUT_REPLY",
        },
        {
          label: "Aprovades",
          value: "APPROVED",
        },
        {
          label: "Rebutjades",
          value: "REJECTED",
        },
      ];
    },
  },
  methods: {
    fetchReviews() {
      const { accommodationUuid } = this.$route.params;

      this.$store
        .dispatch("accommodation/fetchAccommodationReviews", accommodationUuid)
        .catch(() => {
          // TODO: log error to Sentry
        });
    },
    onAddReviewCancel() {
      this.$bvModal.hide("add-review-modal");
    },
    onReviewAdded() {
      this.fetchReviews();
      this.$bvModal.hide("add-review-modal");
      notifySuccess(
        "Ressenya afegida",
        "La ressenya ha estat afegida correctament"
      );
    },
    onAddReviewError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar afegir la ressenya"
      );
    },
    getRatingPercent(rating) {
      if (!rating) return 0;
      return (100 / this.maxRating) * rating;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.review-filter-select {
  min-width: 200px;
}
</style>
