<template>
  <b-card id="location-card" title="Ubicació">
    <!-- ADDRESS -->
    <b-row class="mb-3">
      <!-- TITLE + LINK -->
      <b-col cols="12" class="mb-2">
        <h5>
          <feather-icon icon="MapIcon" /> Adreça
          <b-link
            v-if="!editMode && location"
            v-clipboard:copy="fullAddress"
            v-clipboard:success="onFullAddressCopied"
            v-clipboard:error="onFullAddressCopyError"
            class="ml-1"
          >
            <small>copia adreça</small>
          </b-link>
        </h5>
      </b-col>

      <b-col cols="12">
        <b-row>
          <!-- COUNTRY -->
          <b-col :cols="editMode ? 12 : 6" sm="4" xl="3" class="mb-1">
            <h6>País</h6>
            <v-select
              v-if="editMode"
              v-model="editedLocation.country"
              :options="countryOptions"
              :reduce="(option) => option.value"
            />
            <b-card-text v-else>
              {{ country }}
            </b-card-text>
          </b-col>

          <!-- REGION -->
          <b-col :cols="editMode ? 12 : 6" sm="4" xl="3" class="mb-1">
            <h6>Regió</h6>
            <v-select
              v-if="editMode"
              v-model="editedLocation.region"
              :options="regionOptions"
              :reduce="(option) => option.value"
            />
            <b-card-text v-else>
              {{ region }}
            </b-card-text>
          </b-col>

          <!-- CITY -->
          <b-col :cols="editMode ? 12 : 6" sm="4" xl="3" class="mb-1">
            <h6>Ciutat</h6>
            <v-select
              v-if="editMode"
              v-model="editedLocation.city"
              :options="cityOptions"
              :reduce="(option) => option.value"
            />
            <b-card-text v-else>
              {{ city }}
            </b-card-text>
          </b-col>

          <!-- ZIP -->
          <b-col :cols="editMode ? 12 : 6" sm="4" xl="3" class="mb-1">
            <h6>Codi Postal</h6>
            <b-form-input v-if="editMode" v-model="editedLocation.zip" />
            <b-card-text v-else>
              {{ zip }}
            </b-card-text>
          </b-col>

          <!-- ADDRESS TYPE -->
          <b-col :cols="editMode ? 12 : 6" sm="4" xl="3" class="mb-1">
            <h6>Tipus via</h6>
            <v-select
              v-if="editMode"
              v-model="editedLocation.addressType"
              :options="addressTypeOptions"
              :reduce="(option) => option.value"
            />
            <b-card-text v-else>
              {{ addressType }}
            </b-card-text>
          </b-col>

          <template v-if="editedLocation.addressType === 'DISSEMINATED'">
            <!-- ADDRESS POLYGON -->
            <b-col cols="6" sm="4" xl="3" class="mb-1">
              <h6>Polígon</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedLocation.addressPolygon"
              />
              <b-card-text v-else>
                {{ addressPolygon }}
              </b-card-text>
            </b-col>
            <!-- ADDRESS PLOT -->
            <b-col cols="6" sm="4" xl="3" class="mb-1">
              <h6>Parcel·la</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedLocation.addressPlot"
              />
              <b-card-text v-else>
                {{ addressPlot }}
              </b-card-text>
            </b-col>
          </template>

          <template v-else>
            <!-- ADDRESS NAME -->
            <b-col :cols="editMode ? 12 : 6" sm="4" xl="3" class="mb-1">
              <h6>Nom via</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedLocation.addressName"
              />
              <b-card-text v-else>
                {{ addressName }}
              </b-card-text>
            </b-col>
            <!-- ADDRESS NUMBER -->
            <b-col cols="6" sm="4" xl="3" class="mb-1">
              <h6>Número</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedLocation.addressNumber"
              />
              <b-card-text v-else>
                {{ addressNumber }}
              </b-card-text>
            </b-col>
            <!-- ADDRESS BLOCK -->
            <b-col cols="6" sm="4" xl="3" class="mb-1">
              <h6>Bloc</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedLocation.addressBlock"
              />
              <b-card-text v-else>
                {{ addressBlock }}
              </b-card-text>
            </b-col>
            <!-- ADDRESS PORTAL -->
            <b-col cols="6" sm="4" xl="3" class="mb-1">
              <h6>Portal</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedLocation.addressPortal"
              />
              <b-card-text v-else>
                {{ addressPortal }}
              </b-card-text>
            </b-col>
            <!-- ADDRESS STAIRS -->
            <b-col cols="6" sm="4" xl="3" class="mb-1">
              <h6>Escala</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedLocation.addressStairs"
              />
              <b-card-text v-else>
                {{ addressStairs }}
              </b-card-text>
            </b-col>
            <!-- ADDRESS FLOOR -->
            <b-col cols="6" sm="4" xl="3" class="mb-1">
              <h6>Pis</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedLocation.addressFloor"
              />
              <b-card-text v-else>
                {{ addressFloor }}
              </b-card-text>
            </b-col>
            <!-- ADDRESS DOOR -->
            <b-col cols="6" sm="4" xl="3" class="mb-1">
              <h6>Porta</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedLocation.addressDoor"
              />
              <b-card-text v-else>
                {{ addressDoor }}
              </b-card-text>
            </b-col>
          </template>
        </b-row>
      </b-col>
    </b-row>

    <!-- GPS -->
    <b-row class="mb-3">
      <b-col cols="12" class="mb-2">
        <h5>
          <feather-icon icon="NavigationIcon" /> GPS
          <b-link
            v-if="!editMode && latitude && longitude"
            v-b-modal.map-modal
            class="ml-1"
          >
            <small>veure mapa</small>
          </b-link>
          <b-link
            v-if="!editMode && latitude && longitude"
            class="ml-1"
            @click="openGoogleMapsLink(latitude, longitude)"
          >
            <small>google maps</small>
          </b-link>
        </h5>
      </b-col>
      <b-col cols="12">
        <b-row :class="{ 'mb-1': editMode }">
          <!-- LATITUDE -->
          <b-col :cols="editMode ? 6 : 4" sm="4" xl="3" class="mb-1">
            <h6>Latitud</h6>
            <b-form-input v-if="editMode" v-model="editedLocation.latitude" />
            <b-card-text v-else>
              {{ latitudeText }}
            </b-card-text>
          </b-col>
          <!-- LONGITUDE -->
          <b-col :cols="editMode ? 6 : 4" sm="4" xl="3" class="mb-1">
            <h6>Longitud</h6>
            <b-form-input v-if="editMode" v-model="editedLocation.longitude" />
            <b-card-text v-else>
              {{ longitudeText }}
            </b-card-text>
          </b-col>

          <!-- AREA -->
          <b-col :cols="editMode ? 12 : 4" sm="4" xl="3" class="mb-1">
            <h6>Àrea</h6>
            <v-select
              v-if="editMode"
              v-model="editedLocation.area"
              :options="areaOptions"
              :reduce="(option) => option.value"
            />
            <b-card-text v-else>
              {{ area }}
            </b-card-text>
          </b-col>
        </b-row>
        <!-- EDIT LOCATION MAP -->
        <b-row v-if="editMode">
          <b-col cols="12">
            <div class="map-container">
              <l-map
                ref="editLocationMap"
                :zoom="zoom"
                :center="editedMapCenter"
                :options="mapOptions"
              >
                <l-control-layers position="topright" />
                <l-tile-layer
                  v-for="tileProvider in tileProviders"
                  :key="tileProvider.name"
                  :name="tileProvider.name"
                  :visible="tileProvider.visible"
                  :url="tileProvider.url"
                  layer-type="base"
                />
                <l-marker
                  :visible="editedMapMarker.visible"
                  :draggable="editedMapMarker.draggable"
                  :lat-lng.sync="editedMapMarker.position"
                />
              </l-map>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <!-- MAP MODAL -->
      <b-modal
        id="map-modal"
        title="Ubicació de l'allotjament"
        hide-footer
        centered
        size="lg"
        body-class="p-0"
        @shown="mapModalShown"
      >
        <div class="map-container">
          <l-map
            ref="locationMap"
            :zoom="zoom"
            :center="mapCenter"
            :options="mapOptions"
          >
            <l-control-layers position="topright" />
            <l-tile-layer
              v-for="tileProvider in tileProviders"
              :key="tileProvider.name"
              :name="tileProvider.name"
              :visible="tileProvider.visible"
              :url="tileProvider.url"
              layer-type="base"
            />
            <l-marker :lat-lng="markerPosition" />
          </l-map>
        </div>
      </b-modal>
    </b-row>

    <!-- DISTANCES -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-2">
        <h5><feather-icon icon="Maximize2Icon" /> Distàncies</h5>
      </b-col>
      <b-col cols="12">
        <b-row>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Aeroport</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToAirport" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToAirport) }}
            </b-card-text>
          </b-col>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Port</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToPort" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToPort) }}
            </b-card-text>
          </b-col>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Tren</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToTrain" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToTrain) }}
            </b-card-text>
          </b-col>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Taxi</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToTaxi" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToTaxi) }}
            </b-card-text>
          </b-col>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Bus</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToBus" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToBus) }}
            </b-card-text>
          </b-col>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Centre</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToCityCenter" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToCityCenter) }}
            </b-card-text>
          </b-col>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Farmàcia</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToPharmacy" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToPharmacy) }}
            </b-card-text>
          </b-col>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Hospital</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToHospital" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToHospital) }}
            </b-card-text>
          </b-col>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Mercat</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToMarket" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToMarket) }}
            </b-card-text>
          </b-col>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Platja d'arena</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToSandBeach" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToSandBeach) }}
            </b-card-text>
          </b-col>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Platja de roca</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToRockBeach" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToRockBeach) }}
            </b-card-text>
          </b-col>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Golf</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToGolf" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToGolf) }}
            </b-card-text>
          </b-col>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Parc</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToPark" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToPark) }}
            </b-card-text>
          </b-col>
          <b-col cols="6" sm="2" class="mb-1">
            <h6>Supermercat</h6>
            <b-input-group v-if="editMode" append="m" class="input-group-merge">
              <b-form-input v-model="editedLocation.distanceToSupermarket" />
            </b-input-group>
            <b-card-text v-else>
              {{ getDistanceTo(location.distanceToSupermarket) }}
            </b-card-text>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="d-flex justify-content-between justify-content-sm-end">
      <b-col v-if="editMode" cols="6" sm="auto">
        <b-button block variant="light" @click="editMode = false">
          Cancel·la
        </b-button>
      </b-col>
      <b-col v-if="editMode" cols="6" sm="auto">
        <b-button block variant="primary" @click="onSaveButtonClicked">
          Guarda
        </b-button>
      </b-col>
      <b-col v-if="!editMode" cols="12" sm="auto">
        <b-button block variant="primary" @click="onEditButtonClicked">
          Edita
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BModal,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import {
  getCountryName,
  getAddressTypeName,
  getFullAddress,
  getAreaName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import { LMap, LTileLayer, LMarker, LControlLayers } from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import {
  countryOptions,
  regionOptions,
  addressTypeOptions,
  areaOptions,
} from "@/utils/select-options";
import vSelect from "vue-select";

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  // eslint-disable-next-line global-require
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  // eslint-disable-next-line global-require
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  name: "LocationCard",
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BFormInput,
    BInputGroup,
    BLink,
    BModal,
    LMap,
    LTileLayer,
    LMarker,
    LControlLayers,
    vSelect,
  },
  data() {
    return {
      editMode: false,
      tileProviders: [
        {
          name: "Mapa",
          visible: true,
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
        {
          name: "Satèl·lit",
          visible: false,
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        },
      ],
      mapOptions: { scrollWheelZoom: false },
      zoom: 12,
      editedLocation: {
        latitude: null,
        longitude: null,
        country: null,
        region: null,
        city: null,
        zip: null,
        addressType: null,
        addressName: null,
        addressNumber: null,
        addressBlock: null,
        addressPortal: null,
        addressStairs: null,
        addressFloor: null,
        addressDoor: null,
        addressPolygon: null,
        addressPlot: null,
        area: null,
        distanceToAirport: null,
        distanceToTrain: null,
        distanceToPort: null,
        distanceToTaxi: null,
        distanceToBus: null,
        distanceToCityCenter: null,
        distanceToPharmacy: null,
        distanceToHospital: null,
        distanceToMarket: null,
        distanceToSandBeach: null,
        distanceToRockBeach: null,
        distanceToGolf: null,
        distanceToPark: null,
        distanceToSupermarket: null,
      },
      editedMapMarker: null,
      editedMapCenter: null,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    cities() {
      return this.$store.getters["places/cities"];
    },
    cityOptions() {
      return this.cities.map((city) => ({
        label: city.name,
        value: city.name,
      }));
    },
    location() {
      return this.accommodation?.location || null;
    },
    country() {
      return (
        getCountryName(this.location?.country, this.$i18n.locale) ||
        "No definit"
      );
    },
    countryOptions() {
      return countryOptions;
    },
    region() {
      return this.location?.region || "No definit";
    },
    regionOptions() {
      return regionOptions;
    },
    city() {
      return this.location?.city || "No definit";
    },
    zip() {
      return this.location?.zip || "No definit";
    },
    areaOptions() {
      return areaOptions;
    },
    addressType() {
      return (
        getAddressTypeName(this.location?.addressType, this.$i18n.locale) ||
        "No definit"
      );
    },
    addressTypeOptions() {
      return addressTypeOptions;
    },
    addressName() {
      return this.location?.addressName || "No definit";
    },
    addressNumber() {
      return this.location?.addressNumber || "No definit";
    },
    addressBlock() {
      return this.location?.addressBlock || "No definit";
    },
    addressPortal() {
      return this.location?.addressPortal || "No definit";
    },
    addressStairs() {
      return this.location?.addressStairs || "No definit";
    },
    addressFloor() {
      return this.location?.addressFloor || "No definit";
    },
    addressDoor() {
      return this.location?.addressDoor || "No definit";
    },
    addressPolygon() {
      return this.location?.addressPolygon || "No definit";
    },
    addressPlot() {
      return this.location?.addressPlot || "No definit";
    },
    fullAddress() {
      return getFullAddress(this.location, this.$i18n.locale) || "No definit";
    },
    area() {
      return (
        getAreaName(this.location?.area, this.$i18n.locale) || "No definit"
      );
    },
    mapCenter() {
      return [this.location?.latitude, this.location?.longitude];
    },
    markerPosition() {
      return [...this.mapCenter];
    },
    latitude() {
      return this.location?.latitude;
    },
    latitudeText() {
      return this.latitude || "No definit";
    },
    longitude() {
      return this.location?.longitude;
    },
    longitudeText() {
      return this.longitude || "No definit";
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    "editedLocation.addressType": function (type) {
      if (type === "DISSEMINATED") {
        this.editedLocation.addressName = null;
        this.editedLocation.addressNumber = null;
        this.editedLocation.addressBlock = null;
        this.editedLocation.addressPortal = null;
        this.editedLocation.addressStairs = null;
        this.editedLocation.addressFloor = null;
        this.editedLocation.addressDoor = null;
      } else {
        this.editedLocation.addressPolygon = null;
        this.editedLocation.addressPlot = null;
      }
    },
    // eslint-disable-next-line func-names
    "editedMapMarker.position": function (position) {
      if (position) {
        this.editedLocation.latitude = position.lat;
        this.editedLocation.longitude = position.lng;
      }
    },
  },
  created() {
    this.initInputs();
    if (this.cities.length === 0) this.fetchPlaces();
  },
  methods: {
    mapModalShown() {
      setTimeout(() => {
        // mapObject is a property that is part of leaflet
        this.$refs.locationMap.mapObject.invalidateSize();
      }, 100);
    },
    editMapMarker(latitude, longitude) {
      this.editedMapMarker = {
        position: { lat: latitude, lng: longitude },
        visible: true,
        draggable: true,
      };
    },
    initInputs() {
      if (this.location) {
        this.editedLocation = {
          country: this.location.country,
          region: this.location.region,
          city: this.location.city,
          zip: this.location.zip,
          addressType: this.location.addressType,
          addressName: this.location.addressName,
          addressNumber: this.location.addressNumber,
          addressBlock: this.location.addressBlock,
          addressPortal: this.location.addressPortal,
          addressStairs: this.location.addressStairs,
          addressFloor: this.location.addressFloor,
          addressDoor: this.location.addressDoor,
          addressPolygon: this.location.addressPolygon,
          addressPlot: this.location.addressPlot,
          latitude: this.location.latitude,
          longitude: this.location.longitude,
          area: this.location.area,
          distanceToAirport: this.location.distanceToAirport,
          distanceToPort: this.location.distanceToPort,
          distanceToTrain: this.location.distanceToTrain,
          distanceToTaxi: this.location.distanceToTaxi,
          distanceToBus: this.location.distanceToBus,
          distanceToCityCenter: this.location.distanceToCityCenter,
          distanceToPharmacy: this.location.distanceToPharmacy,
          distanceToHospital: this.location.distanceToHospital,
          distanceToMarket: this.location.distanceToMarket,
          distanceToSandBeach: this.location.distanceToSandBeach,
          distanceToRockBeach: this.location.distanceToRockBeach,
          distanceToGolf: this.location.distanceToGolf,
          distanceToPark: this.location.distanceToPark,
          distanceToSupermarket: this.location.distanceToSupermarket,
        };
      }
      this.editMapMarker(
        this.location?.latitude
          ? parseFloat(this.location.latitude)
          : 39.77288941462659,
        this.location?.longitude
          ? parseFloat(this.location.longitude)
          : 3.0153393745422363
      );
      this.editedMapCenter = [
        this.location?.latitude
          ? parseFloat(this.location.latitude)
          : 39.77288941462659,
        this.location?.longitude
          ? parseFloat(this.location.longitude)
          : 3.0153393745422363,
      ];
    },
    getDistanceTo(distance) {
      if (!distance) return "No definit";
      if (distance > 999) {
        return `${Math.round((distance / 1000) * 10) / 10} km`;
      }
      return `${distance} m`;
    },
    onEditButtonClicked() {
      this.initInputs();
      this.editMode = true;
    },
    onSaveButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      const promises = [];

      promises.push(
        this.$store.dispatch("accommodation/updateAccommodationLocation", {
          accommodation: {
            uuid: this.accommodation.uuid,
          },
          location: {
            uuid: this.location.uuid,
            ...this.editedLocation,
            latitude: this.editedLocation.latitude
              ? this.editedLocation.latitude.toString()
              : null,
            longitude: this.editedLocation.longitude
              ? this.editedLocation.longitude.toString()
              : null,
            distanceToAirport: this.editedLocation.distanceToAirport
              ? parseInt(this.editedLocation.distanceToAirport, 10)
              : null,
            distanceToPort: this.editedLocation.distanceToPort
              ? parseInt(this.editedLocation.distanceToPort, 10)
              : null,
            distanceToTrain: this.editedLocation.distanceToTrain
              ? parseInt(this.editedLocation.distanceToTrain, 10)
              : null,
            distanceToTaxi: this.editedLocation.distanceToTaxi
              ? parseInt(this.editedLocation.distanceToTaxi, 10)
              : null,
            distanceToBus: this.editedLocation.distanceToBus
              ? parseInt(this.editedLocation.distanceToBus, 10)
              : null,
            distanceToCityCenter: this.editedLocation.distanceToCityCenter
              ? parseInt(this.editedLocation.distanceToCityCenter, 10)
              : null,
            distanceToPharmacy: this.editedLocation.distanceToPharmacy
              ? parseInt(this.editedLocation.distanceToPharmacy, 10)
              : null,
            distanceToHospital: this.editedLocation.distanceToHospital
              ? parseInt(this.editedLocation.distanceToHospital, 10)
              : null,
            distanceToMarket: this.editedLocation.distanceToMarket
              ? parseInt(this.editedLocation.distanceToMarket, 10)
              : null,
            distanceToSandBeach: this.editedLocation.distanceToSandBeach
              ? parseInt(this.editedLocation.distanceToSandBeach, 10)
              : null,
            distanceToRockBeach: this.editedLocation.distanceToRockBeach
              ? parseInt(this.editedLocation.distanceToRockBeach, 10)
              : null,
            distanceToGolf: this.editedLocation.distanceToGolf
              ? parseInt(this.editedLocation.distanceToGolf, 10)
              : null,
            distanceToPark: this.editedLocation.distanceToPark
              ? parseInt(this.editedLocation.distanceToPark, 10)
              : null,
            distanceToSupermarket: this.editedLocation.distanceToSupermarket
              ? parseInt(this.editedLocation.distanceToSupermarket, 10)
              : null,
          },
        })
      );

      Promise.all(promises)
        .then(() => {
          notifySuccess(
            "Allotjament actualitzat",
            "L'allotjament s'ha actualitzat correctament"
          );
          this.editMode = false;
        })
        .catch(() =>
          notifyError(
            "Allotjament no actualitzat",
            "L'allotjament no s'ha pogut actualitzar correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onFullAddressCopied() {
      notifySuccess(
        "Adreça copiada",
        "L'adreça completa de l'allotjament ha estat copiada al porta-retalls"
      );
    },
    onFullAddressCopyError() {
      notifyError(
        "Adreça no copiada",
        "Hi ha hagut un error al intentar copiar l'adreça al porta-retalls, si us plau, intenta-ho més tard"
      );
    },
    fetchPlaces() {
      this.$store
        .dispatch("places/fetchPlaces", { pagination: false })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchPlaces.title"),
            this.$t("errors.fetchPlaces.description")
          )
        );
    },
    openGoogleMapsLink(latitude, longitude) {
      if (!latitude || !longitude) return;
      window.open(
        `http://www.google.com/maps/place/${latitude},${longitude}`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#location-card {
  .vs__dropdown-menu {
    z-index: 1001;
  }
}
.map-container {
  height: 400px;
  width: 100%;
}
</style>
