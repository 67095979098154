<template>
  <div id="bedroom-form">
    <!-- TYPE -->
    <b-row class="mb-3">
      <b-col cols="12">
        <h6 class="mb-1">Tipus de dormitori</h6>
        <b-form-radio
          v-model="type"
          name="bedroom-type-radio"
          value="STANDARD"
          class="mr-2 d-inline"
        >
          Estàndard
        </b-form-radio>
        <b-form-radio
          v-model="type"
          name="bedroom-type-radio"
          value="MASTER"
          class="mr-2 d-inline"
        >
          Principal
        </b-form-radio>
        <b-form-radio
          v-model="type"
          name="bedroom-type-radio"
          value="COMUNAL"
          class="mr-2 d-inline"
        >
          Zona comuna
        </b-form-radio>
        <b-form-radio
          v-model="type"
          name="bedroom-type-radio"
          value="OPEN"
          class="d-inline ml-1"
        >
          Obert
        </b-form-radio>
      </b-col>
    </b-row>

    <!-- BEDS -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6 class="mb-1">
          Llits disponibles
          <b-button
            variant="primary"
            size="sm"
            class="ml-1"
            @click="addBedButtonClicked()"
          >
            Afegeix un llit
          </b-button>
        </h6>
        <b-row v-for="(bed, index) in beds" :key="`bed-${index}`" class="mb-2">
          <b-col cols="1">
            <b-form-group label="Esborra" label-for="deleteBedButton">
              <b-button
                id="deleteBedButton"
                variant="danger"
                size="sm"
                @click.stop="deleteBedButtonClicked(bed, index)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </b-form-group>
          </b-col>
          <b-col cols="11">
            <b-row>
              <b-col cols="12" lg="6">
                <b-form-group
                  label="Tipus de llit"
                  :label-for="`bed-${index}-type-input`"
                >
                  <v-select
                    :id="`bed-${index}-type-input`"
                    v-model="bed.type"
                    :options="bedOptions"
                    :name="`bed-${index}-type`"
                    :reduce="(option) => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="3">
                <b-form-group
                  label="Amplada"
                  :label-for="`bed-${index}-width-input`"
                >
                  <b-input-group class="input-group-merge" append="cm">
                    <b-form-input
                      :id="`bed-${index}-width-input`"
                      v-model="bed.width"
                      class="w-full"
                      :name="`bed-${index}-width`"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="3">
                <b-form-group
                  label="Llargària"
                  :label-for="`bed-${index}-length-input`"
                >
                  <b-input-group class="input-group-merge" append="cm">
                    <b-form-input
                      :id="`bed-${index}-length-input`"
                      v-model="bed.length"
                      class="w-full"
                      :name="`bed-${index}-length`"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" lg="6">
                <b-form-group
                  label="Tipus matalàs"
                  :label-for="`bed-${index}-mattress-type-input`"
                >
                  <v-select
                    :id="`bed-${index}-mattress-type-input`"
                    v-model="bed.mattressType"
                    :options="mattressOptions"
                    name="mattressType"
                    :reduce="(option) => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12" lg="6">
                <b-form-group
                  label="Tipus coixí"
                  :label-for="`bed-${index}-pillow-type-input`"
                >
                  <v-select
                    :id="`bed-${index}-pillow-type-input`"
                    v-model="bed.pillowType"
                    :options="pillowOptions"
                    name="pillowType"
                    :reduce="(option) => option.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- FLOOR -->
    <b-row class="mb-3">
      <b-col cols="12">
        <h6 class="mb-1">Planta <small>(dins l'habitatge)</small></h6>
        <b-form-spinbutton v-model="floor" min="-5" max="5" />
      </b-col>
    </b-row>

    <!-- SIZE -->
    <b-row class="mb-3">
      <b-col cols="12">
        <h6 class="mb-1">Superfície</h6>
        <b-input-group class="input-group-merge">
          <b-form-input v-model="size" type="number" />
          <b-input-group-append is-text> m<sup>2</sup> </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>

    <!-- VIEW -->
    <b-row class="mb-3">
      <b-col cols="12">
        <h6 class="mb-1">Vistes</h6>
        <v-select
          v-model="view"
          :options="viewOptions"
          name="view"
          :reduce="(option) => option.value"
        />
      </b-col>
    </b-row>

    <!-- CLIMATITZACIÓ -->
    <b-row class="mb-3">
      <b-col cols="12">
        <h6 class="mb-1">Climatització</h6>
        <b-row>
          <b-col cols="1">
            <b-form-checkbox v-model="ac" switch>
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="11">
            <p>Té aire acondicionat?</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="1">
            <b-form-checkbox v-model="heating" switch>
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="5">
            <p>Té calefacció?</p>
          </b-col>
        </b-row>
        <b-row v-if="heating">
          <b-col cols="12">
            <div cols="6">
              <v-select
                v-model="heatingType"
                placeholder="Selecciona el tipus de calefacció"
                :options="heatingOptions"
                :reduce="(option) => option.value"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- ENSUITE BATHROOM -->
    <b-row class="mb-3">
      <b-col cols="12">
        <h6 class="mb-1">Bany</h6>
        <b-row>
          <b-col cols="1">
            <b-form-checkbox v-model="ensuite" switch>
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </b-col>
          <b-col cols="11">
            <p>Té bany en suite?</p>
          </b-col>
        </b-row>
        <b-row v-if="ensuite">
          <b-col cols="12">
            <div cols="6">
              <v-select
                v-model="ensuiteBathroom"
                placeholder="Selecciona el bany en suite"
                :options="ensuiteBathroomOptions"
                :reduce="(option) => option.value"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-alert
              variant="danger"
              class="mt-1"
              :show="ensuiteBathroomAlreadyAssigned"
            >
              <div class="alert-body">
                Aquest bany ja està assignat al dormitori
                {{ ensuiteBathroomAlreadyAssignedTo }}. No es pot assignar a
                aquest dormitori, elimina'l primer de l'altre.
              </div>
            </b-alert>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- AMENITIES -->
    <b-row class="mb-3">
      <b-col cols="12">
        <h6 class="mb-1">Característiques</h6>
        <b-row class="d-flex flex-wrap">
          <b-col cols="12" sm="6" md="4" lg="3">
            <b-form-checkbox v-model="wardrobe"> Armari </b-form-checkbox>
          </b-col>
          <b-col cols="12" sm="6" md="4" lg="3">
            <b-form-checkbox v-model="mosquitoNet">
              Mosquitera
            </b-form-checkbox>
          </b-col>
          <b-col cols="12" sm="6" md="4" lg="3">
            <b-form-checkbox v-model="tv"> TV </b-form-checkbox>
          </b-col>
          <b-col cols="12" sm="6" md="4" lg="3">
            <b-form-checkbox v-model="smartTv"> Smart TV </b-form-checkbox>
          </b-col>
          <b-col cols="12" sm="6" md="4" lg="3">
            <b-form-checkbox v-model="blinds"> Persianes </b-form-checkbox>
          </b-col>
          <b-col cols="12" sm="6" md="4" lg="3">
            <b-form-checkbox v-model="curtains"> Cortines </b-form-checkbox>
          </b-col>
          <b-col cols="12" sm="6" md="4" lg="3">
            <b-form-checkbox v-model="balcony"> Balcó </b-form-checkbox>
          </b-col>
          <b-col cols="12" sm="6" md="4" lg="3">
            <b-form-checkbox v-model="disabledAdapted">
              Adaptat
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button
          block
          variant="primary"
          :disabled="ensuiteBathroomAlreadyAssigned"
          @click="save()"
        >
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormRadio,
  BFormCheckbox,
  BFormSpinbutton,
  BButton,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { notifySuccess, notifyError } from "@/utils/methods";
import {
  heatingTypeOptions,
  viewTypeOptions,
  bedTypeOptions,
  mattressTypeOptions,
  pillowTypeOptions,
} from "@/utils/select-options";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormRadio,
    BFormCheckbox,
    BFormSpinbutton,
    BButton,
    BAlert,
    vSelect,
  },
  props: {
    bedroom: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      type: "STANDARD",
      floor: null,
      view: null,
      size: null,
      ac: false,
      heating: false,
      heatingType: null,
      ensuite: false,
      ensuiteBathroom: null,
      wardrobe: false,
      mosquitoNet: false,
      tv: false,
      smartTv: false,
      blinds: false,
      curtains: false,
      balcony: false,
      disabledAdapted: false,
      availableBeds: 1,
      beds: [
        {
          type: null,
          width: null,
          length: null,
          mattressType: null,
          pillowType: null,
        },
      ],
      selectedBed: null,
      selectedBedIndex: null,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    bedrooms() {
      return this.$store.getters["accommodation/bedrooms"];
    },
    bathrooms() {
      return this.$store.getters["accommodation/bathrooms"];
    },
    ensuiteBathroomOptions() {
      return this.bathrooms.map((bathroom, index) => {
        const bathroomSpecs = [];
        if (bathroom.toilets > 0) {
          bathroomSpecs.push("wc");
        }
        if (bathroom.bathtubs > 0) {
          bathroomSpecs.push("banyera");
        }
        if (bathroom.showers > 0) {
          bathroomSpecs.push("dutxa");
        }
        return {
          label: `Bany ${index + 1} (${
            bathroomSpecs.length > 0 ? bathroomSpecs.join(", ") : "no definit"
          })`,
          value: bathroom["@id"],
        };
      });
    },
    ensuiteBathroomAlreadyAssigned() {
      if (this.ensuite) {
        let alreadyAssigned = false;
        if (this.ensuiteBathroom) {
          this.bedrooms.forEach((bedroom) => {
            if (
              !alreadyAssigned &&
              ((this.bedroom && bedroom.uuid !== this.bedroom.uuid) ||
                !this.bedroom) &&
              bedroom.bathroom === this.ensuiteBathroom
            ) {
              alreadyAssigned = true;
            }
          });
        }
        return alreadyAssigned;
      }
      return false;
    },
    ensuiteBathroomAlreadyAssignedTo() {
      if (this.ensuite) {
        let alreadyAssignedTo = null;
        if (this.ensuiteBathroom) {
          this.bedrooms.forEach((bedroom, index) => {
            if (
              !alreadyAssignedTo &&
              bedroom.bathroom === this.ensuiteBathroom
            ) {
              alreadyAssignedTo = index + 1;
            }
          });
        }
        return alreadyAssignedTo;
      }
      return null;
    },
    bedOptions() {
      return bedTypeOptions;
    },
    mattressOptions() {
      return mattressTypeOptions;
    },
    pillowOptions() {
      return pillowTypeOptions;
    },
    viewOptions() {
      return viewTypeOptions;
    },
    heatingOptions() {
      return heatingTypeOptions;
    },
  },
  watch: {
    heating(heating) {
      if (!heating) this.heatingType = null;
    },
    ensuite(ensuite) {
      if (!ensuite) this.ensuiteBathroom = null;
    },
  },
  created() {
    if (this.bedroom) this.initForm();
  },
  methods: {
    initForm() {
      this.type = this.bedroom.type;
      this.availableBeds = this.bedroom.beds.length;
      this.beds = this.bedroom.beds.map((bed) => ({
        uuid: bed.uuid,
        type: bed.type,
        width: bed.width,
        length: bed.length,
        mattressType: bed.mattressType,
        pillowType: bed.pillowType,
      }));
      this.floor = this.bedroom.floor;
      this.view = this.bedroom.view;
      this.ac = this.bedroom.ac;
      this.heating = this.bedroom.heating;
      this.heatingType = this.bedroom.heatingType;
      this.ensuite = !!this.bedroom.bathroom;
      this.ensuiteBathroom = this.bedroom.bathroom;
      this.size = this.bedroom.size;
      this.wardrobe = this.bedroom.wardrobe;
      this.mosquitoNet = this.bedroom.mosquitoNet;
      this.tv = this.bedroom.tv;
      this.smartTv = this.bedroom.smartTv;
      this.blinds = this.bedroom.blinds;
      this.curtains = this.bedroom.curtains;
      this.balcony = this.bedroom.balcony;
      this.disabledAdapted = this.bedroom.disabledAdapted;
    },
    resetForm() {
      this.type = "STANDARD";
      this.floor = 0;
      this.view = null;
      this.size = null;
      this.ac = false;
      this.heating = false;
      this.heatingType = null;
      this.ensuite = false;
      this.ensuiteBathroom = null;
      this.wardrobe = null;
      this.mosquitoNet = false;
      this.tv = false;
      this.smartTv = false;
      this.blinds = false;
      this.curtains = false;
      this.balcony = false;
      this.disabledAdapted = false;
      this.availableBeds = 1;
      this.beds = [
        {
          type: null,
          width: null,
          length: null,
          mattressType: null,
          pillowType: null,
        },
      ];
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      const filteredBeds = this.beds.filter((bed) => bed.type);

      if (this.bedroom) {
        // We are in edit form, update the bathroom
        const bedroom = {
          uuid: this.bedroom.uuid,
          type: this.type || null,
          size: this.size ? parseInt(this.size, 10) : null,
          view: this.view || null,
          floor:
            this.floor || this.floor === 0 ? parseInt(this.floor, 10) : null,
          ac: this.ac || false,
          heating: this.heating || false,
          heatingType: this.heatingType || null,
          bathroom: this.ensuiteBathroom || null,
          wardrobe: this.wardrobe || false,
          mosquitoNet: this.mosquitoNet || false,
          tv: this.tv || false,
          smartTv: this.smartTv || false,
          blinds: this.blinds || false,
          curtains: this.curtains || false,
          balcony: this.balcony || false,
          disabledAdapted: this.disabledAdapted || false,
          beds: filteredBeds.map((bed) => ({
            uuid: bed.uuid || null,
            type: bed.type || null,
            width: bed.width ? parseInt(bed.width, 10) : null,
            length: bed.length ? parseInt(bed.length, 10) : null,
            mattressType: bed.mattressType || null,
            pillowType: bed.pillowType || null,
          })),
        };

        this.$store
          .dispatch("accommodation/updateAccommodationBedroom", bedroom)
          .then((result) => {
            this.$emit("bedroom-edited", result);
            this.$store
              .dispatch(
                "accommodation/fetchAccommodationBathrooms",
                this.accommodation.uuid
              )
              .finally(() => {
                notifySuccess(
                  "Dormitori editat",
                  "El dormitori ha estat editat correctament."
                );
                this.resetForm();
                this.$store.dispatch("app/setLoading", false);
              });
          })
          .catch((error) => {
            notifyError(
              "Dormitori no afegit",
              "Hi ha hagut un error al afegir el dormitori."
            );
            this.$emit("edit-bedroom-error", error);
            this.resetForm();
            this.$store.dispatch("app/setLoading", false);
            // TODO: log error to Sentry
          });
      } else {
        // We are in add form
        const bedroom = {
          uuid: uuidv4(),
          accommodation: this.accommodation["@id"],
          type: this.type || null,
          size: this.size ? parseInt(this.size, 10) : null,
          view: this.view || null,
          floor:
            this.floor || this.floor === 0 ? parseInt(this.floor, 10) : null,
          ac: this.ac || false,
          heating: this.heating || false,
          heatingType: this.heatingType || null,
          bathroom: this.ensuiteBathroom || null,
          wardrobe: this.wardrobe || false,
          mosquitoNet: this.mosquitoNet || false,
          tv: this.tv || false,
          smartTv: this.smartTv || false,
          blinds: this.blinds || false,
          curtains: this.curtains || false,
          balcony: this.balcony || false,
          disabledAdapted: this.disabledAdapted || false,
          beds: filteredBeds.map((bed) => ({
            uuid: uuidv4(),
            type: bed.type || null,
            width: bed.width ? parseInt(bed.width, 10) : null,
            length: bed.length ? parseInt(bed.length, 10) : null,
            mattressType: bed.mattressType || null,
            pillowType: bed.pillowType || null,
          })),
        };

        this.$store
          .dispatch("accommodation/addAccommodationBedroom", bedroom)
          .then((result) => {
            notifySuccess(
              "Dormitori afegit",
              "El dormitori ha estat afegit correctament."
            );
            this.$store.dispatch(
              "accommodation/fetchAccommodationBathrooms",
              this.accommodation.uuid
            );
            this.$emit("bedroom-added", result);
          })
          .catch((error) => {
            notifyError(
              "Dormitori no afegit",
              "Hi ha hagut un error al afegir el dormitori."
            );
            this.$emit("add-bedroom-error", error);
            // TODO: log error to Sentry
          })
          .finally(() => {
            this.resetForm();
            this.$store.dispatch("app/setLoading", false);
          });
      }
    },
    addBedButtonClicked() {
      this.beds.push({
        type: null,
        width: null,
        length: null,
        mattressType: null,
        pillowType: null,
      });
      this.availableBeds += 1;
    },
    deleteBedButtonClicked(bed, index) {
      if (bed.uuid) {
        // The bed already exist in the db
        // We have to delete it from the db.
        this.selectedBed = bed;
        this.confirmDeleteBed();
      } else {
        // The bed still doesn't exist in the db
        // We just need to remove it from the array.
        this.beds.splice(index, 1);
        this.availableBeds -= 1;
      }
    },
    confirmDeleteBed() {
      this.$swal({
        title: "Eliminar llit",
        text: "Estàs a punt d'eliminar aquest llit, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteBed();
      });
    },
    deleteBed() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("accommodation/deleteAccommodationBed", {
          bedroomUuid: this.bedroom.uuid,
          bedUuid: this.selectedBed.uuid,
        })
        .then(() => {
          const selectedBedIndex = this.beds.findIndex(
            (bed) => bed.uuid === this.selectedBed.uuid
          );
          if (selectedBedIndex !== -1) {
            this.beds.splice(selectedBedIndex, 1);
            this.availableBeds -= 1;
          }
        })
        .catch(() =>
          notifyError(
            "Llit no eliminat",
            "Hi ha hagut un error al eliminar el llit."
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
