<template>
  <div class="bedroom-card">
    <b-card :title="title">
      <!-- BEDROOM INFO -->
      <b-row class="mb-2">
        <b-col cols="12">
          <ul>
            <!-- NO BEDS ALERT -->
            <li class="px-1">
              <b-alert variant="danger" class="mt-1" :show="noBeds">
                <div class="alert-body">
                  Fa falta afegir algún llit a aquest dormitori!
                </div>
              </b-alert>
            </li>
            <!-- BEDS -->
            <li
              v-for="(bed, index) in bedroom.beds"
              :key="`bed-${bedroom.id}-${index}`"
              class="py-1 px-2 border border-right-0 border-left-0 border-bottom-0"
              :class="{ 'border-top-0': index == 0 }"
            >
              <div class="font-medium">
                {{ bedTypeName(bed) }}
              </div>
              <div>
                <small
                  v-if="bedDescriptionText(bed)"
                  class="text-sm font-light"
                >
                  {{ bedDescriptionText(bed) }}
                </small>
              </div>
            </li>

            <!-- BEDROOM TYPE -->
            <li
              class="d-flex justify-content-between py-1 px-2 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="d-flex items-center">
                <span class="font-medium">Tipus</span>
              </span>
              <span>{{ bedroomTypeText }}</span>
            </li>

            <!-- FLOOR -->
            <li
              class="d-flex justify-content-between py-1 px-2 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="d-flex items-center">
                <span class="font-medium">Altura</span>
              </span>
              <span>{{ floorText }}</span>
            </li>

            <!-- SIZE -->
            <li
              class="d-flex justify-content-between py-1 px-2 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="d-flex items-center">
                <span class="font-medium">Tamany</span>
              </span>
              <span>{{ sizeText }}</span>
            </li>

            <!-- VIEWS -->
            <li
              class="d-flex justify-content-between py-1 px-2 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="d-flex items-center">
                <span class="font-medium">Vistes</span>
              </span>
              <span>{{ viewsText }}</span>
            </li>

            <!-- AC -->
            <li
              class="d-flex justify-content-between py-1 px-2 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="d-flex items-center">
                <span class="font-medium">A/C</span>
              </span>
              <span>{{ acText }}</span>
            </li>

            <!-- HEATING -->
            <li
              class="d-flex justify-content-between py-1 px-2 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="d-flex items-center">
                <span class="font-medium">Calefacció</span>
              </span>
              <span>{{ heatingText }}</span>
            </li>

            <!-- ENSUITE BATHROOM -->
            <li
              class="d-flex justify-content-between py-1 px-2 border border-right-0 border-left-0 border-bottom-0"
            >
              <span class="d-flex items-center">
                <span class="font-medium">Bany en suite</span>
              </span>
              <span>{{ ensuiteBathroomText }}</span>
            </li>

            <!-- AMENITIES -->
            <li
              v-if="bedroomAmenitiesText"
              class="pt-1 px-2 border border-right-0 border-left-0 border-bottom-0"
            >
              <span>{{ bedroomAmenitiesText }}</span>
            </li>
          </ul>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-end">
        <b-col cols="6" sm="auto">
          <b-button variant="danger" block @click="confirmDeleteBedroom">
            Elimina
          </b-button>
        </b-col>
        <b-col cols="6" sm="auto">
          <b-button
            v-b-modal="`bedroom-modal-${bedroom.uuid}`"
            block
            variant="primary"
          >
            Edita
          </b-button>
        </b-col>
      </b-row>

      <!-- EDIT BEDROOM MODAL -->
      <b-modal
        :id="`bedroom-modal-${bedroom.uuid}`"
        title="Edita el dormitori"
        size="lg"
        scrollable
        hide-footer
      >
        <bedroom-form
          :bedroom="bedroom"
          @bedroom-edited="hideBedroomModal(bedroom)"
          @edit-bedroom-error="hideBedroomModal(bedroom)"
          @cancel="hideBedroomModal(bedroom)"
        />
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BAlert } from "bootstrap-vue";
import BedroomForm from "@/views/accommodations/accommodation/components/BedroomForm.vue";
import {
  getBedTypeName,
  getBedDescriptionText,
  getBedroomTypeName,
  getBedroomAmenitiesText,
  getViewName,
  getHeatingName,
  getEnsuiteBathroomText,
  getFloorName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";

export default {
  name: "BedroomCard",
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BedroomForm,
    BAlert,
  },
  props: {
    bedroom: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      editBedroomPopupOpen: false,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    bathrooms() {
      return this.$store.getters["accommodation/bathrooms"];
    },
    bedroomTypeText() {
      const name = getBedroomTypeName(this.bedroom.type);
      return name || "No definit";
    },
    floorText() {
      const text = getFloorName(this.bedroom.floor);
      return text || "No definida";
    },
    sizeText() {
      return this.bedroom.size ? `${this.bedroom.size} m2` : "No definit";
    },
    viewsText() {
      const name = getViewName(this.bedroom.view);
      return name || "No definit";
    },
    acText() {
      return this.bedroom.ac ? "Sí" : "No";
    },
    heatingText() {
      if (this.bedroom.heating) {
        const name = getHeatingName(this.bedroom.heatingType);
        return name || "Sí";
      }
      return "No";
    },
    ensuiteBathroomText() {
      const text = getEnsuiteBathroomText(this.bedroom, this.bathrooms);
      return text || "No";
    },
    bedroomAmenitiesText() {
      return getBedroomAmenitiesText(this.bedroom);
    },
    noBeds() {
      return !this.bedroom.beds || this.bedroom.beds?.length === 0;
    },
  },
  methods: {
    bedTypeName(bed) {
      const name = getBedTypeName(bed.type);
      return name || "No definit";
    },
    bedDescriptionText(bed) {
      const text = getBedDescriptionText(bed);
      return text;
    },
    confirmDeleteBedroom() {
      this.$swal({
        title: "Eliminar dormitori",
        text: "Estàs a punt d'eliminar aquest dormitori, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteBedroom();
      });
    },
    deleteBedroom() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("accommodation/deleteAccommodationBedroom", this.bedroom.uuid)
        .then((result) => {
          this.$emit("bedroom-deleted", result);
          this.$store
            .dispatch(
              "accommodation/fetchAccommodationBathrooms",
              this.accommodation.uuid
            )
            .finally(() => {
              this.$store.dispatch("app/setLoading", false);
              notifySuccess(
                "Dormitori eliminat",
                "El dormitori ha estat eliminat correctament."
              );
            });
        })
        .catch((error) => {
          this.$emit("bedroom-delete-error", error);
          notifyError(
            "Dormitori no eliminat",
            "Hi ha hagut un error al eliminar el dormitori."
          );
          this.$store.dispatch("app/setLoading", false);
          // TODO: log error in Sentry
        });
    },
    hideBedroomModal(bedroom) {
      this.$bvModal.hide(`bedroom-modal-${bedroom.uuid}`);
    },
  },
};
</script>

<style lang="scss">
.bedroom-card {
  .card {
    margin-bottom: 0;
    height: 100%;
  }
  ul {
    padding-left: 0 !important;
    list-style: none;
  }
}
</style>
