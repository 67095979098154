<template>
  <b-card no-body id="statistics-insights-card">
    <b-card-header>
      <div>
        <b-card-title> Recompte de reserves </b-card-title>
        <b-card-text class="font-small-2">
          {{ periodText }}
        </b-card-text>
      </div>
      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item
          v-for="(option, index) in periodOptions"
          :key="`period-option-${index}`"
          @click="selectPeriod(option.value)"
        >
          {{ option.label }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <b-card-body class="pb-2 px-2">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                <b-spinner v-if="loadingBookings" small />
                <span v-else>
                  {{ item.count }}
                  <small v-if="item.percentage"
                    >({{ item.percentage }} %)</small
                  >
                </span>
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.title }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BSpinner,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

export default {
  components: {
    BSpinner,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      period: "CURRENT_YEAR",
      periodOptions: [
        { label: this.$t("Any actual"), value: "CURRENT_YEAR" },
        { label: this.$t("Any passat"), value: "LAST_YEAR" },
        { label: this.$t("Desde l'inici"), value: "ALL" },
      ],
    };
  },
  computed: {
    loadingBookings() {
      return this.$store.getters["accommodation/loadingBookings"];
    },
    bookings() {
      return this.$store.getters["accommodation/bookings"];
    },
    filteredBookings() {
      if (!this.bookings?.length) {
        return [];
      }

      const currentYear = new Date().getFullYear();
      const lastYear = currentYear - 1;

      return this.bookings.filter((booking) => {
        const checkinYear = new Date(booking.checkin).getFullYear();

        switch (this.period) {
          case "CURRENT_YEAR":
            return checkinYear === currentYear;
          case "LAST_YEAR":
            return checkinYear === lastYear;
          case "ALL":
            return true;
        }
        return false;
      });
    },
    requestedBookingsCount() {
      return this.filteredBookings.filter(
        (booking) => booking.status === "REQUESTED"
      ).length;
    },
    requestedBookingsPercentage() {
      if (!this.filteredBookings.length) {
        return null;
      }

      return Math.round(
        (100 / this.filteredBookings.length) * this.requestedBookingsCount
      );
    },
    confirmedBookingsCount() {
      return this.filteredBookings.filter(
        (booking) => booking.status === "CONFIRMED"
      ).length;
    },
    confirmedBookingsPercentage() {
      if (!this.filteredBookings.length) {
        return null;
      }

      return Math.round(
        (100 / this.filteredBookings.length) * this.confirmedBookingsCount
      );
    },
    completedBookingsCount() {
      return this.filteredBookings.filter(
        (booking) => booking.status === "COMPLETED"
      ).length;
    },
    completedBookingsPercentage() {
      if (!this.filteredBookings.length) {
        return null;
      }

      return Math.round(
        (100 / this.filteredBookings.length) * this.completedBookingsCount
      );
    },
    cancelledBookingsCount() {
      return this.filteredBookings.filter(
        (booking) => booking.status === "CANCELLED"
      ).length;
    },
    cancelledBookingsPercentage() {
      if (!this.filteredBookings.length) {
        return null;
      }

      return Math.round(
        (100 / this.filteredBookings.length) * this.cancelledBookingsCount
      );
    },
    statisticsItems() {
      return [
        {
          icon: "HelpCircleIcon",
          color: "light-warning",
          count: this.requestedBookingsCount,
          percentage: this.requestedBookingsPercentage,
          title: "Sol·licitades",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "ThumbsUpIcon",
          color: "light-success",
          count: this.confirmedBookingsCount,
          percentage: this.confirmedBookingsPercentage,
          title: "Confirmades",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "CheckCircleIcon",
          color: "light-primary",
          count: this.completedBookingsCount,
          percentage: this.completedBookingsPercentage,
          title: "Completades",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "XCircleIcon",
          color: "light-danger",
          count: this.cancelledBookingsCount,
          percentage: this.cancelledBookingsPercentage,
          title: "Cancel·lades",
          customClass: "",
        },
      ];
    },
    periodText() {
      const periodTexts = {
        CURRENT_YEAR: this.$t("Any actual"),
        LAST_YEAR: this.$t("Any passat"),
        ALL: this.$t("Desde l'inici"),
      };

      return periodTexts[this.period] || null;
    },
  },
  methods: {
    selectPeriod(period) {
      this.period = period;
    },
  },
};
</script>
