<template>
  <div id="bathroom-form">
    <!-- AMENITIES -->
    <b-row class="mb-2">
      <b-col cols="12">
        <h6 class="mb-1">Característiques</h6>
      </b-col>
      <b-col cols="12">
        <div class="d-flex flex-wrap">
          <b-form-checkbox v-model="toilets" class="mr-1"> WC </b-form-checkbox>
          <b-form-checkbox v-model="bathtubs" class="mr-1">
            Banyera
          </b-form-checkbox>
          <b-form-checkbox v-model="showers"> Dutxa </b-form-checkbox>
        </div>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormCheckbox, BButton } from "bootstrap-vue";
import { notifySuccess, notifyError } from "@/utils/methods";

export default {
  name: "BathroomForm",
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
  },
  props: {
    bathroom: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      bathtubs: false,
      showers: false,
      // If we are in add mode, we mark toilets by default
      toilets: !this.bathroom,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
  },
  created() {
    if (this.bathroom) this.initForm();
  },
  methods: {
    initForm() {
      // TODO: modificar quan ampliem els detalls del bany
      this.bathtubs = this.bathroom.bathtubs > 0;
      this.showers = this.bathroom.showers > 0;
      this.toilets = this.bathroom.toilets > 0;
    },
    resetForm() {
      // TODO: modificar quan ampliem els detalls del bany
      this.bathtubs = false;
      this.showers = false;
      this.toilets = false;
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    save() {
      // TODO: validar camps
      this.$store.dispatch("app/setLoading", true);
      if (this.bathroom)
        this.updateBathroom(); // We are in edit form, update the bathroom
      else this.addBathroom(); // We are in add form
    },
    updateBathroom() {
      this.$store
        .dispatch("accommodation/updateAccommodationBathroom", {
          uuid: this.bathroom.uuid,
          bathtubs: this.bathtubs ? 1 : 0,
          showers: this.showers ? 1 : 0,
          toilets: this.toilets ? 1 : 0,
        })
        .then((result) => {
          notifySuccess("Bany editat", "El bany ha estat editat correctament.");
          this.$emit("bathroom-edited", result);
        })
        .catch((error) => {
          notifyError(
            "Bany no editat",
            "Hi ha hagut un error al editar el bany."
          );
          this.$emit("edit-bathroom-error", error);
          // TODO: log the error in Sentry
        })
        .finally(() => {
          this.resetForm();
          this.$store.dispatch("app/setLoading", false);
        });
    },
    addBathroom() {
      this.$store
        .dispatch("accommodation/addAccommodationBathroom", {
          accommodation: this.accommodation["@id"],
          bathtubs: this.bathtubs ? 1 : 0,
          showers: this.showers ? 1 : 0,
          toilets: this.toilets ? 1 : 0,
        })
        .then((result) => {
          notifySuccess("Bany afegit", "El bany ha estat afegit correctament.");
          this.$emit("bathroom-added", result);
        })
        .catch((error) => {
          notifyError(
            "Bany no afegit",
            "Hi ha hagut un error al afegir el bany."
          );
          this.$emit("add-bathroom-error", error);
          // TODO: log the error in Sentry
        })
        .finally(() => {
          this.resetForm();
          this.$store.dispatch("app/setLoading", false);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
