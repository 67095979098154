<template>
  <b-overlay :show="loadingAmenities" rounded="sm" variant="white">
    <b-card id="amenities-card" title="Característiques">
      <!-- SURFACES -->
      <b-row class="mb-3">
        <b-col cols="12">
          <h5 class="mb-2"><feather-icon icon="CropIcon" /> Superfícies</h5>
        </b-col>
        <b-col cols="12">
          <b-row>
            <!-- PLOT -->
            <b-col cols="6" md="4" class="mb-1">
              <h6>Solar</h6>
              <b-input-group v-if="editMode" class="input-group-merge">
                <b-form-input v-model="plotSize" type="number" />
                <b-input-group-append is-text>
                  m<sup>2</sup>
                </b-input-group-append>
              </b-input-group>
              <b-card-text v-else>
                {{ plotSizeAmenityText }}
              </b-card-text>
            </b-col>
            <!-- ACCOMMODATION -->
            <b-col cols="6" md="4" class="mb-1">
              <h6>Habitatge</h6>
              <b-input-group v-if="editMode" class="input-group-merge">
                <b-form-input v-model="accommodationSize" type="number" />
                <b-input-group-append is-text>
                  m<sup>2</sup>
                </b-input-group-append>
              </b-input-group>
              <b-card-text v-else>
                {{ accommodationSizeAmenityText }}
              </b-card-text>
            </b-col>
            <!-- TERRACE -->
            <b-col cols="6" md="4" class="mb-1">
              <h6>Terrassa</h6>
              <b-input-group v-if="editMode" class="input-group-merge">
                <b-form-input v-model="terraceSize" type="number" />
                <b-input-group-append is-text>
                  m<sup>2</sup>
                </b-input-group-append>
              </b-input-group>
              <b-card-text v-else>
                {{ terraceSizeAmenityText }}
              </b-card-text>
            </b-col>
            <!-- GARDEN -->
            <b-col cols="6" md="4" class="mb-1">
              <h6>Jardí</h6>
              <b-input-group v-if="editMode" class="input-group-merge">
                <b-form-input v-model="gardenSize" type="number" />
                <b-input-group-append is-text>
                  m<sup>2</sup>
                </b-input-group-append>
              </b-input-group>
              <b-card-text v-else>
                {{ gardenSizeAmenityText }}
              </b-card-text>
            </b-col>
            <!-- POOL -->
            <b-col cols="6" md="4" class="mb-1">
              <h6>Piscina (superfície)</h6>
              <div v-if="editMode">
                <b-input-group class="mb-1 input-group-merge">
                  <b-form-input
                    v-model="poolLength"
                    placeholder="Llarg"
                    type="number"
                  />
                  <b-input-group-append is-text> m </b-input-group-append>
                </b-input-group>
                <b-input-group class="mb-1 input-group-merge">
                  <b-form-input
                    v-model="poolWidth"
                    placeholder="Ample"
                    type="number"
                  />
                  <b-input-group-append is-text> m </b-input-group-append>
                </b-input-group>
              </div>
              <b-card-text v-else>
                {{ poolSizeText }}
              </b-card-text>
            </b-col>
            <!-- POOL DEPTH -->
            <b-col cols="6" md="4" class="mb-1">
              <h6>Piscina (profunditat)</h6>
              <div v-if="editMode">
                <b-input-group class="mb-1 input-group-merge">
                  <b-form-input
                    v-model="poolMinDepth"
                    placeholder="Min"
                    type="number"
                  />
                  <b-input-group-append is-text> cm </b-input-group-append>
                </b-input-group>
                <b-input-group class="input-group-merge">
                  <b-form-input
                    v-model="poolMaxDepth"
                    placeholder="Max"
                    type="number"
                  />
                  <b-input-group-append is-text> cm </b-input-group-append>
                </b-input-group>
              </div>
              <b-card-text v-else>
                {{ poolDepthText }}
              </b-card-text>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- EQUIPMENT -->
      <b-row class="mb-2 equipment-amenities">
        <b-col cols="12">
          <h5 class="mb-2"><feather-icon icon="ListIcon" /> Equipament</h5>
        </b-col>
        <b-col cols="12">
          <b-tabs no-fade>
            <!-- POOL -->
            <b-tab active>
              <template #title> Piscina </template>
              <b-row class="mt-2">
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="PRIVATE_SWIMMING_POOL"
                  >
                    Piscina privada
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="COMMUNAL_SWIMMING_POOL"
                  >
                    Piscina comunitària
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="PUBLIC_SWIMMING_POOL"
                  >
                    Piscina pública
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="HEATED_SWIMMING_POOL"
                  >
                    Piscina climatitzada
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="COVERED_SWIMMING_POOL"
                  >
                    Piscina Coberta
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="CHLORINE_SWIMMING_POOL"
                  >
                    Piscina de clor
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="SALT_SWIMMING_POOL"
                  >
                    Piscina de sal
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="JACUZZI"
                  >
                    Jacuzzi
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="POOL_TOWELS"
                  >
                    Tovalloles de piscina
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-tab>
            <!-- CLIMA -->
            <b-tab>
              <template #title> Climatització </template>
              <b-row class="my-2">
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="allBedroomsACAmenity"
                    :disabled="true"
                  >
                    A/C habitacions (totes)
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="someBedroomsAcAmenity"
                    :disabled="true"
                  >
                    A/C habitacions (algunes)
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="AIR_CONDITIONING_LIVING_ROOM"
                  >
                    A/C sala d'estar
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="AIR_CONDITIONING_KITCHEN"
                  >
                    A/C cuina
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="AIR_CONDITIONING_CORRIDOR"
                  >
                    A/C passadís
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="allBedroomsHeatingAmenity"
                    :disabled="true"
                  >
                    Calefacció habitacions (totes)
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="someBedroomsHeatingAmenity"
                    :disabled="true"
                  >
                    Calefacció habitacions (algunes)
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="HEATING_LIVING_ROOM"
                  >
                    Calefacció sala d'estar
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="HEATING_KITCHEN"
                  >
                    Calefacció cuina
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="HEATING_CORRIDOR"
                  >
                    Calefacció passadís
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="FAN"
                  >
                    Ventiladors
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="FIREPLACE"
                  >
                    Ximeneia
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-tab>
            <!-- EXTERIOR -->
            <b-tab>
              <template #title> Exterior </template>
              <b-row class="mt-2">
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="TERRACE"
                  >
                    Terrassa
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="FURNISHED_TERRACE"
                  >
                    Mobiliari terrassa
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="BALCONY"
                  >
                    Balcó
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="GARDEN"
                  >
                    Jardí
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="BARBECUE"
                  >
                    Barbacoa
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="SOLARIUM"
                  >
                    Solarium
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="SUN_LOUNGERS"
                  >
                    Hamaques
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="PLAYGROUND"
                  >
                    Zona infantil
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="ORCHARD"
                  >
                    Hort
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="FARM_ANIMALS"
                  >
                    Animals de granja
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-tab>
            <!-- KITCHEN -->
            <b-tab>
              <template #title> Cuina </template>
              <b-row class="mt-2">
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="KITCHEN"
                  >
                    Cuina
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="OVEN"
                  >
                    Forn
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="MICROWAVE"
                  >
                    Microones
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="FRIDGE"
                  >
                    Gelera
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="FREEZER"
                  >
                    Congelador
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="DISHES"
                  >
                    Vaixella
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="DISHWASHER"
                  >
                    Rentavaixelles
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="COFFEE_MAKER"
                  >
                    Cafetera
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="CAPSULE_COFFEE_MAKER"
                  >
                    Cafetera de capsules
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="NESPRESSO"
                  >
                    Cafetera Nespresso
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="DOLCEGUSTO"
                  >
                    Cafetera DolceGusto
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="TEAPOT"
                  >
                    Tetera
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="KETTLE"
                  >
                    Kettle
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="TOASTER"
                  >
                    Torradora
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="SQUEEZER"
                  >
                    Espremedora
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="FOOD_MIXER"
                  >
                    Batedora
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="FRYING_PAN"
                  >
                    Fregidora
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="SPICE_SELECTION"
                  >
                    Espècies
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="MINI_BAR"
                  >
                    Mini bar
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-tab>
            <!-- ENTERTAINMENT -->
            <b-tab>
              <template #title> Entreteniment </template>
              <b-row class="mt-2">
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="FREE_WIFI"
                  >
                    WiFi gratuït
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="PAID_WIFI"
                  >
                    WiFi de pagament
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="TV"
                  >
                    TV
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="SMART_TV"
                  >
                    Smart TV
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="SATELLITE_TV"
                  >
                    TV Satèl·lit
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="DVD_PLAYER"
                  >
                    Reproductor DVD
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="SOUND_SYSTEM"
                  >
                    Equip de música
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="POOL_TABLE"
                  >
                    Billar
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="TABLE_TENNIS"
                  >
                    Ping-Pong
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="FUTBOLIN"
                  >
                    Futbolín
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="BOARD_GAMES"
                  >
                    Jocs de taula
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="GYM"
                  >
                    Gimnàs
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="SAUNA"
                  >
                    Sauna
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="HYDROMASSAGE_BATHTUB"
                  >
                    Banyera hidromassatge
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-tab>
            <!-- GENERAL -->
            <b-tab>
              <template #title> General </template>
              <b-row class="mt-2">
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="BABY_COT"
                  >
                    Cuna
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="BABY_CHAIR"
                  >
                    Trona
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="BED_LINEN"
                  >
                    Llençols
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="TOWELS"
                  >
                    Tovalloles
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="BATHROBE"
                  >
                    Barnussos
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="SLIPPERS"
                  >
                    Sabatilles
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="WASHING_MACHINE"
                  >
                    Rentadora
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="TUMBLE_DRYER"
                  >
                    Assecadora
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="CLOTHES_HANGERS"
                  >
                    Penjadors
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="CLOTHES_LINE"
                  >
                    Estenedor
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="IRON"
                  >
                    Planxa
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="IRONING_BOARD"
                  >
                    Taula de planxar
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="VACUUM"
                  >
                    Aspirador
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="ELEVATOR"
                  >
                    Ascensor
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-tab>
            <!-- VEHICLES & PARKING -->
            <b-tab>
              <template #title> Vehicles i Aparcament </template>
              <b-row class="mt-2">
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="FREE_PARKING"
                  >
                    Parking gratuït
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="PAID_PARKING"
                  >
                    Parking de pagament
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="COVERED_PARKING"
                  >
                    Parking cobert
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="STREET_PARKING"
                  >
                    Parking al carrer
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="CAR_NEEDED"
                  >
                    Coche necessari
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="CAR_RECOMMENDED"
                  >
                    Coche recomanat
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="CAR_NOT_NEEDED"
                  >
                    Coche no necessari
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="BICYCLE_STORAGE"
                  >
                    Guarda bicicletes
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-tab>
            <!-- SECURITY -->
            <b-tab>
              <template #title> Seguretat </template>
              <b-row class="mt-2">
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="FIRE_EXTINGUISHER"
                  >
                    Extintors
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="SMOKE_DETECTOR"
                  >
                    Detectors de fum
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="FIRST_AID_KIT"
                  >
                    Farmaciola
                  </b-form-checkbox>
                </b-col>
                <b-col cols="12" sm="6" md="3" class="mb-1">
                  <b-form-checkbox
                    v-model="selectedAmenities"
                    :disabled="!editMode"
                    value="SAFETY_BOX"
                  >
                    Caixa forta
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="onCancelButtonClicked">
            Cancel·la
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="onSaveButtonClicked">
            Guarda
          </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="onEditButtonClicked">
            Edita
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BTabs,
  BTab,
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import {
  getAmenityTextAndUnit,
  notifyError,
  notifySuccess,
} from "@/utils/methods";

export default {
  name: "AmenitiesCard",
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BTabs,
    BTab,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      editMode: false,
      plotSize: null,
      accommodationSize: null,
      gardenSize: null,
      terraceSize: null,
      poolWidth: null,
      poolLength: null,
      poolMinDepth: null,
      poolMaxDepth: null,
      selectedAmenities: [],
      allBedroomsACAmenity: null,
      someBedroomsAcAmenity: null,
      allBedroomsHeatingAmenity: null,
      someBedroomsHeatingAmenity: null,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    loadingAmenities() {
      return this.$store.getters["accommodation/loadingAmenities"];
    },
    amenities() {
      return this.$store.getters["accommodation/amenities"];
    },
    accommodationBedrooms() {
      return this.$store.getters["accommodation/bedrooms"];
    },
    bedroomsWithAC() {
      if (this.accommodationBedrooms.length === 0) return 0;
      return this.accommodationBedrooms.filter((b) => b.ac).length;
    },
    bedroomsWithHeating() {
      if (this.accommodationBedrooms.length === 0) return 0;
      return this.accommodationBedrooms.filter((b) => b.heating).length;
    },
    plotSizeAmenity() {
      return this.amenities.find((a) => a.amenity.code === "PLOT_SIZE");
    },
    plotSizeAmenityText() {
      const text = getAmenityTextAndUnit(this.plotSizeAmenity);
      return text || "No disponible";
    },
    accommodationSizeAmenity() {
      return this.amenities.find(
        (a) => a.amenity.code === "ACCOMMODATION_SIZE"
      );
    },
    accommodationSizeAmenityText() {
      const text = getAmenityTextAndUnit(this.accommodationSizeAmenity);
      return text || "No disponible";
    },
    gardenSizeAmenity() {
      return this.amenities.find((a) => a.amenity.code === "GARDEN_SIZE");
    },
    gardenSizeAmenityText() {
      const text = getAmenityTextAndUnit(this.gardenSizeAmenity);
      return text || "No disponible";
    },
    terraceSizeAmenity() {
      return this.amenities.find((a) => a.amenity.code === "TERRACE_SIZE");
    },
    terraceSizeAmenityText() {
      const text = getAmenityTextAndUnit(this.terraceSizeAmenity);
      return text || "No disponible";
    },
    poolLengthAmenity() {
      return this.amenities.find((a) => a.amenity.code === "POOL_LENGTH");
    },
    poolLengthAmenityText() {
      return getAmenityTextAndUnit(this.poolLengthAmenity);
    },
    poolWidthAmenity() {
      return this.amenities.find((a) => a.amenity.code === "POOL_WIDTH");
    },
    poolWidthAmenityText() {
      return getAmenityTextAndUnit(this.poolWidthAmenity);
    },
    poolSizeText() {
      if (!this.poolLengthAmenityText || !this.poolWidthAmenityText)
        return "No disponible";
      return `${this.poolLengthAmenityText} x ${this.poolWidthAmenityText}`;
    },
    poolMinDepthAmenity() {
      return this.amenities.find((a) => a.amenity.code === "POOL_MIN_DEPTH");
    },
    poolMinDepthAmenityText() {
      return getAmenityTextAndUnit(this.poolMinDepthAmenity);
    },
    poolMaxDepthAmenity() {
      return this.amenities.find((a) => a.amenity.code === "POOL_MAX_DEPTH");
    },
    poolMaxDepthAmenityText() {
      return getAmenityTextAndUnit(this.poolMaxDepthAmenity);
    },
    poolDepthText() {
      if (!this.poolMinDepthAmenityText || !this.poolMaxDepthAmenityText)
        return "No disponible";
      return `Min: ${this.poolMinDepthAmenityText} Max: ${this.poolMaxDepthAmenityText}`;
    },
    updatableAmenities() {
      const manualAmenities = [
        "PLOT_SIZE",
        "ACCOMMODATION_SIZE",
        "GARDEN_SIZE",
        "TERRACE_SIZE",
        "POOL_LENGTH",
        "POOL_WIDTH",
        "POOL_MIN_DEPTH",
        "POOL_MAX_DEPTH",
      ];

      const amenities = [];

      // Checkbox amenities
      this.selectedAmenities.forEach((amenity) => {
        if (!manualAmenities.includes(amenity)) {
          amenities.push({
            code: amenity,
            value: null,
            units: null,
          });
        }
      });

      // Sizes amenities
      amenities.push({
        code: "PLOT_SIZE",
        value: this.plotSize,
        units: "m2",
      });
      amenities.push({
        code: "ACCOMMODATION_SIZE",
        value: this.accommodationSize,
        units: "m2",
      });
      amenities.push({
        code: "GARDEN_SIZE",
        value: this.gardenSize,
        units: "m2",
      });
      amenities.push({
        code: "TERRACE_SIZE",
        value: this.terraceSize,
        units: "m2",
      });
      amenities.push({
        code: "POOL_LENGTH",
        value: this.poolLength,
        units: "m",
      });
      amenities.push({
        code: "POOL_WIDTH",
        value: this.poolWidth,
        units: "m",
      });
      amenities.push({
        code: "POOL_MIN_DEPTH",
        value: this.poolMinDepth,
        units: "cm",
      });
      amenities.push({
        code: "POOL_MAX_DEPTH",
        value: this.poolMaxDepth,
        units: "cm",
      });

      return amenities;
    },
  },
  watch: {
    accommodationBedrooms: {
      deep: true,
      handler() {
        this.setBedroomsACAmenities();
        this.setBedroomsHeatingAmenities();
      },
    },
    amenities: {
      deep: true,
      handler() {
        this.amenities.forEach((a) => {
          this.addAmenityToList(a.amenity.code);
        });
      },
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    initInputs() {
      this.plotSize = this.plotSizeAmenity?.value || null;
      this.accommodationSize = this.accommodationSizeAmenity?.value || null;
      this.gardenSize = this.gardenSizeAmenity?.value || null;
      this.terraceSize = this.terraceSizeAmenity?.value || null;
      this.poolWidth = this.poolWidthAmenity?.value || null;
      this.poolLength = this.poolLengthAmenity?.value || null;
      this.poolMinDepth = this.poolMinDepthAmenity?.value || null;
      this.poolMaxDepth = this.poolMaxDepthAmenity?.value || null;

      if (this.accommodationBedrooms.length > 0) {
        this.setBedroomsACAmenities();
        this.setBedroomsHeatingAmenities();
      }

      if (this.amenities.length > 0) {
        this.amenities.forEach((a) => {
          this.addAmenityToList(a.amenity.code);
        });
      }
    },
    addAmenityToList(amenityCode) {
      const foundAmenity = this.selectedAmenities.find(
        (a) => a === amenityCode
      );
      if (!foundAmenity) {
        this.selectedAmenities.push(amenityCode);
      }
    },
    removeAmenityFromList(amenityCode) {
      const amenityIndex = this.selectedAmenities.findIndex(
        (a) => a === amenityCode
      );
      if (amenityIndex !== -1) {
        this.selectedAmenities.splice(amenityIndex, 1);
      }
    },
    setBedroomsACAmenities() {
      this.allBedroomsACAmenity =
        this.bedroomsWithAC === this.accommodationBedrooms.length;
      if (this.allBedroomsACAmenity) {
        this.addAmenityToList("AIR_CONDITIONING_ALL_BEDROOMS");
      } else {
        this.removeAmenityFromList("AIR_CONDITIONING_ALL_BEDROOMS");
      }
      this.someBedroomsAcAmenity =
        this.bedroomsWithAC > 0 &&
        this.bedroomsWithAC < this.accommodationBedrooms.length;
      if (this.someBedroomsAcAmenity) {
        this.addAmenityToList("AIR_CONDITIONING_SOME_BEDROOMS");
      } else {
        this.removeAmenityFromList("AIR_CONDITIONING_SOME_BEDROOMS");
      }
    },
    setBedroomsHeatingAmenities() {
      this.allBedroomsHeatingAmenity =
        this.bedroomsWithHeating === this.accommodationBedrooms.length;
      if (this.allBedroomsHeatingAmenity) {
        this.addAmenityToList("HEATING_ALL_BEDROOMS");
      } else {
        this.removeAmenityFromList("HEATING_ALL_BEDROOMS");
      }
      this.someBedroomsHeatingAmenity =
        this.bedroomsWithHeating > 0 &&
        this.bedroomsWithHeating < this.accommodationBedrooms.length;
      if (this.someBedroomsHeatingAmenity) {
        this.addAmenityToList("HEATING_SOME_BEDROOMS");
      } else {
        this.removeAmenityFromList("HEATING_SOME_BEDROOMS");
      }
    },
    onEditButtonClicked() {
      this.editMode = true;
    },
    onCancelButtonClicked() {
      this.editMode = false;
      this.initInputs();
    },
    onSaveButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("accommodation/updateAccommodationAmenities", {
          accommodationUuid: this.accommodation.uuid,
          amenities: this.updatableAmenities,
        })
        .then(async () => {
          await this.$store.dispatch(
            "accommodation/fetchAccommodationAmenities",
            this.accommodation.uuid
          );
          notifySuccess(
            "Allotjament actualitzat",
            "L'allotjament s'ha actualitzat correctament"
          );
          this.initInputs();
          this.editMode = false;
        })
        .catch(() => {
          notifyError(
            "Allotjament no actualitzat",
            "L'allotjament no s'ha pogut actualitzar correctament, si us plau, revisa les dades introduïdes"
          );
          // TODO: log error in Sentry
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
.equipment-amenities {
  .custom-control-label {
    color: #5e5873 !important;
  }
}
</style>
