<template>
  <b-overlay :show="loadingServices" rounded="sm" variant="white" opacity="0.9">
    <b-card id="services-card">
      <!-- SERVICES TABLE -->
      <accommodation-services-table
        :services="mappedServices"
        :loading="loadingServices"
        show-button
        show-search
        @button-click="onAddServiceClicked"
        @edit-service-click="onEditServiceClicked"
      />

      <!-- ADD SERVICE MODAL -->
      <b-modal
        :id="'add-accommodation-service-modal'"
        title="Afegeix un servei"
        scrollable
        hide-footer
      >
        <accommodation-service-form
          @cancel="onAddServiceCancel"
          @service-added="onServiceAdded"
          @add-service-error="onAddServiceError"
        />
      </b-modal>

      <!-- EDIT SERVICE MODAL -->
      <b-modal
        :id="'edit-service-modal'"
        title="Edita el servei"
        scrollable
        hide-footer
      >
        <accommodation-service-form
          :accommodation-service="selectedService"
          @cancel="onEditServiceCancel"
          @service-edited="onServiceEdited"
          @edit-service-error="onEditServiceError"
          @service-deleted="onServiceDeleted"
          @delete-service-error="onDeleteServiceError"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard } from "bootstrap-vue";
import AccommodationServicesTable from "@/views/accommodations/accommodation/components/AccommodationServicesTable.vue";
import AccommodationServiceForm from "@/views/accommodations/accommodation/components/AccommodationServiceForm.vue";
import {
  notifyError,
  notifySuccess,
  getPaymentFrequencyName,
  getPaymentTimeName,
  getPaymentMethodName,
  getServiceName,
} from "@/utils/methods";
import { formatCurrency } from "@/utils/formatters";

export default {
  components: {
    BOverlay,
    BCard,
    AccommodationServicesTable,
    AccommodationServiceForm,
  },
  data() {
    return {
      selectedService: null,
    };
  },
  computed: {
    loadingServices() {
      return this.$store.getters["accommodation/loadingServices"];
    },
    accommodationServices() {
      return this.$store.getters["accommodation/services"];
    },
    mappedServices() {
      if (this.accommodationServices.length > 0) {
        return this.accommodationServices.map((accommodationService) => ({
          uuid: accommodationService.uuid || this.$t("No definit"),
          name:
            getServiceName(accommodationService.service, this.$i18n.locale) ||
            accommodationService.service.code,
          mandatory: accommodationService.mandatory ? "Sí" : "No",
          price:
            formatCurrency(accommodationService.price) || this.$t("No definit"),
          costPrice:
            formatCurrency(accommodationService.costPrice) ||
            this.$t("No definit"),
          paymentFrequency:
            getPaymentFrequencyName(accommodationService.paymentFrequency) ||
            this.$t("No definit"),
          paymentTime:
            getPaymentTimeName(accommodationService.paymentTime) ||
            this.$t("No definit"),
          paymentMethods:
            this.getPaymentMethodsText(accommodationService.paymentMethods) ||
            this.$t("No definit"),
          amount: accommodationService.amount || this.$t("No definit"),
        }));
      }
      return [];
    },
  },
  methods: {
    getPaymentMethodsText(paymentMethods) {
      if (!paymentMethods || paymentMethods.length === 0) return null;
      const text = [];
      paymentMethods.forEach((pm) => {
        if (pm) text.push(getPaymentMethodName(pm));
      });
      return text.join(", ");
    },
    fetchServices() {
      this.$store.dispatch("app/setLoading", true);

      const { accommodationUuid } = this.$route.params;

      this.$store
        .dispatch("accommodation/fetchServices", accommodationUuid)
        .catch(() => {
          // TODO: log error to Sentry
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onAddServiceClicked() {
      this.$bvModal.show("add-accommodation-service-modal");
    },
    onAddServiceCancel() {
      this.$bvModal.hide("add-accommodation-service-modal");
    },
    onServiceAdded() {
      this.fetchServices();
      this.$bvModal.hide("add-accommodation-service-modal");
      notifySuccess("Servei afegit", "El servei ha estat afegit correctament");
    },
    onAddServiceError() {
      notifyError("Error", "Hi ha hagut un error al intentar afegir el servei");
    },
    onEditServiceCancel() {
      this.$bvModal.hide("edit-service-modal");
    },
    onServiceEdited() {
      this.fetchServices();
      this.$bvModal.hide("edit-service-modal");
      notifySuccess("Servei editat", "El servei ha estat editat correctament");
    },
    onEditServiceError() {
      notifyError("Error", "Hi ha hagut un error al intentar editar el servei");
    },
    onServiceDeleted() {
      this.fetchServices();
      notifySuccess(
        "Servei eliminat",
        "El servei ha estat eliminat correctament"
      );
    },
    onDeleteServiceError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar eliminar el servei"
      );
    },
    onEditServiceClicked(serviceUuid) {
      this.selectedService = this.accommodationServices.find(
        (s) => s.uuid === serviceUuid
      );
      this.$bvModal.show("edit-service-modal");
    },
  },
};
</script>
