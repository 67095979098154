<template>
  <b-overlay :show="false" rounded="sm" variant="white">
    <b-card>
      <b-form>
        <b-card-title> Notificacions per WhatsApp </b-card-title>
        <b-row class="mb-2">
          <b-col cols="12" sm="6" md="3" xl="3" class="mb-2">
            <h6 class="mb-75">Nova reserva</h6>
            <b-form-checkbox
              v-model="editedDetails.whatsAppNewBookingNotification"
              switch
              @input="save"
            />
          </b-col>
          <b-col cols="12" sm="6" md="3" xl="3" class="mb-2">
            <h6 class="mb-75">Recordatori d'arribada</h6>
            <b-form-checkbox
              v-model="editedDetails.whatsAppCheckinReminderNotification"
              switch
              @input="save"
            />
          </b-col>
        </b-row>
        <b-card-title> Notificacions per E-mail </b-card-title>
        <b-row class="mb-2">
          <b-col cols="12" sm="6" md="3" xl="3" class="mb-2">
            <h6 class="mb-75">Nova reserva</h6>
            <b-form-checkbox
              v-model="editedDetails.emailNewBookingNotification"
              switch
              @input="save"
            />
          </b-col>
          <b-col cols="12" sm="6" md="3" xl="3" class="mb-2">
            <h6 class="mb-75">Recordatori d'arribada</h6>
            <b-form-checkbox
              v-model="editedDetails.emailCheckinReminderNotification"
              switch
              @input="save"
            />
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BForm,
  BFormCheckbox,
} from "bootstrap-vue";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BForm,
    BFormCheckbox,
  },
  data() {
    return {
      editMode: false,
      editedDetails: {
        whatsAppNewBookingNotification: false,
        whatsAppCheckinReminderNotification: false,
        emailNewBookingNotification: false,
        emailCheckinReminderNotification: false,
      },
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    details() {
      return this.accommodation?.details || null;
    },
    whatsAppNewBookingNotification() {
      return this.details?.whatsAppNewBookingNotification || false;
    },
    whatsAppCheckinReminderNotification() {
      return this.details?.whatsAppCheckinReminderNotification || false;
    },
    emailNewBookingNotification() {
      return this.details?.emailNewBookingNotification || false;
    },
    emailCheckinReminderNotification() {
      return this.details?.emailCheckinReminderNotification || false;
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    initInputs() {
      this.editedDetails = {
        whatsAppNewBookingNotification:
          this.details?.whatsAppNewBookingNotification || false,
        whatsAppCheckinReminderNotification:
          this.details?.whatsAppCheckinReminderNotification || false,
        emailNewBookingNotification:
          this.details?.emailNewBookingNotification || false,
        emailCheckinReminderNotification:
          this.details?.emailCheckinReminderNotification || false,
      };
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      const action = this.details
        ? "updateAccommodationDetails"
        : "createAccommodationDetails";

      this.$store
        .dispatch(`accommodation/${action}`, {
          uuid: this.details?.uuid,
          accommodation: this.accommodation["@id"],
          ...this.editedDetails,
        })
        .then(() => {
          notifySuccess(
            "Notifications actualitzades",
            "Les notificacions de l'allotjament s'ha actualitzat correctament"
          );
          this.editMode = false;
        })
        .catch(() =>
          notifyError(
            "Notifications no actualitzades",
            "Les notificacions de l'allotjament no s'han pogut actualitzar correctament, si us plau, intenta-ho de nou"
          )
        )
        .finally(() => {
          this.initInputs();
          this.$store.dispatch("app/setLoading", false);
        });
    },
  },
};
</script>
