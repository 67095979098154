<template>
  <b-overlay :show="loadingPicturesOrVideos" rounded="sm" variant="white">
    <b-card id="multimedia-card" title="Multimèdia">
      <b-overlay :show="!city" rounded="sm" variant="white" :opacity="1">
        <!-- TITLE AND ACTIONS -->
        <b-row>
          <b-col cols="12" class="mb-1">
            <h5>
              <span>
                <feather-icon icon="ImageIcon" /> Fotos ({{ pictures.length }})
                <template v-if="!editMode && pictures.length > 0">
                  <b-link
                    v-if="allPicturesSelected"
                    class="ml-1"
                    @click="selectAllPictures(false)"
                  >
                    <small> Esborra la selecció </small>
                  </b-link>
                  <b-link v-else class="ml-1" @click="selectAllPictures(true)">
                    <small> Selecciona-les totes </small>
                  </b-link>
                  <b-link
                    v-if="selectedPictures.length > 0"
                    class="ml-1"
                    @click="askDeleteSelectedPictures()"
                  >
                    <small> Elimina ({{ selectedPictures.length }}) </small>
                  </b-link>
                </template>
              </span>
            </h5>
          </b-col>
        </b-row>

        <!-- PHOTOS GRID -->
        <b-row v-if="pictures.length > 0" class="mb-2">
          <draggable
            v-model="draggedPictures"
            tag="div"
            class="d-flex flex-wrap w-100"
            group="pictures"
            draggable=".draggable-picture"
            :scroll-sensitivity="250"
            :scroll-speed="20"
            :force-fallback="true"
          >
            <!-- DRAGGABLE PHOTOS -->
            <b-col
              v-for="(picture, pictureIndex) in draggedPictures"
              :key="`draggable-picture-${picture.uuid}`"
              cols="6"
              sm="6"
              md="4"
              lg="3"
              xl="2"
              class="cursor-pointer"
              :class="{ 'draggable-picture': editMode }"
              @click="onPictureClicked(picture)"
            >
              <b-card
                :img-src="picture.thumbnailUrl"
                img-top
                img-alt="Accommodation image"
                :style="{ opacity: !picture.active ? 0.5 : 1 }"
                :class="{ shake: editMode }"
                no-body
              >
                <template v-if="!editMode" #footer>
                  <b-form-checkbox
                    v-model="selectedPictures"
                    class="picture-checkbox"
                    :value="picture.uuid"
                  />
                  <b-row
                    class="d-flex justify-content-center justify-content-md-between"
                  >
                    <b-col cols="12" sm="4" class="mb-1 mb-sm-0">
                      <b-button
                        block
                        variant="danger"
                        size="sm"
                        class="mr-1 picture-action-button"
                        @click.stop="deletePictureButtonClicked(picture)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </b-col>
                    <b-col cols="12" sm="4">
                      <b-button
                        block
                        size="sm"
                        class="picture-action-button"
                        @click.stop="togglePictureStatus(picture)"
                      >
                        <feather-icon icon="PowerIcon" />
                      </b-button>
                    </b-col>
                    <b-col cols="12" sm="4">
                      <b-button
                        variant="primary"
                        block
                        size="sm"
                        class="picture-action-button"
                        @click.stop="showLightbox(pictureIndex)"
                      >
                        <feather-icon icon="ZoomInIcon" />
                      </b-button>
                    </b-col>
                  </b-row>
                </template>
              </b-card>
            </b-col>
          </draggable>

          <!-- LIGHTBOX -->
          <vue-easy-lightbox
            :visible="lightboxVisible"
            :imgs="draggedPicturesUrls"
            :index="lightboxIndex"
            @hide="hideLightbox"
          />
        </b-row>

        <!-- NO PHOTOS ALERT -->
        <b-alert class="mb-2" variant="primary" :show="pictures.length === 0">
          <div class="alert-body">Encara no s'ha afegit cap foto</div>
        </b-alert>

        <!-- BUTTONS -->
        <b-row class="d-flex justify-content-end">
          <template v-if="!editMode">
            <b-col cols="12" sm="auto" class="mb-1 mb-sm-0">
              <b-button block variant="primary" @click="editMode = true">
                Edita ordre
              </b-button>
            </b-col>
            <b-col cols="12" sm="auto">
              <b-button
                v-b-modal.add-pictures-modal
                block
                variant="primary"
                @click="editMode = false"
              >
                Puja fotos
              </b-button>
            </b-col>
          </template>
          <template v-if="editMode">
            <b-col cols="12" sm="auto">
              <b-button block variant="light" @click="onCancelPicturesOrder">
                Cancel·la
              </b-button>
            </b-col>
            <b-col cols="12" sm="auto">
              <b-button block variant="primary" @click="onSavePicturesOrder">
                Guarda
              </b-button>
            </b-col>
          </template>
        </b-row>

        <!-- ADD PHOTOS MODAL -->
        <b-modal
          id="add-pictures-modal"
          title="Carrega fotos"
          centered
          size="lg"
          hide-footer
          @hidden="resetFileInput"
        >
          <b-row
            v-if="previewPictures && previewPictures.length > 0"
            class="d-flex flex-wrap mb-2"
          >
            <b-col
              v-for="(picture, index) in previewPictures"
              :key="`preview-picture-${index}`"
              cols="12"
              md="6"
              lg="3"
            >
              <b-card
                :img-src="picture"
                img-top
                img-alt="Accommodation image"
                class="mb-1"
                no-body
              >
                <template #footer>
                  <div class="d-flex justify-content-center">
                    <b-button
                      variant="danger"
                      size="sm"
                      @click.stop="deletePreviewImage(index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                </template>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="d-flex flex-wrap mb-2">
            <b-col cols="12" sm="6">
              <b-form-file
                v-if="previewPictures"
                id="wildcard"
                accept="image/*"
                multiple
                @change="handleImages($event)"
              />
            </b-col>
          </b-row>
          <!-- BUTTONS -->
          <b-row
            class="mb-1 d-flex justify-content-between justify-content-sm-end"
          >
            <b-col cols="6" sm="auto">
              <b-button block variant="light" @click="closeAddPicturesModal">
                Cancel·la
              </b-button>
            </b-col>
            <b-col cols="6" sm="auto">
              <b-button
                block
                variant="primary"
                :disabled="previewPictures.length === 0"
                @click="uploadPictures"
              >
                Puja
              </b-button>
            </b-col>
          </b-row>
        </b-modal>

        <!-- OVERLAY CONTENT -->
        <template #overlay>
          <div class="text-center">
            <p class="font-weight-bold">
              Abans de poder carregar fotos, completa la ubicació de
              l'allotjament
            </p>
          </div>
        </template>
      </b-overlay>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BFormFile,
  BLink,
  BModal,
  BOverlay,
  BAlert,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import { notifySuccess, notifyError } from "@/utils/methods";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  name: "MultimediaCard",
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormFile,
    BLink,
    BModal,
    BOverlay,
    BAlert,
    draggable,
    VueEasyLightbox,
  },
  data() {
    return {
      previewPictures: [],
      draggedPictures: [],
      selectedPicture: null,
      selectedPictures: [],
      editMode: false,
      lightboxVisible: false,
      lightboxIndex: 0,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    loadingPicturesOrVideos() {
      return this.$store.getters["accommodation/loadingPicturesOrVideos"];
    },
    pictures() {
      return this.$store.getters["accommodation/pictures"];
    },
    allPicturesSelected() {
      return (
        this.pictures.length > 0 &&
        this.pictures.length === this.selectedPictures.length
      );
    },
    location() {
      return this.accommodation ? this.accommodation.location : null;
    },
    city() {
      return this.location?.city;
    },
    draggedPicturesUrls() {
      if (!this.draggedPictures.length) return [];
      return this.draggedPictures.map((picture) => picture.url);
    },
  },
  watch: {
    pictures(pictures) {
      this.setDraggedPictures(pictures);
    },
  },
  mounted() {
    this.setDraggedPictures(this.pictures);
  },
  methods: {
    setDraggedPictures(pictures) {
      this.draggedPictures = [...pictures];
    },
    onPictureClicked(picture) {
      const pictureIndex = this.selectedPictures.findIndex(
        (p) => p === picture.uuid
      );
      if (pictureIndex !== -1) {
        this.selectedPictures.splice(pictureIndex, 1);
      } else {
        this.selectedPictures.push(picture.uuid);
      }
    },
    selectAllPictures(select) {
      this.selectedPictures = [];
      if (select)
        this.pictures.forEach((p) => this.selectedPictures.push(p.uuid));
    },
    askDeleteSelectedPictures() {
      this.$swal({
        title: "Eliminar fotos",
        text: `Estàs a punt d'eliminar les ${this.selectedPictures.length} fotos seleccionades, estàs segur/a?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteSelectedPictures();
        }
      });
    },
    deleteSelectedPictures() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch(
          "accommodation/deleteAccommodationPictures",
          this.selectedPictures
        )
        .then(() =>
          notifySuccess(
            "Fotos eliminades",
            "Les fotos seleccionades han estat eliminades correctament."
          )
        )
        .catch(() =>
          notifyError(
            "Fotos no eliminades",
            "Hi ha hagut un error al eliminar les fotos seleccionades."
          )
        )
        .finally(() => {
          this.$store.dispatch("app/setLoading", false);
          this.selectedPictures = [];
        });
    },
    deletePictureButtonClicked(picture) {
      this.selectedPicture = picture;
      this.confirmDeletePicture();
    },
    confirmDeletePicture() {
      this.$swal({
        title: "Eliminar foto",
        text: "Estàs a punt d'eliminar aquesta foto, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deletePicture();
        }
      });
    },
    deletePicture() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch(
          "accommodation/deleteAccommodationPicture",
          this.selectedPicture.uuid
        )
        .then(() =>
          notifySuccess(
            "Foto eliminada",
            "La foto ha estat eliminada correctament."
          )
        )
        .catch(() =>
          notifyError(
            "Foto no eliminada",
            "Hi ha hagut un error al eliminar la foto."
          )
        )
        .finally(() => {
          this.$store.dispatch("app/setLoading", false);
        });
    },
    togglePictureStatus(picture) {
      this.$store
        .dispatch("accommodation/updateAccommodationPictureStatus", {
          pictureUuid: picture.uuid,
          status: !picture.active,
        })
        .catch(() =>
          notifyError(
            "Foto no actualitzada",
            "Hi ha hagut un error al intentar actualitzar la foto."
          )
        );
    },
    handleImages(event) {
      this.previewPictures = [];
      event.target.files.forEach((file) => {
        this.createBase64Image(file);
      }, this);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.previewPictures.push(event.target.result);
      };
      reader.readAsDataURL(fileObject);
    },
    deletePreviewImage(index) {
      this.previewPictures.splice(index, 1);
    },
    uploadPictures() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("accommodation/addAccommodationPictures", {
          accommodationUuid: this.accommodation.uuid,
          pictures: this.previewPictures,
        })
        .finally(() => {
          this.$store.dispatch("app/setLoading", false);
          this.$bvModal.hide("add-pictures-modal");
          this.previewPictures = [];
        });
    },
    resetFileInput() {
      this.previewPictures = false;
      this.$nextTick(() => {
        this.previewPictures = [];
      });
    },
    closeAddPicturesModal() {
      this.$bvModal.hide("add-pictures-modal");
      this.resetFileInput();
    },
    onCancelPicturesOrder() {
      this.editMode = false;
      this.setDraggedPictures(this.pictures);
    },
    onSavePicturesOrder() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch(
          "accommodation/updateAccommodationPicturesOrder",
          this.draggedPictures
        )
        .then(() => {
          this.editMode = false;
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    showLightbox(index) {
      this.lightboxIndex = index;
      this.lightboxVisible = true;
    },
    hideLightbox() {
      this.lightboxVisible = false;
      this.lightboxIndex = 0;
    },
  },
};
</script>

<style lang="scss">
#multimedia-card {
  .picture-checkbox {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  .picture-action-button {
    opacity: 1 !important;
  }

  @keyframes shake {
    from {
      -webkit-transform: rotate(0.5deg);
    }

    to {
      -webkit-transform-origin: center center;
      -webkit-transform: rotate(-0.5deg);
    }
  }

  .shake {
    animation: shake 0.08s ease-in-out 0.08s infinite alternate;
  }
}
</style>
