<template>
  <div id="owner-rates-form" class="d-flex flex-column justify-content-between">
    <div>
      <!-- FORM -->
      <b-row class="mb-2">
        <b-col cols="12" class="mb-25">
          <h6>Dates</h6>
        </b-col>

        <!-- START DATE -->
        <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
          <b-form-datepicker
            v-model="newRate.startDate"
            placeholder="Inici"
            :locale="locale"
            :start-weekday="1"
            :show-decade-nav="false"
            :date-format-options="dateFormat"
            hide-header
          />
        </b-col>

        <!-- END DATE -->
        <b-col cols="12" sm="6">
          <b-form-datepicker
            v-model="newRate.endDate"
            placeholder="Fi"
            :min="newRate.startDate"
            :locale="locale"
            :start-weekday="1"
            :show-decade-nav="false"
            :date-format-options="dateFormat"
            hide-header
          />
        </b-col>

        <b-col cols="12" class="mb-25 mt-2">
          <h6>Preu propietari <small>(per nit)</small></h6>
        </b-col>

        <!-- OWNER RATE -->
        <b-col cols="12" class="mb-2">
          <b-input-group class="input-group-merge" append="€">
            <b-form-input v-model="newRate.ownerPrice" type="number" />
          </b-input-group>
        </b-col>

        <!-- ADD BUTTON -->
        <b-col cols="12" class="text-right">
          <b-button
            variant="primary"
            size="sm"
            :disabled="addRateButtonDisabled"
            @click="addRate"
          >
            Afegir
          </b-button>
        </b-col>
      </b-row>

      <!-- RATES LIST -->
      <b-row v-show="ownerRates.length" class="mb-2">
        <b-col cols="12" class="mb-25">
          <h6>Llistat de tarifes</h6>
        </b-col>
        <b-col cols="12">
          <b-table
            responsive="sm"
            :items="ownerRates"
            :fields="ownerRatesFields"
            ref="ownerRatesTable"
          >
            <template #cell(delete)="data">
              <feather-icon
                icon="TrashIcon"
                class="text-danger cursor-pointer"
                @click="deleteRate(data.index)"
              />
            </template>
            <template #cell(overlaps)="data">
              {{ data.value ? "Sí" : "No" }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()" :disabled="!canSave">
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormDatepicker,
  BButton,
  BInputGroup,
  BFormInput,
  BTable,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BFormDatepicker,
    BInputGroup,
    BFormInput,
    BButton,
    BTable,
  },
  data() {
    return {
      newRate: {
        startDate: null,
        endDate: null,
        ownerPrice: null,
        overlaps: false,
      },
      dateFormat: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      ownerRates: [],
      ownerRatesFields: [
        {
          key: "delete",
          label: "",
        },
        {
          key: "startDate",
          label: "Data inici",
        },
        {
          key: "endDate",
          label: "Data fi",
        },
        {
          key: "ownerPrice",
          label: "Preu propietari",
        },
      ],
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    locale() {
      return this.$i18n.locale;
    },
    addRateButtonDisabled() {
      const { startDate, endDate, ownerPrice } = this.newRate || {};
      return !startDate || !endDate || !ownerPrice;
    },
    canSave() {
      return (
        this.ownerRates?.length &&
        this.ownerRates.every((ownerRate) => !ownerRate._rowVariant)
      );
    },
  },
  watch: {
    "newRate.startDate"(startDate) {
      if (
        startDate &&
        (!this.newRate.endDate || this.newRate.endDate < startDate)
      ) {
        this.newRate.endDate = this.$moment(startDate)
          .endOf("month")
          .format("YYYY-MM-DD");
      }
    },
  },
  created() {
    this.resetForm();
  },
  methods: {
    resetForm() {
      this.newRate = {
        startDate: null,
        endDate: null,
        ownerPrice: null,
        _rowVariant: null,
      };
    },
    updateForm() {
      const lastEndDate = this.$moment(this.newRate.endDate);
      this.resetForm();
      this.newRate.startDate = lastEndDate.add(1, "day").format("YYYY-MM-DD");
    },
    addRate() {
      this.ownerRates.push(this.newRate);

      // Sort rates by startDate
      this.ownerRates.sort((a, b) => {
        return new Date(a.startDate) - new Date(b.startDate);
      });

      // Update row variants
      this.updateOverlapping();

      // Update form with next dates
      this.updateForm();
    },
    deleteRate(index) {
      this.ownerRates.splice(index, 1);

      // Update row variants
      this.updateOverlapping();
    },
    resetOverlapping() {
      for (let i = 0; i < this.ownerRates.length; i++) {
        this.ownerRates[i]._rowVariant = null;
      }
    },
    updateOverlapping() {
      // Reset row variants
      this.resetOverlapping();

      // Update row variants
      for (let i = 0; i < this.ownerRates.length; i++) {
        for (let j = 0; j < this.ownerRates.length; j++) {
          if (i !== j) {
            const rangeA = this.ownerRates[i];
            const rangeB = this.ownerRates[j];

            const overlaps =
              (rangeA.startDate <= rangeB.endDate &&
                rangeA.startDate >= rangeB.startDate) ||
              (rangeA.endDate >= rangeB.startDate &&
                rangeA.endDate <= rangeB.endDate);

            if (overlaps) {
              this.ownerRates[i]._rowVariant = "danger";
              this.ownerRates[j]._rowVariant = "danger";
            }
          }
        }
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      const promises = [];

      this.ownerRates.forEach((ownerRate) => {
        promises.push(
          this.$store.dispatch("accommodation/setAccommodationOwnerRates", {
            accommodation: this.accommodation["@id"],
            startDate: ownerRate.startDate,
            endDate: ownerRate.endDate,
            ownerPrice: Math.round(ownerRate.ownerPrice * 100),
          })
        );
      });

      Promise.all(promises)
        .then(() => this.$emit("owner-rates-added"))
        .catch(() => {
          this.$emit("add-owner-rates-error");
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style scoped>
#owner-rates-form {
  min-height: 430px;
}
</style>
