<template>
  <div class="bathroom-card">
    <b-card :title="title">
      <b-row class="mb-2">
        <b-col>
          <span>{{ bathroomSpecsText }}</span>
          <br />
          <small v-if="bathroom.ensuite">{{ ensuiteText }}</small>
          <small v-else>Independent</small>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-end">
        <b-col cols="6" sm="auto">
          <b-button
            v-b-tooltip.hover.top="
              bathroom.ensuite
                ? 'Per poder eliminar el bany, primer cal llevar-lo de l\'habitació'
                : ''
            "
            variant="danger"
            block
            @click="onDeleteBathroomClicked"
          >
            Elimina
          </b-button>
        </b-col>
        <b-col cols="6" sm="auto">
          <b-button
            v-b-modal="`bathroom-modal-${bathroom.uuid}`"
            block
            variant="primary"
          >
            Edita
          </b-button>
        </b-col>
      </b-row>

      <!-- BATHROOM MODAL -->
      <b-modal
        :id="`bathroom-modal-${bathroom.uuid}`"
        title="Edita el bany"
        size="lg"
        scrollable
        hide-footer
      >
        <bathroom-form
          :bathroom="bathroom"
          @bathroom-edited="hideBathroomModal(bathroom)"
          @edit-bathroom-error="hideBathroomModal(bathroom)"
          @cancel="hideBathroomModal(bathroom)"
        />
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";
import BathroomForm from "@/views/accommodations/accommodation/components/BathroomForm.vue";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  name: "BathroomCard",
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BathroomForm,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    bathroom: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      popupOpen: false,
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    bedrooms() {
      return this.$store.getters["accommodation/bedrooms"];
    },
    ensuiteText() {
      if (!this.bathroom.ensuite) return null;

      let bedroomIndex = null;

      if (this.bedrooms.length > 0) {
        this.bedrooms.forEach((bedroom, index) => {
          if (!bedroomIndex && bedroom.bathroom === this.bathroom["@id"]) {
            bedroomIndex = index + 1;
          }
        });
      }
      return bedroomIndex ? `En suite a dormitori ${bedroomIndex}` : null;
    },
    bathroomSpecsText() {
      const bathroomSpecs = [];
      if (this.bathroom.toilets > 0) {
        bathroomSpecs.push("WC");
      }
      if (this.bathroom.bathtubs > 0) {
        bathroomSpecs.push("banyera");
      }
      if (this.bathroom.showers > 0) {
        bathroomSpecs.push("dutxa");
      }
      return bathroomSpecs.length > 0 ? bathroomSpecs.join(", ") : "No definit";
    },
  },
  methods: {
    onDeleteBathroomClicked() {
      if (!this.bathroom.ensuite) this.confirmDeleteBathroom();
    },
    confirmDeleteBathroom() {
      this.$swal({
        title: "Eliminar bany",
        text: "Estàs a punt d'eliminar aquest bany, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteBathroom();
      });
    },
    deleteBathroom() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch(
          "accommodation/deleteAccommodationBathroom",
          this.bathroom.uuid
        )
        .then((result) => {
          this.$emit("bathroom-deleted", result);
          this.$store
            .dispatch(
              "accommodation/fetchAccommodationBedrooms",
              this.accommodation.uuid
            )
            .finally(() => {
              this.$store.dispatch("app/setLoading", false);
              notifySuccess(
                "Bany eliminat",
                "El bany ha estat eliminat correctament."
              );
            });
        })
        .catch((error) => {
          this.$emit("bathroom-delete-error", error);
          notifyError(
            "Bany no eliminat",
            "Hi ha hagut un error al eliminar el bany."
          );
          this.$store.dispatch("app/setLoading", false);
          // TODO: log error in Sentry
        });
    },
    hideBathroomModal(bathroom) {
      this.$bvModal.hide(`bathroom-modal-${bathroom.uuid}`);
    },
  },
};
</script>

<style lang="scss">
.bathroom-card {
  .card {
    margin-bottom: 0;
    height: 100%;
  }
}
</style>
