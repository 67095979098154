<template>
  <b-overlay :show="false" rounded="sm" variant="white">
    <b-card>
      <validation-observer ref="accountingFormRules">
        <b-form>
          <b-card-title> Configuració bancària </b-card-title>
          <b-row class="mb-2">
            <b-col cols="12" sm="6" md="4" xl="4" class="mb-2">
              <h6 class="mb-75">IBAN</h6>
              <validation-provider
                v-if="editMode"
                #default="{ errors }"
                :name="$t('IBAN')"
                rules="required"
              >
                <b-form-input
                  v-model="editedDetails.iban"
                  :formatter="formatIbanNumber"
                  placeholder="ESXX XXXX XXXX XXXX XXXX XXXX"
                  max="24"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <b-card-text v-else>
                {{ iban }}
              </b-card-text>
            </b-col>
          </b-row>

          <b-card-title> Configuració comptable </b-card-title>
          <b-row class="mb-2">
            <b-col cols="12" sm="6" md="4" xl="4" class="mb-2">
              <h6 class="mb-75">Compte de depòsits</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedDetails.depositLedgerAccount"
                :lazy-formatter="true"
                :formatter="formatLedgerAccount"
                placeholder="p. e. 4109.1"
              />
              <b-card-text v-else>
                {{ depositLedgerAccount }}
              </b-card-text>
            </b-col>
            <b-col cols="12" sm="6" md="4" xl="4" class="mb-2">
              <h6 class="mb-75">Compte de fiances</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedDetails.securityDepositLedgerAccount"
                :lazy-formatter="true"
                :formatter="formatLedgerAccount"
                placeholder="p. e. 560.1"
              />
              <b-card-text v-else>
                {{ securityDepositLedgerAccount }}
              </b-card-text>
            </b-col>
            <b-col cols="12" sm="6" md="4" xl="4" class="mb-2">
              <h6 class="mb-75">Compte d'impost turístics</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedDetails.touristTaxLedgerAccount"
                :lazy-formatter="true"
                :formatter="formatLedgerAccount"
                placeholder="p. e. 561.1"
              />
              <b-card-text v-else>
                {{ touristTaxLedgerAccount }}
              </b-card-text>
            </b-col>
            <b-col cols="12" sm="6" md="4" xl="4" class="mb-2">
              <h6 class="mb-75">Compte de serveis</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedDetails.servicesLedgerAccount"
                :lazy-formatter="true"
                :formatter="formatLedgerAccount"
                placeholder="p. e. 5612.1"
              />
              <b-card-text v-else>
                {{ servicesLedgerAccount }}
              </b-card-text>
            </b-col>
            <b-col cols="12" sm="6" md="4" xl="4" class="mb-2">
              <h6 class="mb-75">Compte de clients</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedDetails.clientLedgerAccount"
                :lazy-formatter="true"
                :formatter="formatLedgerAccount"
                placeholder="p. e. 430.1"
              />
              <b-card-text v-else>
                {{ clientLedgerAccount }}
              </b-card-text>
            </b-col>
            <b-col cols="12" sm="6" md="4" xl="4" class="mb-2">
              <h6 class="mb-75">Zona</h6>
              <b-form-input
                v-if="editMode"
                v-model="editedDetails.zoneLedgerAccount"
                :lazy-formatter="true"
                placeholder="p. e. 001"
                :max="4"
              />
              <b-card-text v-else>
                {{ zoneLedgerAccount }}
              </b-card-text>
            </b-col>
          </b-row>

          <!-- BUTTONS -->
          <b-row class="d-flex justify-content-between justify-content-sm-end">
            <b-col v-if="editMode" cols="6" sm="auto">
              <b-button block variant="light" @click="editMode = false">
                Cancel·la
              </b-button>
            </b-col>
            <b-col v-if="editMode" cols="6" sm="auto">
              <b-button block variant="primary" @click="onSaveButtonClicked">
                Guarda
              </b-button>
            </b-col>
            <b-col v-if="!editMode" cols="12" sm="auto">
              <b-button block variant="primary" @click="onEditButtonClicked">
                Edita
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BButton,
  BFormInput,
  BForm,
} from "bootstrap-vue";
import {
  formatLedgerAccount,
  formatIbanNumber,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardText,
    BButton,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      editMode: false,
      editedDetails: {
        iban: null,
        depositLedgerAccount: null,
        securityDepositLedgerAccount: null,
        touristTaxLedgerAccount: null,
        servicesLedgerAccount: null,
        clientLedgerAccount: null,
        zoneLedgerAccount: null,
      },
      formatLedgerAccount,
      formatIbanNumber,
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale.substring(0, 2).toLowerCase();
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    details() {
      return this.accommodation?.details || null;
    },
    iban() {
      return formatIbanNumber(this.details?.iban) || this.$t("No disponible");
    },
    depositLedgerAccount() {
      return this.details?.depositLedgerAccount || this.$t("No disponible");
    },
    securityDepositLedgerAccount() {
      return (
        this.details?.securityDepositLedgerAccount || this.$t("No disponible")
      );
    },
    touristTaxLedgerAccount() {
      return this.details?.touristTaxLedgerAccount || this.$t("No disponible");
    },
    servicesLedgerAccount() {
      return this.details?.servicesLedgerAccount || this.$t("No disponible");
    },
    clientLedgerAccount() {
      return this.details?.clientLedgerAccount || this.$t("No disponible");
    },
    zoneLedgerAccount() {
      return this.details?.zoneLedgerAccount || this.$t("No disponible");
    },
    formattedIban() {
      return this.editedDetails?.iban?.replace(/\s/g, "") || null;
    },
    emptyForm() {
      return (
        !this.editedDetails.iban &&
        !this.editedDetails.depositLedgerAccount &&
        !this.editedDetails.securityDepositLedgerAccount &&
        !this.editedDetails.touristTaxLedgerAccount &&
        !this.editedDetails.servicesLedgerAccount &&
        !this.editedDetails.clientLedgerAccount &&
        !this.editedDetails.zoneLedgerAccount
      );
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    switchValidationLocale() {
      localize(this.currentLanguage);

      if (!this.emptyForm) {
        this.validateFormRules();
      }
    },
    validateFormRules(silent = false) {
      return new Promise((resolve, reject) => {
        if (!this.$refs.accountingFormRules) {
          reject(new Error());
        }

        this.$refs.accountingFormRules
          .validate({ silent })
          .then((valid) => {
            resolve();
          })
          .catch(() => reject(new Error()));
      });
    },
    initInputs() {
      this.editedDetails = {
        iban: formatIbanNumber(this.details?.iban) || null,
        depositLedgerAccount: this.details?.depositLedgerAccount || null,
        securityDepositLedgerAccount:
          this.details?.securityDepositLedgerAccount || null,
        touristTaxLedgerAccount: this.details?.touristTaxLedgerAccount || null,
        servicesLedgerAccount: this.details?.servicesLedgerAccount || null,
        clientLedgerAccount: this.details?.clientLedgerAccount || null,
        zoneLedgerAccount: this.details?.zoneLedgerAccount || null,
      };
    },
    onEditButtonClicked() {
      this.initInputs();
      this.editMode = true;
    },
    onSaveButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      const action = this.details
        ? "updateAccommodationDetails"
        : "createAccommodationDetails";

      this.$store
        .dispatch(`accommodation/${action}`, {
          uuid: this.details?.uuid,
          accommodation: this.accommodation["@id"],
          ...this.editedDetails,
          iban: this.formattedIban,
        })
        .then(() => {
          notifySuccess(
            "Detalls actualitzats",
            "Els detalls de l'allotjament s'ha actualitzat correctament"
          );
          this.editMode = false;
        })
        .catch(() =>
          notifyError(
            "Detalls no actualitzats",
            "Els detalls de l'allotjament no s'ha pogut actualitzar correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
  watch: {
    currentLanguage() {
      // ! We use nextTick to allow vee-validate to update the validation messages
      // TODO: Working but improvement needed
      this.$nextTick(() => {
        this.switchValidationLocale();
      });
    },
  },
};
</script>
