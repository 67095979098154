<template>
  <div id="rate-form">
    <!-- DATES -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Dates</h6>
      </b-col>
      <!-- START DATE -->
      <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
        <b-form-datepicker
          v-model="startDate"
          placeholder="Inici"
          :locale="locale"
          :start-weekday="1"
          :show-decade-nav="false"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          hide-header
          @hidden="onStartDateDatepickerHidden"
        />
      </b-col>
      <!-- END DATE -->
      <b-col cols="12" sm="6">
        <b-form-datepicker
          ref="endDateDatepicker"
          v-model="endDate"
          placeholder="Fi"
          :min="startDate"
          :locale="locale"
          :start-weekday="1"
          :show-decade-nav="false"
          :date-format-options="{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }"
          hide-header
        />
      </b-col>
    </b-row>

    <!-- PVP & OWNER PRICE -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-2 mb-sm-0">
        <b-row>
          <b-col cols="12" class="mb-1">
            <h6>PVP</h6>
          </b-col>
          <b-col cols="12">
            <b-input-group class="input-group-merge" append="€">
              <b-form-input v-model="price" type="number" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="6" class="mb-2 mb-sm-0">
        <b-row>
          <b-col cols="12" class="mb-1">
            <h6>Propietari</h6>
          </b-col>
          <b-col cols="12">
            <b-input-group class="input-group-merge" append="€">
              <b-form-input v-model="ownerPrice" type="number" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- BOOKING POLICY -->
    <b-row>
      <b-col cols="12" sm="6">
        <b-row>
          <b-col cols="12" class="mb-1">
            <h6>Estada mínima</h6>
          </b-col>
          <b-col cols="12">
            <b-input-group class="input-group-merge" append="dies">
              <b-form-input v-model="minimumStay" type="number" />
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" sm="6">
        <b-row class="mb-2">
          <b-col cols="12" class="mb-1">
            <h6>Política de reserva</h6>
          </b-col>
          <b-col cols="12">
            <v-select
              v-model="bookingPolicy"
              :options="bookingPoliciesOptions"
              :reduce="(option) => option.value"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <!-- CHECKIN DAYS -->
    <b-row class="mb-2">
      <b-col cols="12" class="mb-1">
        <h6>Dies d'entrada</h6>
      </b-col>
      <b-col cols="12" class="d-flex flex-wrap">
        <b-form-checkbox
          v-model="checkinDays"
          class="mr-2 mb-2 mb-sm-0"
          :value="1"
        >
          Dl
        </b-form-checkbox>
        <b-form-checkbox
          v-model="checkinDays"
          class="mr-2 mb-2 mb-sm-0"
          :value="2"
        >
          Dm
        </b-form-checkbox>
        <b-form-checkbox
          v-model="checkinDays"
          class="mr-2 mb-2 mb-sm-0"
          :value="3"
        >
          Dx
        </b-form-checkbox>
        <b-form-checkbox
          v-model="checkinDays"
          class="mr-2 mb-2 mb-sm-0"
          :value="4"
        >
          Dj
        </b-form-checkbox>
        <b-form-checkbox
          v-model="checkinDays"
          class="mr-2 mb-2 mb-sm-0"
          :value="5"
        >
          Dv
        </b-form-checkbox>
        <b-form-checkbox
          v-model="checkinDays"
          class="mr-2 mb-2 mb-sm-0"
          :value="6"
        >
          Ds
        </b-form-checkbox>
        <b-form-checkbox v-model="checkinDays" class="mb-2 mb-sm-0" :value="0">
          Dg
        </b-form-checkbox>
      </b-col>
    </b-row>

    <!-- CHECKOUT DAYS -->
    <b-row class="mb-3">
      <b-col cols="12" class="mb-1">
        <h6>Dies de sortida</h6>
      </b-col>
      <b-col cols="12" class="d-flex flex-wrap">
        <b-form-checkbox
          v-model="checkoutDays"
          class="mr-2 mb-2 mb-sm-0"
          :value="1"
        >
          Dl
        </b-form-checkbox>
        <b-form-checkbox
          v-model="checkoutDays"
          class="mr-2 mb-2 mb-sm-0"
          :value="2"
        >
          Dm
        </b-form-checkbox>
        <b-form-checkbox
          v-model="checkoutDays"
          class="mr-2 mb-2 mb-sm-0"
          :value="3"
        >
          Dx
        </b-form-checkbox>
        <b-form-checkbox
          v-model="checkoutDays"
          class="mr-2 mb-2 mb-sm-0"
          :value="4"
        >
          Dj
        </b-form-checkbox>
        <b-form-checkbox
          v-model="checkoutDays"
          class="mr-2 mb-2 mb-sm-0"
          :value="5"
        >
          Dv
        </b-form-checkbox>
        <b-form-checkbox
          v-model="checkoutDays"
          class="mr-2 mb-2 mb-sm-0"
          :value="6"
        >
          Ds
        </b-form-checkbox>
        <b-form-checkbox v-model="checkoutDays" class="mb-2 mb-sm-0" :value="0">
          Dg
        </b-form-checkbox>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="mb-1 d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel()"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save()"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormDatepicker,
  BButton,
  BInputGroup,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BFormDatepicker,
    vSelect,
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      bookingPolicy: null,
      price: null,
      ownerPrice: null,
      minimumStay: null,
      checkinDays: [0, 1, 2, 3, 4, 5, 6],
      checkoutDays: [0, 1, 2, 3, 4, 5, 6],
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    locale() {
      return this.$i18n.locale;
    },
    bookingPoliciesOptions() {
      return this.$store.getters["bookingPolicies/policies"].map((policy) => ({
        label: policy.name,
        value: policy["@id"],
      }));
    },
  },
  watch: {
    startDate(startDate) {
      if (startDate && (!this.endDate || this.endDate < startDate)) {
        this.endDate = this.$moment(startDate)
          .endOf("month")
          .format("YYYY-MM-DD");
      }
    },
  },
  methods: {
    resetForm() {
      this.startDate = null;
      this.endDate = null;
      this.bookingPolicy = null;
      this.price = null;
      this.ownerPrice = null;
      this.minimumStay = null;
      this.checkinDays = [];
      this.checkoutDays = [];
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    save() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("accommodation/setAccommodationRates", {
            accommodation: this.accommodation["@id"],
            startDate: this.startDate,
            endDate: this.endDate,
            bookingPolicy: this.bookingPolicy,
            price: Math.round(this.price * 100),
            ownerPrice: Math.round(this.ownerPrice * 100),
            minimumStay: parseInt(this.minimumStay, 10),
            checkinDays: this.checkinDays,
            checkoutDays: this.checkoutDays,
          })
          .then(() => this.$emit("rates-added"))
          .catch(() => {
            this.$emit("add-rates-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
    // eslint-disable-next-line no-unused-vars
    onStartDateDatepickerHidden(event) {
      this.$refs.endDateDatepicker.isVisible = true;
    },
  },
};
</script>
