<template>
  <b-tab>
    <template #title>
      {{ language.toUpperCase() }}
    </template>
    <app-collapse v-if="faqs.length" accordion type="border">
      <app-collapse-item
        v-for="(faq, faqIndex) in faqs"
        :key="`${language}-faq-${faqIndex}`"
      >
        <template #header>
          <div>
            <b>{{ faq.title }}</b>
            <b-badge v-if="getScopeBadgeText(faq)" class="ml-1">
              {{ getScopeBadgeText(faq) }}
            </b-badge>
          </div>
        </template>

        <b-overlay :show="loading" variant="white" rounded="sm">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="faq.content" />

          <b-row
            v-if="faq.pictures && faq.pictures.length"
            class="d-flex flex-wrap mb-2"
          >
            <b-col
              v-for="(picture, pictureIndex) in faq.pictures"
              :key="`faq-${faqIndex}-picture-${pictureIndex}`"
              cols="6"
              md="4"
              lg="2"
            >
              <b-card
                overlay
                :img-src="picture.thumbnailUrl"
                img-alt="FAQ image"
                class="mb-1 cursor-pointer"
                @click="showLightbox(faq, pictureIndex)"
              >
                <div class="d-flex justify-content-end">
                  <b-button
                    size="sm"
                    variant="danger"
                    class="btn-icon ml-50 p-25"
                    @click.stop="askDeletePictureFaq(faq, picture)"
                  >
                    <feather-icon icon="Trash2Icon" size="13" />
                  </b-button>
                </div>
              </b-card>
            </b-col>
          </b-row>

          <b-row class="d-flex justify-content-end mt-2">
            <b-col cols="6" md="auto">
              <b-button block size="sm" variant="primary" @click="editFaq(faq)">
                Editar
              </b-button>
            </b-col>
            <b-col cols="6" md="auto">
              <b-button
                block
                size="sm"
                variant="danger"
                @click="askDeleteFaq(faq)"
              >
                Eliminar
              </b-button>
            </b-col>
          </b-row>
        </b-overlay>
      </app-collapse-item>
    </app-collapse>

    <vue-easy-lightbox
      v-if="lightboxPictures.length"
      :visible="lightboxVisible"
      :imgs="lightboxPictures"
      :index="lightboxIndex"
      @hide="hideLightbox"
    />

    <!-- EDIT FAQ MODAL -->
    <b-modal
      :id="`edit-faq-modal-${language}`"
      hide-footer
      centered
      title="Edita la FAQ"
    >
      <faq-form
        :faq="selectedFaq"
        @cancel="onEditFaqCancel"
        @edited="onFaqEdited"
        @edit-error="onEditFaqError"
      />
    </b-modal>
  </b-tab>
</template>

<script>
import {
  BOverlay,
  BTab,
  BRow,
  BCol,
  BButton,
  BCard,
  BBadge,
} from "bootstrap-vue";
import AppCollapse from "@foravila-core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@foravila-core/components/app-collapse/AppCollapseItem.vue";
import { notifyError, notifySuccess } from "@/utils/methods";
import FaqForm from "@/views/accommodations/accommodation/components/FaqForm.vue";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    BTab,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BCard,
    FaqForm,
    BBadge,
    VueEasyLightbox,
  },
  props: {
    language: {
      type: String,
      required: true,
    },
    faqs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      selectedFaq: null,
      lightboxPictures: [],
      lightboxVisible: false,
      lightboxIndex: 0,
    };
  },
  methods: {
    askDeleteFaq(faq) {
      this.$swal({
        title: "Eliminar FAQ",
        text: `Estàs a punt d'eliminar aquesta FAQ, estàs segur/a?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteFaq(faq);
        }
      });
    },
    deleteFaq(faq) {
      if (!faq?.uuid) return;
      this.loading = true;
      this.$store
        .dispatch("accommodation/deleteAccommodationFaq", faq.uuid)
        .then(() =>
          notifySuccess(
            "FAQ eliminada",
            "La FAQ ha estat eliminada correctament"
          )
        )
        .catch(() =>
          notifyError(
            "FAQ no eliminada",
            "Hi ha hagut un error al intentar elminar la FAQ"
          )
        )
        .finally(() => {
          this.loading = false;
        });
    },
    askDeletePictureFaq(faq, picture) {
      this.$swal({
        title: "Eliminar foto",
        text: `Estàs a punt d'eliminar aquesta foto de la FAQ, estàs segur/a?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteFaqPicture(faq, picture);
        }
      });
    },
    deleteFaqPicture(faq, picture) {
      this.loading = true;
      this.$store
        .dispatch("accommodation/deleteAccommodationFaqPicture", {
          faqUuid: faq.uuid,
          pictureUuid: picture.uuid,
        })
        .then(() =>
          notifySuccess(
            "Foto eliminada",
            "La foto ha estat eliminada correctament de la FAQ"
          )
        )
        .catch(() =>
          notifyError(
            "Foto no eliminada",
            "Hi ha hagut un error al intentar elminar la foto de la FAQ"
          )
        )
        .finally(() => {
          this.loading = false;
        });
    },
    editFaq(faq) {
      this.selectedFaq = faq;
      this.$bvModal.show(`edit-faq-modal-${this.language}`);
    },
    onEditFaqCancel() {
      this.$bvModal.hide(`edit-faq-modal-${this.language}`);
    },
    onFaqEdited() {
      this.$bvModal.hide(`edit-faq-modal-${this.language}`);
    },
    onEditFaqError() {
      notifyError(
        "Error",
        "Hi ha hagut un erro al intentar modificar la FAq. Revisa les dades i intenta-ho una altra vegada"
      );
    },
    getScopeBadgeText(faq) {
      if (!faq?.scope || faq?.scope === "ALL") return null;
      switch (faq.scope) {
        case "CLIENT":
          return "Només client";
        case "AGENCY":
          return "Només agència";
        default:
          return faq.scope;
      }
    },
    getFaqPicturesUrls(faq) {
      if (!faq?.pictures?.length) return [];
      return faq.pictures.map((picture) => picture.url);
    },
    showLightbox(faq, index) {
      this.lightboxPictures = this.getFaqPicturesUrls(faq);
      this.lightboxIndex = index;
      this.lightboxVisible = true;
    },
    hideLightbox() {
      this.lightboxPictures = [];
      this.lightboxVisible = false;
      this.lightboxIndex = 0;
    },
  },
};
</script>
