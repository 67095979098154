<template>
  <div id="description-form">
    <b-row class="mb-1">
      <b-col cols="12" md="3" class="mb-1 mb-md-0">
        <v-select
          v-model="editedDescription.language"
          :options="languageOptions"
          :reduce="(language) => language.value"
          placeholder="Selecciona un idioma"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col cols="12">
        <quill-editor
          :ref="`quillEditor-${description.uuid}`"
          v-model="editedDescription.content"
          :options="editorOption"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-start">
        <b-link
          class="text-danger"
          @click.stop="$emit('delete', description.uuid)"
        >
          <feather-icon icon="TrashIcon" />
          Elimina descripció
        </b-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BLink } from "bootstrap-vue";
import vSelect from "vue-select";
import { languageOptions } from "@/utils/select-options";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    vSelect,
    quillEditor,
  },
  props: {
    description: {
      type: Object,
      required: true,
    },
    accommodationDescription: {
      type: Boolean,
      default: false,
    },
    neighbourhoodDescription: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            // [{ header: [1, 2, false] }],
            ["bold", "italic", "underline"],
          ],
        },
      },
      editedDescription: {
        language: null,
        content: null,
        scope: null,
        uuid: null,
        new: false,
      },
    };
  },
  computed: {
    languageOptions() {
      return languageOptions;
    },
  },
  watch: {
    editedDescription: {
      deep: true,
      handler(description) {
        this.$emit("change", description);
      },
    },
  },
  created() {
    this.editedDescription = {
      language: this.description.language,
      content: this.description.content,
      scope: this.description.scope,
      uuid: this.description.uuid,
      new: this.description.new,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#description-form {
  .quill-editor {
    .ql-editor {
      p {
        margin-top: 0 !important;
        margin-bottom: 1rem !important;
      }
    }
  }
}
</style>
