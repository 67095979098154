<template>
  <b-overlay :show="loadingBedroomsOrBathrooms" variant="white" rounded="sm">
    <b-card id="arrangements-card" title="Distribució">
      <!-- BEDROOMS TITLE -->
      <b-row class="mb-1">
        <b-col cols="12">
          <h5>
            <feather-icon icon="GridIcon" />
            Dormitoris ({{ bedrooms.length }})
          </h5>
        </b-col>
      </b-row>

      <!-- BEDROOMS LIST -->
      <b-row v-if="bedrooms.length > 0" class="d-flex flex-wrap match-height">
        <!-- BEDROOMS CARDS -->
        <b-col
          v-for="(bedroom, index) in bedrooms"
          :key="`bedroom-${index}`"
          cols="12"
          md="6"
          class="mb-3"
        >
          <bedroom-card
            :title="'Dormitori ' + (index + 1)"
            :bedroom="bedroom"
            class="h-100"
          />
        </b-col>
      </b-row>

      <!-- NO BEDROOMS ALERT -->
      <b-alert class="mb-2" variant="primary" :show="bedrooms.length === 0">
        <div class="alert-body">Encara no s'ha afegit cap dormitori</div>
      </b-alert>

      <!-- BATHROOMS TITLE -->
      <b-row class="mb-1">
        <b-col cols="12">
          <h5>
            <feather-icon icon="GridIcon" />
            Banys ({{ bathrooms.length }})
          </h5>
        </b-col>
      </b-row>

      <!-- BATHROOMS LIST -->
      <b-row v-if="bathrooms.length > 0" class="d-flex flex-wrap match-height">
        <!-- BATHROOMS CARDS -->
        <b-col
          v-for="(bathroom, index) in bathrooms"
          :key="`bathroom-${index}`"
          cols="12"
          md="6"
          class="mb-3"
        >
          <bathroom-card
            :title="'Bany ' + (index + 1)"
            :bathroom="bathroom"
            class="h-100"
          />
        </b-col>
      </b-row>

      <!-- NO BATHROOMS ALERT -->
      <b-alert class="mb-2" variant="primary" :show="bathrooms.length === 0">
        <div class="alert-body">Encara no s'ha afegit cap bany</div>
      </b-alert>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-end">
        <b-col cols="6" sm="auto">
          <b-button v-b-modal.add-bathroom-modal block variant="primary">
            Afegeix bany
          </b-button>
        </b-col>
        <b-col cols="6" sm="auto">
          <b-button v-b-modal.add-bedroom-modal block variant="primary">
            Afegeix dormitori
          </b-button>
        </b-col>
      </b-row>

      <!-- ADD BEDROOM MODAL -->
      <b-modal
        id="add-bedroom-modal"
        size="lg"
        title="Afegeix un dormitori"
        scrollable
        hide-footer
      >
        <bedroom-form
          @bedroom-added="hideBedroomModal"
          @add-bedroom-error="hideBedroomModal"
          @cancel="hideBedroomModal"
        />
      </b-modal>

      <!-- ADD BATHROOM MODAL -->
      <b-modal
        id="add-bathroom-modal"
        size="lg"
        title="Afegeix un bany"
        scrollable
        hide-footer
      >
        <bathroom-form
          @bathroom-added="hideBathroomModal"
          @add-bathroom-error="hideBathroomModal"
          @cancel="hideBathroomModal"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BRow, BCol, BButton, BAlert, BOverlay } from "bootstrap-vue";
import BedroomCard from "@/views/accommodations/accommodation/components/BedroomCard.vue";
import BedroomForm from "@/views/accommodations/accommodation/components/BedroomForm.vue";
import BathroomCard from "@/views/accommodations/accommodation/components/BathroomCard.vue";
import BathroomForm from "@/views/accommodations/accommodation/components/BathroomForm.vue";

export default {
  name: "ArrangementsCard",
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAlert,
    BOverlay,
    BedroomCard,
    BedroomForm,
    BathroomCard,
    BathroomForm,
  },
  computed: {
    loadingBedroomsOrBathrooms() {
      return this.$store.getters["accommodation/loadingBedroomsOrBathrooms"];
    },
    bedrooms() {
      return this.$store.getters["accommodation/bedrooms"];
    },
    bathrooms() {
      return this.$store.getters["accommodation/bathrooms"];
    },
  },
  methods: {
    hideBedroomModal() {
      this.$bvModal.hide("add-bedroom-modal");
    },
    hideBathroomModal() {
      this.$bvModal.hide("add-bathroom-modal");
    },
  },
};
</script>
